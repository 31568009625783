<template>
    <div class="black-ui" style="height: 100%">
        <v-common-top :rightBtn="{text:'提现记录',to:'/withdraw/record/money'}"></v-common-top>
        <el-form :model="form" :rules="rules" ref="mainForm" label-width="0">
            <div class="pay-bottom pay-set">
                <div class="account-info" style="height: 90px">
                    <h3 class="title">{{'可汇出余额'|langTranslation}}</h3>
                    <div class="content">
                        {{money|moneyToShow}} <em>円</em>
                    </div>
                </div>


                <div class="pay-select" style="box-shadow:none">
                    <h2>{{'选择银行账户'|langTranslation}}
                        <router-link style="float: right" :to="'/user/bankcard'"><i class="icon-font-setting"></i></router-link>
                    </h2>
                    <div class="pay-list">
                        <ul>
                            <li v-for="it in list" @click.stop.prevent="form.wallet=it.id">
                                <label class="bm-checkbox"><input :value="it.id" :checked="form.wallet==it.id" type="checkbox"/><i></i></label>
                                <span class="pl-title">{{it.bank_name}}</span>
                                <span class="pl-info">{{it.card_no}}</span>
                            </li>

                            <li>
                                <router-link :to="'/user/bankcard/edit'" class="button04" style="margin: auto">
                                    <i class="icon-font-bank-add"></i>
                                    <span style="margin-left: 10px;">{{'添加新银行卡'|langTranslation}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="wallet-content">

                    <el-form-item prop="withdraw" :error="formError.withdraw" ref="fi_btc">
                        <el-input @focus="handleFocus" @blur="handleBlur" class="text" v-model="form.withdraw"  :placeholder="'请输入汇出金额'|langTranslation"></el-input>
                    </el-form-item>

                    <div class="ps-tab-sel">
                        <div class="left">
                            <a href="javascript:;" :class="{'current':currentScale==1}" @click="changeScale(1)">10%</a>
                            <a href="javascript:;" :class="{'current':currentScale==2}" @click="changeScale(2)">20%</a>
                            <a href="javascript:;" :class="{'current':currentScale==5}" @click="changeScale(5)">50%</a>
                            <a href="javascript:;" :class="{'current':currentScale==10}" @click="changeScale(10)">100%</a>
                        </div>

                        <!--<span class="input-rate" :class="{current:currentScale=='auto'}">
                                <input @focus="currentScale='auto'" v-model="customScale" type="text" placeholder="カスタム" maxlength="3" />
                            </span>-->
                    </div>
                </div>
            </div>
            <div class="p-detail">

                <p>{{'提现手续费'|langTranslation}}：{{handlingFee}}円 &nbsp; {{'最少提现数额'|langTranslation}}：{{minHandling}}円</p>
                <p>
                    {{'お客様の振込先（出金先）指定口座へのお振込みは、出金をご依頼された金額から振込手数料を差し引いた金額を受付日の翌々営業日に行います。'|langTranslation}}
                </p>
                <p>{{'お振込みのご連絡はいたしませんので、お客様ご自身で残高のご確認をお願いいたします。'|langTranslation({"handlingFee":handlingFee})}}</p>

            </div>

            <div class="pay-bottom">
                <div class="code-box">
                    <el-form-item prop="code" :error="formError.code" ref="fi_mobile">
                        <el-input class="text" v-model="form.code" ref="code" :placeholder="'验证码'|langTranslation"></el-input>
                    </el-form-item>
                    <label @click="getMobileCode">
                        {{countDown>0?countDown+'s':'获取验证码'|langTranslation}}
                    </label>
                </div>

                <a href="javascript:;" @click="withdraw()" class="button01">{{'进行汇出'|langTranslation}}</a>
            </div>
        </el-form>
    </div>

</template>
<style type="text/css">
    body {
        height: 100%;
    }
</style>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {getBankCardList, getWithdrawInfo, sendMobileCode, withdraw} from '../api';
    import Big from 'big.js' ;
    import common from '@/common/common'
    let langTranslation = common.langTranslation;
    import Vuex from 'vuex';

    export default {
        computed: {
            ...Vuex.mapState(['userInfo']),
            ...Vuex.mapGetters(['isActiveUser']),
            withdraw:{
                get(){
                    if(!this.form || this.form.withdraw*1==0) return ''
                    return this.form.withdraw
                },
                set(val){
                    if(this.form){
                        this.form.withdraw = val;
                    }

                }
            }
        },
        data() {
            return {
                list:[],
                money: 0,
                moneyOut: 0,
                handlingFee: '300',
                minHandling:'1500',
                currentScale: 'auto',
                countDown:0,
                countDownMax: 60,

                form:{
                    withdraw: '',
                    wallet:'',
                    code:''
                },

                formError :{
                    code:'',
                    withdraw: '',
                    wallet:''
                },
                rules: {
                    wallet:[
                        {
                            validator(rule, value, callback) {
                                if(!value||!value.trim()){
                                    return callback(new Error(langTranslation('请选择银行卡')));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    withdraw: [
                        {
                            validator:(rule, value, callback)=>{
                                if(!value||!value.trim()){
                                    return callback(new Error(langTranslation('请填写提现金额')));
                                }
                                if(value*1>this.money*1){
                                    return callback(new Error(langTranslation('法币申请提现数额不能超过当前余额')));
                                }

                                if(value*1<this.minHandling*1){
                                    return callback(new Error(langTranslation('提现数额不能少于{minHandling}円',{'minHandling':this.minHandling})));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    code: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机验证码')));
                                }
                                if (value.length != 4) {
                                    return callback(new Error(langTranslation('请输完整的验证码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],

                }

            }
        },
        components: {
            vCommonTop
        },
        methods: {
            handleFocus(e){
                e.target.classList.add('input-focus')
            },
            handleBlur(e){
                e.target.classList.remove('input-focus')
            },
            changeScale(scale) {
                this.currentScale = scale;
                this.form.withdraw = Big(this.money).times(scale).div(10).round(2,0).toFixed(2)
            },
            startCountDown(cur) {
                this.countDown = cur;
            },
            getMobileCode() {
                if (this.countDown > 0) return;
                sendMobileCode('withdraw').then(json => {

                    if (json.data && json.data.count_down) {
                        this.countDownMax = json.data.count_down;
                    }

                    if(json.data && json.data.code){
                        this.form.code = json.data.code;
                    }

                    this.startCountDown(this.countDownMax);
                    this.$message({
                        message: langTranslation('发送成功'),
                        type: 'success'
                    });
                }, json => {

                    if (json.errorData && json.errorData.leftTime) {
                        this.startCountDown(json.errorData.leftTime);
                    }

                    this.$message(json.message);
                })
            },
            withdraw() {
                this.$refs.mainForm.validate(rs => {



                    if (rs) {
                        if(this.withdrawing) return ;
                        this.withdrawing = true;

                        withdraw('money',this.form).then(()=>{
                            this.$message({
                                message: langTranslation('提现申请提交成功'),
                                type: 'success'
                            });
                            setTimeout(()=>{
                                let to = '/withdraw/record/money';
                                this.$router.push(to);
                            },1000)
                        },json=>{
                            this.withdrawing = false;
                            this.$message(json.message);
                        })
                    }
                })

            }
        },
        watch: {

        },
        created() {
            if(!this.isActiveUser){
                this.$router.push({path:'/ht'});
                return ;
            }
            getWithdrawInfo('money').then(json => {

                this.money = json.data.money || 0;
                this.moneyOut = json.data.moneyOut || 0;
                this.handlingFee = json.data.handlingFee || 300;
                this.minHandling = json.data.minHandling || 1500;
                // this.form.withdraw = Big(this.money).times(this.currentScale).div(10).round(2,0).toFixed(2)
                this.form.withdraw = ''
            });

            getBankCardList().then(json=>{
                this.list = json.data || [];
                if(this.list.length){
                    this.form.wallet = this.list[0].id;
                }
            })


            let _ = () => {
                if (this.countDown > 0) {
                    this.countDown -= 1;
                }
                this.timer = setTimeout(_, 1000)
            }
            _();
        },
        template: '__template__'
    };
</script>
