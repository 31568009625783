<template>
  <div class="page-bankcard-admin">
    <div class="header">
      <v-common-top :class="{'ct-white':1}"></v-common-top>
    </div>

    <div class="limit-box">

      <div v-if="!list.length" class="bankcard-empty">
        <h3>{{('没有银行账户信息')|langTranslation}}</h3>
      </div>

      <div v-for="it in list" class="m-bankcard-layout">
        <div class="content">
          <div class="title">{{it.bank_name}}</div>
          <div class="card-no">{{it.card_no}}</div>
        </div>
        <div class="edit" @click="edit(it.id)">
          <i class="icon-font-edit icon"/>
        </div>
      </div><!--

      <bankcard-layout @edit="$router.push({name: 'new_bankcard', params: {type: 'edit'}})"/>
      <bankcard-layout @edit="$router.push({name: 'new_bankcard', params: {type: 'edit'}})"/>-->

      <div class="user-btns">
        <router-link :to="'/user/bankcard/edit'" class="button01">
          <i class="icon-font-add"></i>
          <span>{{'添加银行账户信息'|langTranslation}}</span>
        </router-link>
      </div>

    </div>

    <v-copyright></v-copyright>

  </div>
</template>

<script>
  import vCommonTop from '../block/common-top';
  import {getAddressList, getBankCardList} from "../../api";
  export default {
    components: {
      vCommonTop
    },
    data () {
      return {
        list:[]
      }
    },
    created(){
      getBankCardList().then(json=>{
        this.list=json.data||[];
      })
    },
    methods:{
      edit(id){
        this.$router.push({path: '/user/bankcard/edit/' + (id || '')})
      }
    }
  }

</script>
