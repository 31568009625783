import vCopyright from './page/components/block/copyright'
import {getRealTimePrice} from './page/api';
import Vue from 'vue'
import qs from 'qs';
import routes from '@/page/routes';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import ElementUI from 'element-ui';
import axios from 'axios';
import App from './App.vue';

import 'element-ui/lib/theme-chalk/index.css';

if(window.THEME=='blue'){
  require('./scss/main_blue.scss');
}else if(window.THEME=='blue1'){
  require('./scss/main_blue1.scss');
}else if(window.THEME=='yellow'){
  require('./scss/main_yellow.scss');
}else if(window.THEME=='yellow1'){
  require('./scss/main_yellow1.scss');
}else{
  require('./scss/main.scss');
}

import './vant';
import MyMenu from '@/page/components/new/menu.vue';
Vue.component('my-menu',MyMenu);


Vue.mixin({
  components:{
    vCopyright
  }
})

Vue.use(Vuex);
Vue.use(ElementUI);
Vue.use(VueRouter);


const router = new VueRouter({
  //mode: 'history',
  mode: process.env.VUE_APP_ROUTER||'history',
  routes, // （缩写）相当于 routes: routes
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }

  }
})


router.beforeEach((to, from, next) => {
  if(to.name=='login' && store.getters.isLogin){
    next({
      path: '/'
    })
  }else if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLogin) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})

let getRealTimePriceTimer ;

const store = new Vuex.Store({
  state: {
    userId:window.USER_ID,
    userInfo:window.USER_INFO,
    shareKey:window.SHARE_KEY,
    currency:window.CURRENCY,
    noBTC:window.NO_BTC,
    site_mark:window.SITE_MARK,
    currentRoute: '',
    realTimeBTCPriceJP:window.BTCRealTimePriceJP,
    realTimeBTCPriceJPUpdateTime:window.BTCRealTimePriceJPUpdateTime,
    activeUsers:['11009','1256']
  },
  getters:{
    isLogin(state){
      return !!state.userId;
    },
    isActiveUser(state){
      return !!state.userId && state.activeUsers.indexOf(state.userId.toString())>-1;
    }
  },
  actions:{
    updateRealTimeBTCPriceJP({commit,dispatch}){

      let now = new Date(window["DIFFTIME"]+(+new Date));
      let min = now.getMinutes()%5;
      let sec = now.getSeconds();

      getRealTimePriceTimer = clearTimeout(getRealTimePriceTimer);
      getRealTimePriceTimer = setTimeout(()=>{

        dispatch('updateRealTimeBTCPriceJP');
      },(min==0 && sec<10?11-sec:303-min*60-sec)*1000);
      getRealTimePrice().then(json=>{
        if(json.data && json.data.price){
          commit('setRealTimeBTCPriceJP',json.data.price);
          commit('setRealTimeBTCPriceJPUpdateTime',json.data.time);
        }
      },()=>{

      })
    }
  },
  mutations:{
    setUserId(state,uid){
      state.userId=uid;
    },
    setUserInfo(state,data){
      state.userInfo = data;
      state.userId = data && data.id;
    },
    setUserName(state,name){
      state.userInfo.user_name = name;
    },
    setCurrentRoute(state,route){
      state.currentRoute = route;
    },
    setRealTimeBTCPriceJP(state,price){
      state.realTimeBTCPriceJP = price;
    },
    setRealTimeBTCPriceJPUpdateTime(state,time){
      state.realTimeBTCPriceJPUpdateTime = time;
    },
  }
})

Vue.directive('dom', {
  bind: function (el, binding) {
    var obj = binding.value;
    if (obj != null) {
      var key = Object.keys(binding.modifiers)[0] || "el";
      Vue.set(obj, key, el);
    }
  }
});

axios.defaults.withCredentials = true;
axios.defaults.baseURL =  process.env.VUE_APP_BASE_API;

axios.defaults.transformRequest.unshift(function (data) {
  data = qs.stringify(data);
  return data;
});

const app = new Vue({
  router,
  store,
  data() {
    return {}
  },
  computed: {

  },
  methods:{
    ...Vuex.mapMutations(['setCurrentRoute'])
  },
  watch: {
    "$route"(to) {
      this.setCurrentRoute(to.name);
    }
  },
  created() {
    this.setCurrentRoute(router.currentRoute && router.currentRoute.name);
    store.dispatch('updateRealTimeBTCPriceJP')

  },
  render: h => h(App),
}).$mount('#app');

//app.currentRoute=router.currentRoute && router.currentRoute.name;
window.APP = app;
