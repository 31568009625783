<template>
    <div>
        <v-common-top :hasShadow="true" ></v-common-top>
        <div class="limit-box">
            <div class="user-data-sum" v-if="$route.params.date">
                <div class="income-type">
                    リワード
                </div>
                <div class="date" v-if="$route.params.date">
                    {{$route.params.date.start | dateFormat('full_to_date2')}}
                    -
                    {{$route.params.date.end | dateFormat('full_to_date2')}}
                </div>
                <p>
                    {{'選択期間内合計収益'|langTranslation}}
                </p>
                <p>
                    <strong>
                        {{sum|moneyToShow(2)}} <em>円</em>
                    </strong>
                </p>

            </div>

            <div class="user-data-list">


                <ul>

                    <template v-for="(it,index) in list">


                        <li :key="it.id">
                            <span :style="{'min-width':80+'px'}">{{it.detail.month}}</span>
                            <span style="padding: 4px 0px; flex: auto;text-align: center" >
                                {{it.detail.calc_count}}{{'台'|langTranslation}}
                            </span>
                            <span style="padding: 4px 0; text-align: right;min-width:125px;">
                                <template>
                                    {{it.money|moneyToShow(2)}} 円
                                </template>
                            </span>
                        </li>
                    </template>

                    <li class="loading" v-if="this.lastLoad">
                        <span>{{'读取中'|langTranslation}}</span>
                    </li>

                </ul>
            </div>

        </div>

        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">

    import date from '@/common/date';
    import vCommonTop from './block/common-top';
    import {getPercentageList} from '../api';
    import Vuex from "vuex";

    export default {
        data() {
            return {
                list:[],
                start:0,
                sum:0,
                sum_money:0,
                lastLoad:null
            }
        },
        components: {
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['noBTC']),
        },
        filters:{
            dateFormat: date.format
        },
        watch: {},
        methods: {

            loadIncome(reset){
                if((this.lastLoad||this.listEnd) && !reset){
                    return ;
                }
                let start = reset?0:this.start;
                let opt = {
                    start:start,
                    limit:40,
                    sum:start==0?1:0,

                };

                if(this.$route.params.date){
                    opt.begin = date.format(this.$route.params.date.start,'full_to_date');
                    opt.end = date.format(this.$route.params.date.end,'full_to_date');
                }

                if(reset){
                    this.listEnd = false;
                    this.list = [];
                }
                this.lastLoad = {...opt,reset};
                getPercentageList(opt).then(json=>{
                    if(JSON.stringify(this.lastLoad) != JSON.stringify({...opt,reset})){
                        //不是最后一次请求
                        return false;
                    }
                    this.lastLoad = false;
                    if(reset){
                        this.list=[];
                    }
                    json.data.list.forEach(it=>{
                        it.detail = JSON.parse(it.detail);
                        this.list.push(it);
                    })

                    if(!json.data.list.length){
                        this.listEnd = true;
                    }
                    this.start = this.list.length;
                })
            },

            scrollTable: function () {
                let visibleHeight = document.documentElement.clientHeight
                let scrollHeight = document.documentElement.scrollHeight
                let scrollTop = document.documentElement.scrollTop
                if (scrollHeight - (scrollTop + visibleHeight) <= 100) {
                    this.loadIncome()
                }
            }
        },
        beforeDestroy(){
            window.removeEventListener('scroll',this.scrollTable,true)
        },
        created() {
            if(!this.$store.getters.isLogin){
                return this.goLogin();
            }

            this.loadIncome();
            window.addEventListener('scroll', this.scrollTable, true)



        },
        template: '__template__'
    };
</script>
