<template>
    <div>
        <v-common-top></v-common-top>
        <div class="limit-box">

            <div class="user-nav-tab">
                <a href="javascript:;" @click="changeCurrent(1)" :class="{current:current==1}">{{'支付中'|langTranslation}}</a>
                <a href="javascript:;" @click="changeCurrent(2)" :class="{current:current==2}">{{'委託管理中'|langTranslation}}</a>
                <a href="javascript:;" @click="changeCurrent(3)" :class="{current:current==3}">{{'期間終了'|langTranslation}}</a>
            </div>

            <div class="lb-message" v-if="current==2">
                <p>1.現在のネットワークに応じたシーリング率を表示中です。</p>
                <p>2.プロトコルラボのルールに基づいてマイニングを行います。</p>
            </div>


            <div v-for="(i,ind) in viewList" class="machine-item pdc-box" :class="map[i].boxClass" :key="ind">
                <div v-if="list[i] && list[i].length">
                    <h3 v-if="map[i].title" class="h-title" @click="toggleList(i)">
                        <span>{{map[i].title}}</span>
                    </h3>
                    <div v-if="map[i].showList">
                        <div  class="box" v-for="item in list[i]"  @click="showDetail(item)" :key="item.id">
                            <div class="info">
                                <h3 class="title" style="position: relative">
                                    {{item.pro_name}}
                                    <!--<span v-if="item.status!=0 && item.status!=-3" style="font-size: 12px;font-weight: normal; float: right">{{map[i].title}}</span>-->
                                    <span v-if="current==2" style="font-weight: normal;font-size: 12px;color: #1FA47E;letter-spacing: 0;text-align: right;float: right">
                                      <template v-if="now/1000 - item.pay_time>item.income_start_day*86400 || item.status==2">
                                        <template v-if="item.product.pro_type==1 && item.new_order && item.new_order.status!=0 ">
                                          更新中
                                          <em style="display: block;position: absolute;left: 0px;top: 37px;">更新完了まで{{item.new_order.income_start_day}}日かかります、しばらくお待ち下さい。</em>
                                        </template>
                                        <template v-else>稼働中</template>
                                      </template>
                                      <template v-else>準備中
                                        <em v-if="item.pro_type==4" style="display: block;position: absolute;left: 0px;top: 37px;">稼働開始まで{{ item.income_start_day }}日かかります、しばらくお待ち下さい。</em>
                                      </template>
                                    </span>
                                </h3>
                                <template v-if="item.status==0 || item.status==-3">
                                    <ul>
                                        <li><span>{{'订单号'|langTranslation}}</span><span>{{item.order_code}}</span></li>
                                        <li><span>{{'购买数量'|langTranslation}}</span><span>{{item|allCalc}} {{'台'|langTranslation}}</span></li>
                                        <li><span>{{'购买单价'|langTranslation}}</span>
                                            <span v-if="item.order.pay_way.indexOf('btc')>=0"><strong>{{item.pro_price|priceToBtc(item.status==4?item.order.btc_price:realTimeBTCPriceJP)}}</strong> BTC</span>
                                            <span v-else><strong>{{item.pro_price|moneyToShow}} 円</strong></span></li>
                                        <li v-if="item.pro_sale_tax && item.pro_sale_tax!=0"><span>{{'消费税'|langTranslation}}</span>
                                            <span v-if="item.order.pay_way.indexOf('btc')>=0">{{item.pro_sale_tax|priceToBtc(item.status==4?item.order.btc_price:realTimeBTCPriceJP)}} BTC</span>
                                            <span v-else>{{item.pro_sale_tax|moneyToShow}} 円</span>
                                        </li>
                                        <li><span>{{'订单时间'|langTranslation}}</span><span>{{item.create_time|dateFormat}}</span></li>
                                        <li v-if="item.status==0 && item.order.pay_way.indexOf('btc')>=0" ><span style="color:red;">{{'价格更新时间'|langTranslation}}</span><span style="color:red;">{{leftTime}}</span></li>
                                        <li v-if="item.order.pay_way=='online_btc' && item.order.pay_type==2 && item.status==4" class="pay-status-bar"><div class="bar" style="color:#0371e2">
                                            <span :style="{width:item.confirmations/2*100 + '%'}"></span>
                                        </div><div class="text">{{(item.confirmations>=2?'支払い成功':'支払確認中')|langTranslation}}</div></li>
                                    </ul>

                                    <ul v-if="item.order.pay_type!=3 && item.status==0" class="box-line">
                                        <li class="sum" v-if="item.order.pay_way.indexOf('btc')==-1">
                                            <span style="font-weight: bold;">{{'支付金额'|langTranslation}}</span>
                                            <span><strong>{{item.order.by_tax_amount|moneyToShow}} 円</strong></span>
                                        </li>
                                        <li class="sum" v-if="item.order.pay_way.indexOf('btc')>=0 && (item.order.pay_type==1 || item.status==0)"><span style="font-weight: bold;"> {{'支付金额'|langTranslation}}</span><span><strong>{{item.order.by_tax_amount|priceToBtc(realTimeBTCPriceJP)}} BTC</strong></span></li>
                                        <li class="sum" v-if="item.order.pay_type==2"><span style="font-weight: bold;">{{'支付金额'|langTranslation}}</span><span><strong>{{item.order.pay_amount}} BTC</strong></span></li>
                                    </ul>

                                    <div v-if="item.status==0" class="pay-bottom">
                                        <a href="javascript:;" @click.stop="goto('/order/'+item.order_code)" class="button01">{{'去支付'|langTranslation}}</a>
                                        <a href="javascript:;" v-if="item.pro_type!=3" @click.stop="cancelOrder(item.order_code)">{{'入取消'|langTranslation}}</a>
                                    </div>

                                    <div v-if="item.status==-3 && item.product.can_show && item.product.status==1" class="pay-bottom">
                                        <router-link :to="'/detail/'+item.pro_id" class="button01">
                                            {{'重新购买'|langTranslation}}
                                        </router-link>
                                        <!--<a href="" @click="cancelOrder">キャンセルする</a>-->
                                    </div>
                                </template>

                                <template v-else>
                                    <ul>
                                        <li><span>{{'订单号'|langTranslation}}</span><span>{{item.order_code}}</span></li>
                                        <li><span>{{'合計ストレージ'|langTranslation}}</span><span>{{item|allCalc}} T</span></li>
                                        <li class="sum">
                                            <span>{{'購入金額'|langTranslation}}</span>
                                            <span v-if="item.order.pay_type==1"><strong>{{item.order.pay_amount|moneyToShow}} 円</strong></span>
                                            <span v-if="item.order.pay_type==2"><strong>{{item.order.pay_amount}} BTC</strong></span>
                                        </li>
                                    </ul>
                                    <ul class="box-line">
                                        <li v-if="current==2"><span>{{'シーリング量(累計)'|langTranslation}}</span><span>{{item.seal_calc}} T</span></li>
                                        <!--<li v-if="current==2"><span>{{'シーリング量(前日)'|langTranslation}}</span><span>0 T</span></li>-->
                                        <li v-if="item.pro_type<=2"><span>{{'残りストレージ'|langTranslation}}</span><span>{{item|leftCalc}} T</span></li>
                                        <li><span>{{'累計獲得枚数'|langTranslation}}</span><span>{{item.sum_fil}} FIL</span></li>
                                        <template v-if="item.status==2">
                                            <li><span>{{'開始期間'|langTranslation}}</span><span>{{item.income_start_time|dateFormat}}</span></li>
                                            <li><span>{{'終了期間'|langTranslation}}</span><span>{{item.income_end_time|dateFormat}}</span></li>
                                        </template>


                                    </ul>
                                  <div v-if="item.status==2 && item.product.pro_type==1 && !item.new_order_code && item.product.upgrade_pro_id" class="pay-bottom">
                                    <a @click.stop="goto('/detail/'+item.product.upgrade_pro_id+'?order_code=' + item.order_code)" class="button01">
                                      {{'升级'|langTranslation}}
                                    </a>
                                    <!--<a href="" @click="cancelOrder">キャンセルする</a>-->
                                  </div>

                                </template>



                            </div>


                        </div>
                    </div>
                </div>
                <div v-loading="loading" v-if="loading && ind==0" style="height: calc(100vh / 2); "></div>
                <div  v-if="isEmpty && ind==0" >
                    <div :class="{'machine-empty':current!=3,'host-empty':current==3}">
                        <h3 style="font-size:20px;">{{current==3?'期限終了の注文がありません':'注文がありません'|langTranslation}}</h3>
                    </div>

                    <div class="user-btns" v-if="current!=3">
                        <router-link to="/product" class="button01">
                            <i class="icon-font-add"></i>
                            <span>{{'商品一覧へ'|langTranslation}}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import Big from 'big.js' ;
    import vCommonTop from './block/common-top';
    import Vuex from 'vuex';
    import {getMyMachine,shipOrder,redemption,cancelOrder} from '../api';
    import common from '@/common/common'
    let langTranslation = common.langTranslation;

    export default {
        props:['type'],
        data() {
            console.log(this.type)
            return {
                current:this.type=='paid'?2:1,// 1 进行中    2 已到期   3 已赎回
                list:{},
                loading:true,
                map:{
                    0:{ // 待支付
                        title:langTranslation('待支付'),
                        boxClass:'machine-expire',
                        showList:1
                    },
                    1:{
                        title:langTranslation('未开始'),
                        boxClass:'machine-expire',
                        showList:1
                    },
                    2:{
                        title:langTranslation('稼働中'),
                        showList:1
                    },
                    3:{
                        //title:'期間終了',
                        title:langTranslation('ストレージ不足'),
                        showList:1
                    },
                    4:{ //已支付
                        //title:'支払完了',
                        //title:langTranslation('開始待ち'),
                        title:'',
                        showList:1
                    },
                    5:{ //待发货
                        title:langTranslation('发货中'),
                        showList:1
                    },
                    6:{ //已发货
                        title:langTranslation('发货完成'),
                        showList:1
                    },

                    '-1':{
                        title:langTranslation('申请中'),
                        showList:1
                    },
                    '-2':{
                        title:langTranslation('完成'),
                        showList:1
                    },
                    '-3':{
                        title:langTranslation('取消'),
                        showList:1
                    },
                },
                now:new Date(+new Date + (window.DIFF_TIME || 0))
            }
        },
        components:{
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['realTimeBTCPriceJP','realTimeBTCPriceJPUpdateTime','noBTC']),
            isEmpty(){
                if(this.loading) return false;
                var notEmpty = false;
                for(var i=0;i<this.viewList.length;i++){
                    let it = this.list[this.viewList[i]];
                    if(it && it.length ){
                        notEmpty = true;
                    }
                }
                return !notEmpty;
            },
            leftTime(){
                var now = this.now;

                var left = Math.floor(this.realTimeBTCPriceJPUpdateTime - (+now/1000) + 301);

                if(left<=0) return '00:00';

                var min = Math.floor(left/60),sec = left % 60;
                return (min>=10?min:'0'+min) + ':' + (sec>=10?sec:'0'+sec);
            },
            viewList(){
                if(this.current==1){
                    return [0,-3]
                }
                if(this.current==2){
                    return [4,2,3]
                }
                if(this.current==3){
                    return [-1,-2]
                }
                return []
            }
        },
        filters:{
            execIncome(day,item,noBTC){
                let exp;
                if(item.product && item.product.other){
                    exp = item.product.other;
                    if(typeof exp == 'string'){
                        exp = JSON.parse(exp);
                    }

                    exp = exp.expirations_income.find(it=>it.expiration==item.pro_expiration_time);
                }

                if(!exp || !exp.income) return '0';

                if(noBTC){
                    return Big(exp.annualized).times(item.order.order_amount).div(365).times(day).div(100).toFixed(2);
                }else{
                    return Big(exp.income).times(day).times(item.pro_base_calc).times(item.count).times(window.BTCPriceJP).toFixed(2);
                }
            },
            execIncomeBTC(day,item){
                let exp;
                if(item.product && item.product.other){
                    exp = item.product.other;
                    if(typeof exp == 'string'){
                        exp = JSON.parse(exp);
                    }

                    exp = exp.expirations_income.find(it=>it.expiration==item.pro_expiration_time);
                }

                if(!exp || !exp.income) return '0';

                return Big(exp.income).times(day).times(item.pro_base_calc).times(item.count).round(8,0).toFixed(8)
            },

            allCalc(v){

                if(v.pro_type==2){
                    return Big(v.pro_base_calc).times(v.pro_hash_power).times(v.count).toFixed();
                }else{
                    return Big(v.pro_base_calc).times(v.count).toFixed();
                }

            },
            leftCalc(v){
                if(v.pro_type==2){
                    return Big(v.pro_base_calc).times(v.pro_hash_power).times(v.count).sub(v.seal_calc||0).toFixed(2);
                }else{
                    return Big(v.pro_base_calc).times(v.count).sub(v.seal_calc||0).toFixed(2);
                }
            },
            calcCount(v){

                return Big(v.pro_base_calc).times(v.count).toFixed();
            },
        },
        methods:{
            toggleList(key){
                if(this.map[key]){
                    this.map[key].showList =  1 - this.map[key].showList;
                }
            },
            loadMyMachine(reload){
                if(!reload && this.list[this.viewList[0]]) return ;// 已加载
                this.loading = true;
                for( let i =0;i<this.viewList.length;i++){
                    let lit = this.viewList[i]
                    this.$set(this.list,lit,[]);
                }



                getMyMachine(this.viewList.join(','),'1,2,3,4,5,6').then(json=>{
                    let list = json.data;
                    let i,c


                    for(i=0,c=list.length;i<c;i++){
                        let it = list[i];

                        it.pro_info =JSON.parse(it.pro_info);
                        it.pro_hash_power = it.pro_info.pro_hash_power;
                        it.pro_sale_tax = it.pro_info.pro_sale_tax;
                        it.order.pay_way = it.order.pay_way || 'offline'
                        it.order.by_tax_amount = Big(it.order.order_amount).add(it.order.sale_tax||0).toFixed(2)
                        let lit = it.status;

                        this.list[lit].push(it);
                    }
                    this.loading = false;
                },json=>{
                    this.loading = false;
                    this.$message(json.message);
                })
            },
            changeCurrent(cur){
                this.current = cur;
                this.loadMyMachine();
            },
            shipOrder(order_code,tf){
                if(!tf) return ;
                this.$confirm(langTranslation('是否确认发货'), {
                    cancelButtonText:langTranslation('取消'),
                    confirmButtonText:langTranslation('确认发货'),
                    callback:(action)=>{
                        if(action=='confirm'){
                            shipOrder(order_code).then(()=>{
                                this.loadMyMachine(true);
                                this.$message({
                                    message: langTranslation('设置发货成功'),
                                    type: 'success'
                                });
                            });
                        }
                    }
                })
                //设置为发货
            },
            redemption(order_code,pro_do_php){
                if(pro_do_php){
                    this.$confirm(langTranslation('是否取消回购申请？'), {
                        cancelButtonText:langTranslation('取消'),
                        confirmButtonText:langTranslation('申请回购'),
                        callback:(action)=>{
                            if(action=='confirm'){
                                redemption(order_code).then(()=>{
                                    this.loadMyMachine(true);
                                    this.$message({
                                        message: langTranslation('赎回成功'),
                                        type: 'success'
                                    });
                                },json=>{
                                    this.$message(json.message);
                                });
                            }
                        }
                    })
                }else{
                    this.$router.push({
                        path: '/redemption/'+order_code
                    });
                }

            },
            cancelOrder(order_code){
                this.$confirm(langTranslation('是否确认取消订单'), {
                    cancelButtonText:langTranslation('取消'),
                    confirmButtonText:langTranslation('确认'),
                    callback:(action)=>{
                        if(action=='confirm'){
                            cancelOrder(order_code).then(()=>{
                                this.loadMyMachine(true);
                                this.$message({
                                    message: langTranslation('取消订单成功'),
                                    type: 'success'
                                });
                            });
                        }
                    }
                })
            },
            showDetail(item){
                this.$router.push('/order_detail/'+item.order_code);
                return ;
                
                if(item.status==0||item.status==4){
                    this.$router.push('/order_detail/'+item.order_code);
                    return ;
                }

                if(item.status==2||item.status==3){
                    this.$router.push('/order_detail/'+item.order_code);
                    return ;
                }

                if(item.status==-1||item.status==-2){
                    this.$router.push('/redemption/'+item.order_code);
                    return ;
                }


            },
            goto(url){
                this.$router.push(url);
            }
        },
        watch: {

        },
        created() {
            var self = this;
            this.loadMyMachine();
            function _(){
                setTimeout(() => {
                    self.now = new Date(+new Date + (window.DIFF_TIME || 0));
                    _();
                },1000)
            }
            _();

        },
        template: '__template__'
    };
</script>

<style scoped>
.lb-message{
    background: #FEFCF9;
    box-shadow: 3px 4px 16px 0 rgba(101,101,101,0.50);
    border-radius: 11px;
    margin: 5px 15px;
    padding: 5px 10px;

    font-size: 14px;
    color: #A77F50;
    letter-spacing: 0;
}
</style>
