<template>
  <div class="menu">
    <van-popup
      ref="menu"
      v-model="isShowMenu"
      position="right"
      @closed="closed"
      @open="open"
      @close="$emit('switching',true);$emit('close')"
      @opened="$emit('switching',false)"
      :lazy-render="false"
      :style="{ width: '66%', height: '100vh','max-height':'100vh'}"
    >
      <ul class="menu-bar" @click.stop="handleNav">
        <li data-path="/"><i class="iconicon-home iconfont icon"></i>{{'首页'|langTranslation}}</li>
        <li data-path="/product"><i class="iconicon-shopping-card iconfont icon"></i>{{'矿机购买'|langTranslation}}</li>
          <template v-if="currency=='FIL'">
              <li data-path="/article/14"><i class="icon-font-nav-like icon"></i>{{'選ばれる理由'|langTranslation}}</li>
              <li data-path="/article/16"><i class="icon-font-nav-ipfs icon"></i>{{'IPFS/Filecoinとは'|langTranslation}}</li>
              <li data-path="/article/17"><i class="icon-font-nav-filecoin-mining icon"><span class="path1"></span><span class="path2"></span></i>{{'Filecoinマイニング'|langTranslation}}</li>
              <li data-type="nav" data-path="/calculator"><i class="icon-font-nav-calc icon"></i>{{'FIL計算機'|langTranslation}}</li>
          </template>
        <li v-else data-path="/lease"><i class="iconicon-rent iconfont icon"></i>{{'矿机租赁'|langTranslation}}</li>
        <li data-path="/user"><i class="iconicon-user iconfont icon"></i>{{'个人中心'|langTranslation}}</li>

        <li class="language-item" style="display: none">
          <div class="language-icon country-icon"
               @click="switchDropdown"
               :class="`${useLanguage}-icon`"></div>
          <van-dropdown-menu :overlay="false">
            <van-dropdown-item :title="_language" ref="dropdown" >
              <div class="language-item" @click="changeLanguage('Japanese')"><i class="country-icon Japanese-icon icon" />日本语</div>
              <div class="language-item" @click="changeLanguage('English')"><i class="country-icon English-icon icon" />英语</div>
            </van-dropdown-item>
          </van-dropdown-menu>
        </li>
      </ul>
    </van-popup>

  </div>
</template>

<script>
import Vuex from "vuex";

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
      ...Vuex.mapState(['currency']),
    _language: {
      get(){
        return this.language[this.useLanguage];
      },
      set(language){
        this.useLanguage = language;
      }
    }
  },
  data () {
    return {
      isShowMenu: false,
      switching: false,
      path: '',
      useLanguage: 'Japanese',
      language: {
        Japanese: '日本语',
        English: '英语'
      }
    }
  },
  methods: {
    open(){
      this.$emit('switching',true);
      this.$emit('openmenu');
    },
    closed(){
      this.$emit('switching',false);
      this.$emit('shutmenu');
      if(this.path == '')return ;
      this.$emit('change-path', this.path);
    },
    changeLanguage(language) {
      this.useLanguage = language;
      this.switchDropdown();
    },
    switchDropdown(){
      this.$refs.dropdown.toggle();
    },
    handleNav(e){
      let {path,type} = e.target.dataset;
      if(path){
        if(type=='nav'){
          location.href = path;
          return ;
        }
        this.isShowMenu = false;
        this.path = path;
      }

    }
  },
  watch: {
    isShow(val){
      this.isShowMenu = val;
    }
  }
}

</script>

<style lang="scss">
  @import "~@/scss/mixin.scss";
  .menu-bar {
    padding-top: 88px;

    margin: 0 auto;
    li {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 40px;
      font-size: 14px;
    }
    .icon {
      display: block;
      font-style: normal;
      margin: 0 6.66666%;
      font-size: 16px;
    }
    .country-icon {
      width: 16px;
      height: 16px;
      background-size: 100%;
      background-repeat: no-repeat;
      transform: scale(1.2);
    }
    .Japanese-icon {
      @include bg-image('~@/images/header/Japanese');
    }
    .English-icon {
      @include bg-image('~@/images/header/English');
    }
    .van-dropdown-menu,
    .language-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 60px;
      width: 180px;
      margin: 0 auto;
    }
    .van-ellipsis {
      margin-right: 54px;
    }
    .van-dropdown-menu__item {
      justify-content: flex-end;
    }
    .language-icon {
      position: absolute;
      left: 20px;
      top: 50%;
      width: 25px;
      height: 16px;
      z-index: 10;
      transform: translateY(-50%);
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

.menu{
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y:hidden;
    pointer-events: none;
    *{
        pointer-events: all;
    }
    .van-overlay{
        position: absolute;
        height: 100vh;
    }
    .van-popup{
        position: absolute;
    }
    /*.van-popup--right{
        top:50vh;
    }*/

}


  @media screen and (min-width: 480px) {
    .menu-bar {
      padding-top: 98px;
    }
  }
  @media screen and (min-width: 640px){
    .menu-bar {
      padding-top: 110px;
    }
  }
  @media screen and (min-width: 1024px) {
    .menu-bar {
      padding-top: 122px;
    }
  }
</style>
