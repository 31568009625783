<template>
  <div class="page-new-bankcard">
    <div class="header">
      <v-common-top :class="{'ct-white':1}"></v-common-top>
    </div>
    <v-bankcard-add :id="id" @saved="saved" :showDel="true"></v-bankcard-add>
  </div>
</template>

<script>
  import vCommonTop from '../block/common-top';
  import vBankcardAdd from '../block/bankcardAdd';
  import {delBankCard, getBankCard, saveBankCard} from '../../api';
  import common from '@/common/common'
  let langTranslation = common.langTranslation;
  export default {
    components: {
      vCommonTop,
      vBankcardAdd
    },
    computed: {
      id(){
        return this.$route.params.id
      },

    },
    data () {
      return {

      }
    },
    created() {

    },
    methods: {
      saved(){
        this.$router.go(-1);
      },

    }
  }

</script>


