<template>
    <div class="black-ui" style="height: 100vh">
        <v-common-top :rightBtn="{text:'记录',to:'/mine/history'}"></v-common-top>
        <el-form :model="form" :rules="rules" ref="mainForm" label-width="0">
            <div class="transfer-box">
                <div class="tb-from">
                    <p>{{fromText}}</p>
                    <em>{{ form.type==0?fil:mine_fil|toFixed(8) }} FIL</em>
                    <s>から</s>
                </div>
                <div class="tb-to">
                    <p>{{toText}}</p>
                    <s>へ</s>
                </div>
                <i class="icon-transfer" @click="form.type=1-form.type"></i>
            </div>
            <div class="transfer-val">
                <p>送付数量</p>
                <el-form-item prop="transfer" :error="formError.transfer" ref="fi_btc">
                    <el-input @focus="handleFocus" @blur="handleBlur" class="text" v-model="form.transfer"  placeholder="">
                        <template slot="append">
                            <em>FIL</em>
                            <a @click.stop="form.transfer=form.type==0?fil:mine_fil">全部</a>
                        </template>
                    </el-input>
                </el-form-item>
            </div>

            <hr color="#525567">
            <div class="transfer-text">
                <p><em style="color: #FFE5A8">効率影響残高</em>の送付指示を行う場合は、翌日マイニング効率が最大30％低下し、フルパワーでマイニングができなくなるため、採掘できる量に影響を及ぼします。</p>
            </div>
            <div class="pay-bottom" style="margin-top: calc(100vh - 550px)">

                <a href="javascript:;" @click="transfer()" class="button01">{{'送付を行う'|langTranslation}}</a>
            </div>
        </el-form>
        
    </div>

</template>


<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {getMyBalance, getWithdrawInfo, sendMobileCode, transferMineFil, withdraw} from '../api';
    import Big from 'big.js' ;
    import common from '@/common/common'
    let langTranslation = common.langTranslation;
    import Vuex from 'vuex';

    export default {

        data() {
            let valTransfer = (rule, value, callback) => {

                if (!value || !value.trim()) {
                    return callback(new Error(langTranslation('请填划转金额')));
                }

                if (this.form.type == 0) {

                    if (Big(value).cmp(this.fil) > 0) {
                        return callback(new Error(langTranslation('划转金额大于余额')));
                    }
                } else {
                    if (Big(value).cmp(this.mine_fil) > 0) {
                        return callback(new Error(langTranslation('划转金额大于余额')));
                    }
                }

                callback();
            }
            return {
                transfering:false,
                fil: 0,
                mine_fil: 0,
                lock_fil: 0,
                form: {
                    type: 0,
                    transfer: 0,
                },

                formError: {
                    transfer: ''
                },
                rules: {

                    transfer: [
                        {
                            validator: valTransfer,
                            trigger: 'blur'
                        }
                    ],
                }

            }
        },
        computed: {
            ...Vuex.mapState(['userInfo']),
            fromText() {
                return this.form.type == 0 ? '送付可能残高' : 'マイニング残高';
            },
            toText() {
                return this.form.type == 0 ? 'マイニング残高' : '送付可能残高';
            },
        },
        components: {
            vCommonTop
        },
        methods: {
            handleFocus(e) {
                e.target.classList.add('input-focus')
            },
            handleBlur(e) {
                e.target.classList.remove('input-focus')
            },
            transfer() {
                this.$refs.mainForm.validate(rs => {
                    if (rs) {
                        if(this.transfering) return ;
                        this.transfering = true;
                        transferMineFil(this.form.type,this.form.transfer).then(()=>{
                            this.$message({
                                message: langTranslation('划转成功'),
                                type: 'success'
                            });
                            setTimeout(()=>{
                                let to = '/mine/history';
                                this.$router.push(to);
                            },1000)
                        },json=>{
                            this.transfering = false;
                            this.$message(json.message);
                        })
                        
                    }
                })
            },
        },
        watch: {},
        created() {
            getMyBalance('fil').then(json => {
                this.fil = json.data.fil;
                this.lock_fil = json.data.lock_fil;
                this.mine_fil = json.data.mine_fil;
            })
        },
        template: '__template__'
    }
    
</script>
<style>
body,html,#app{
    min-height: 100%;
}
.van-dialog-rs-btns .van-dialog__footer--buttons{
    flex-direction:row-reverse;
}
hr{
    border-bottom: none;
    margin:50px 20px 20px;
}  
</style>
<style scoped lang="scss">
    
</style>
