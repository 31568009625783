<template>
    <div class="black-ui" style="height: 100%">
        <v-common-top :rightBtn="{text:'提币记录',to:'/withdraw/record/btc'}"></v-common-top>
        <el-form :model="form" :rules="rules" ref="mainForm" label-width="0">
            <div class="pay-bottom pay-set">
                <div class="account-info">
                    <h3 class="title">{{'可用余额'|langTranslation}}</h3>
                    <div class="content">
                        {{btc|toFixed(8)}} <em>BTC</em>
                        <div class="desc">≒ {{money|toFixed(2)}}円</div>
                    </div>
                </div>
                <div class="wallet-content">
                    <el-form-item prop="walletBTC" :error="formError.walletBTC" ref="fi_wallet">
                        <el-input @focus="handleFocus" @blur="handleBlur" class="text" v-model="form.walletBTC" ref="walletBTC" :placeholder="'提币地址'|langTranslation"></el-input>
                    </el-form-item>
                    <el-form-item prop="withdrawBTC" :error="formError.withdrawBTC" ref="fi_btc">
                        <el-input @focus="handleFocus" @blur="handleBlur" class="text" v-model="form.withdrawBTC"  placeholder=""></el-input>
                    </el-form-item>

                    <div class="ps-tab-sel">
                        <div class="left">
                            <a href="javascript:;" :class="{'current':currentScale==1}" @click="changeScale(1)">10%</a>
                            <a href="javascript:;" :class="{'current':currentScale==2}" @click="changeScale(2)">20%</a>
                            <a href="javascript:;" :class="{'current':currentScale==5}" @click="changeScale(5)">50%</a>
                            <a href="javascript:;" :class="{'current':currentScale==10}" @click="changeScale(10)">100%</a>
                        </div>
                        <!--<a href="javascript:;" :class="{'current':currentScale==10}" @click="changeScale(10)">100%</a>-->
                        <!--<span class="input-rate">
                                <input  type="text" placeholder="カスタム" maxlength="3" />
                            </span>-->
                    </div>
                </div>
            </div>
            <div class="p-detail">

                <p>{{'提币手续费'|langTranslation}}：{{handlingFeeBTC}}BTC &nbsp; {{'最少提币数量'|langTranslation}}：{{minHandlingBTC}}BTC</p>
                <p>
                    {{'请务必填写BTC提币地址，如果将BTC误提到其他的地址可能会导致无法收到。此外如果误提到其他地址而造成的损失由您自己承担，尽请谅解。'|langTranslation}}
                    </p>
                <p>{{'提币收费每次收取{handlingFeeBTC}BTC。可用余额或者最少提币数量低于手续费的情况不可提币，敬请谅解。'|langTranslation({"handlingFeeBTC":handlingFeeBTC})}}</p>

            </div>

            <div class="pay-bottom">
                <div class="code-box">
                    <el-form-item prop="code" :error="formError.code" ref="fi_mobile">
                        <el-input class="text" v-model="form.code" ref="code" :placeholder="'验证码'|langTranslation"></el-input>
                    </el-form-item>
                    <label @click="getMobileCode">
                        {{countDown>0?countDown+'s':'获取验证码'|langTranslation}}
                    </label>
                </div>

                <a href="javascript:;" @click="withdraw()" class="button01">{{'进行提币'|langTranslation}}</a>
            </div>
        </el-form>
    </div>

</template>
<style type="text/css">
    body {
        height: 100%;
    }
</style>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {getWithdrawInfo,sendMobileCode,withdraw} from '../api';
    import Big from 'big.js' ;
    import common from '@/common/common'
    let langTranslation = common.langTranslation;
    import Vuex from 'vuex';

    export default {
      computed: {
        ...Vuex.mapState(['userInfo']),
          ...Vuex.mapGetters(['isActiveUser']),
      },
        data() {
            return {
                radio: '1',
                active: 'btc',
                btc: 0,
                btcOut: 0,
                money: 0,
                handlingFeeBTC: '0.0015',
                minHandlingBTC:'0.002',
                currentScale: 2,


                countDown:0,
                countDownMax: 60,

                form:{
                    code:'',
                    withdrawBTC: 0,
                    walletBTC:''
                },

                formError :{
                    code:'',
                    withdrawBTC: '',
                    walletBTC:''
                },
                rules: {
                    walletBTC:[
                        {
                            validator(rule, value, callback) {
                                if(!value||!value.trim()){
                                    return callback(new Error(langTranslation('请填写钱包地址')));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    withdrawBTC: [
                        {
                            validator:(rule, value, callback)=>{
                                if(!value||!value.trim()){
                                    return callback(new Error(langTranslation('请填写提现数量')));
                                }
                                if(value>this.btc){
                                    return callback(new Error(langTranslation('BTC申请提现数量不能超过当前余额')));
                                }

                                if(value<this.minHandlingBTC){
                                    return callback(new Error(langTranslation('提现数量不能少于{minHandlingBTC}BTC',{'minHandlingBTC':this.minHandlingBTC})));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    code: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机验证码')));
                                }
                                if (value.length != 4) {
                                    return callback(new Error(langTranslation('请输完整的验证码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],

                }

            }
        },
        components: {
            vCommonTop
        },
        methods: {
            handleFocus(e){
              e.target.classList.add('input-focus')
            },
            handleBlur(e){
              e.target.classList.remove('input-focus')
            },
            changeScale(scale) {
                this.currentScale = scale;
                this.form.withdrawBTC = Big(this.btc).times(scale).div(10).round(8,0).toFixed(8)
            },
            startCountDown(cur) {
                this.countDown = cur;
            },
            getMobileCode() {
                if (this.countDown > 0) return;
                sendMobileCode('withdraw_btc').then(json => {

                    if (json.data && json.data.count_down) {
                        this.countDownMax = json.data.count_down;
                    }

                    if(json.data && json.data.code){
                        this.form.code = json.data.code;
                    }

                    this.startCountDown(this.countDownMax);
                    this.$message({
                        message: langTranslation('发送成功'),
                        type: 'success'
                    });
                }, json => {

                    if (json.errorData && json.errorData.leftTime) {
                        this.startCountDown(json.errorData.leftTime);
                    }

                    this.$message(json.message);
                })
            },
            withdraw() {
                this.$refs.mainForm.validate(rs => {



                    if (rs) {
                        if(this.withdrawing) return ;
                        this.withdrawing = true;

                        withdraw('btc',this.form).then(()=>{
                            this.$message({
                                message: langTranslation('提现申请提交成功'),
                                type: 'success'
                            });
                            setTimeout(()=>{
                                let to = '/withdraw/record/btc';
                                this.$router.push(to);
                            },1000)
                        },json=>{
                            this.withdrawing = false;
                            this.$message(json.message);
                        })
                    }
                })

            }
        },
        watch: {},
        created() {
            if(!this.isActiveUser){
                this.$router.push({path:'/ht'});
                return ;
            }
            getWithdrawInfo('btc').then(json => {
                this.btc = json.data.btc || 0;
                this.btcOut = json.data.btcOut || 0;
                this.money = json.data.money || 0;
                this.handlingFeeBTC = json.data.handlingFeeBTC || 0.0015;
                this.minHandlingBTC = json.data.minHandlingBTC || 0.002;
                this.form.withdrawBTC = Big(this.btc).times(this.currentScale).div(10).round(8,0).toFixed(8)
            });

            let _ = () => {
                if (this.countDown > 0) {
                    this.countDown -= 1;
                }
                this.timer = setTimeout(_, 1000)
            }
            _();
        },
        template: '__template__'
    };
</script>
