const basePath = '/api/1.0/web/1.0/';
import common from '@/common/common'
let langTranslation = common.langTranslation;
import axios from "axios";

/**
 * 默认处理
 * @param axios
 * @returns {Promise}
 */
function defaultDo(axios) {
    let st = 0;
    let timer = setTimeout(function () {
        //显示加载中
        st = +new Date;
    }, 500);

    function closeTip() {
        clearTimeout(timer);
        if (st) {
            setTimeout(function () {

            }, Math.max(300 + st - new Date, 0));
        }

    }

    return new Promise((resolve, reject) => {
        axios.then(function (json) {
            closeTip();
            if (json && json.state) {
                resolve(json);
            } else {
                reject(json);
            }

        }).catch(function () {
            closeTip()
            reject({state: false, message: langTranslation('接口响应错误')})
        })
    })

}

export function sendMobileCode( type,mobile) {
    return defaultDo(axios({
        url:basePath+'login/send_mobile_code',
        method:'post',
        data:{
            type,mobile
        }
    }))
}

export function register(form) {
    return defaultDo(axios({
        url:basePath+'login/register',
        method:'post',
        data:{
            form
        }
    }))
}

export function save(data) {
    return defaultDo(axios({
        url: basePath + '/test/save',
        method: 'post',
        data: {
            data
        }
    }));
}

export function loadProduct(id) {
    return defaultDo(axios({
        url:basePath+'product/get',
        params:{
            id:id
        }
    }))
}

export function loadArticle(id) {
    return defaultDo(axios({
        url:basePath+'article/get',
        params:{
            id:id
        }
    }))
}



export function loadIndexArticles() {
    return defaultDo(axios({
        url:basePath+'article/get_index_list',
    }))
}


export function loadProductSysArticles(pro_type) {
    return defaultDo(axios({
        url:basePath+'article/get_product_sys_articles',
        params:{pro_type}
    }))

}

export function loadRedemptionArticle() {
    return defaultDo(axios({
        url:basePath+'article/get_redemption_article',
    }))

}
export function loadLeaseSysArticles(order_code) {
    return defaultDo(axios({
        url:basePath+'article/get_lease_sys_articles?order_code='+order_code,
    }))

}

export function loadIndexSysArticles() {
    return defaultDo(axios({
        url:basePath+'article/get_index_sys_articles',
    }))

}

export function getNewsList(){
    return defaultDo(axios({
        url:basePath+'article/get_news_list',
    }))
}

export function getFrontProducts() {
    return defaultDo(axios({
        url:basePath+'product/get_front_products',

    }))
}
export function createOrder(id,count,address_id,upgrade_order_code) {
    return defaultDo(axios({
        url:basePath+'order/create_order',
        method:'post',
        data:{
            id,
            count,
            address_id,
            upgrade_order_code
        }
    }))
}

export function leaseOrder(order_code,expiration_index) {
    return defaultDo(axios({
        url:basePath+'order/lease_order',
        method:'post',
        data:{
            order_code,expiration_index
        }
    }))
}



export function shipOrder(order_code) {
    return defaultDo(axios({
        url:basePath+'order/ship_order',
        method:'post',
        data:{
            order_code
        }
    }))
}


export function cancelOrder(order_code) {
    return defaultDo(axios({
        url:basePath+'order/cancel_order',
        method:'post',
        data:{
            order_code
        }
    }))
}


export function redemption(order_code,bank_info) {
    return defaultDo(axios({
        url:basePath+'order/redemption',
        method:'post',
        data:{
            order_code,
            bank_info
        }
    }))
}



export function payment(order_code,payment) {
    return defaultDo(axios({
        url:basePath+'order/stripe_pay',
        method:'post',
        data:{
            order_code,
            payment
        }
    }))
}


export function setPayWay(order_code,pay_way,pay_key) {
    return defaultDo(axios({
        url:basePath+'order/set_pay_way',
        method:'post',
        data:{
            order_code,
            pay_way,
            pay_key
        }
    }))
}


export function loadOrder(order_code,detail,product,btc_record,withdraw) {
    return defaultDo(axios({
        url:basePath+'order/get',
        params:{
            order_code,
            detail,
            product,
            btc_record,
            withdraw
        }
    }))
}




export function checkPayment() {
    return defaultDo(axios({
        url:basePath+'order/check_payment',
    }))
}



export function loadUserInfo(checkHasMine='') {
    return defaultDo(axios({
        url:basePath+'user/get_my_info',
        params:{
            check_has_mine:checkHasMine
        }
    }))
}

export function getMyMachine(status,type) {
    return defaultDo(axios({
        url:basePath+'order/get_my_machine',
        params:{
            status,
            type
        }
    }))
}

export function getMyBalance(type) {
    return defaultDo(axios({
        url:basePath+'user/get_my_balance',
        params:{
            type
        }
    }))
}


export function getWithdrawInfo(type) {
    return defaultDo(axios({
        url:basePath+'user/get_withdraw_info',
        params:{
            type
        }
    }))
}

export function getMineInfo(){
    return defaultDo(axios({
        url:basePath+'user/get_mine_info',
    }))
}


export function getIncomeList(opt) {
    return defaultDo(axios({
        url:basePath+'user/income_list',
        params:{
            ...opt
        }
    }));
}



export function getPercentageList(opt) {
    return defaultDo(axios({
        url:basePath+'user/percentage_list',
        params:{
            ...opt
        }
    }));
}


export function getWithdrawList(opt) {
    return defaultDo(axios({
        url:basePath+'wallet/withdraw_list',
        params:{
            ...opt
        }
    }));
}

export function getAddressList(business) {
    return defaultDo(axios({
        url:basePath+'address/address_list?business='+(business?1:''),

    }));
}

export function getAddress(id) {
    return defaultDo(axios({
        url:basePath+'address/get_address',
        params:{
            id
        }
    }));
}


export function saveAddress(form) {
    return defaultDo(axios({
        url:basePath+'address/save',
        method:'post',
        data:{
            form:form
        },
    }));
}


export function getBankCardList() {
    return defaultDo(axios({
        url:basePath+'bank_card/bank_card_list'

    }));
}


export function getBankCard(id){
    return defaultDo(axios({
        url:basePath+'bank_card/get_bank_card',
        params:{
            id
        }
    }));
}



export function saveBankCard(form) {
    return defaultDo(axios({
        url:basePath+'bank_card/save',
        method:'post',
        data:{
            form:form
        },
    }));
}


export function delBankCard(id) {
    return defaultDo(axios({
        url:basePath+'bank_card/del',
        method:'post',
        data:{
            id
        },
    }));
}


export function loadWithdraw(id) {
    return defaultDo(axios({
        url:basePath+'wallet/get_withdraw',
        params:{
            id
        }
    }));
}

export function useContractCode(order_code,code) {
    return defaultDo(axios({
        url:basePath+'user/use_contract_code',
        method:'post',
        data:{
            order_code,
            code
        }
    }));
}

export function getYesterdayIncome(currency){
    return defaultDo(axios({
        url:basePath+'user/get_yesterday_income',
        params:{
            currency
        }
    }));
}


export function editPassword(form) {
    return defaultDo(axios({
        url:'/api/1.0/web/1.0/user/change_password',
        method:'post',
        data:{
            form:form
        },
    }));
}



export function resetPassword(form) {
    return defaultDo(axios({
        url:'/api/1.0/web/1.0/login/reset_password',
        method:'post',
        data:{
            form:form
        },
    }));
}



export function withdraw(type,form) {
    return defaultDo(axios({
        url:'/api/1.0/web/1.0/wallet/withdraw',
        method:'post',
        data:{
            type,
            form:form
        },
    }));
}



export function transferMineFil(type,transfer) {
    return defaultDo(axios({
        url:'/api/1.0/web/1.0/user/transfer_mine_fil',
        method:'post',
        data:{
            type,
            transfer
        },
    }));
}

export function getFilMineLogList(opt){
    return defaultDo(axios({
        url:basePath+'user/fil_mine_log_list',
        params:{
            ...opt
        }
    }));
}

export function getFilMineStat(){
    return defaultDo(axios({
        url:basePath+'user/fil_mine_stat',
        params:{
            
        }
    }));
}


export function getRealTimePrice() {
    return defaultDo(axios({
        url:basePath+'product/get_real_time_price',
    }))
}

export function updateUserName(form){
    return defaultDo(axios({
        url:'/api/1.0/web/1.0/user/update_user_name',
        method:'post',
        data:{
            form
        },
    }));
}
export function changeMobile(form) {
    return defaultDo(axios({
        url:'/api/1.0/web/1.0/user/change_mobile',
        method:'post',
        data:{
            form
        },
    }));
}

export function logout(){
    return defaultDo(axios({
        url:'/api/1.0/web/1.0/login/logout?type=api',
        method:'get',
    }));
}
