import Vue from 'vue';
import {
  Icon,
  Popup,
  Picker,
  DropdownMenu,
  DropdownItem,
  Tab,
  Tabs,
  RadioGroup,
  Radio,
  Cell,
  CellGroup,
  Field,
  Button,
  Toast,
  Dialog,
  Slider
} from 'vant';


Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(Slider);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

let $notice = {
  toast: Toast,
  dialog: Dialog
}
Vue.prototype.$notice = $notice;
