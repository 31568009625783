<template>
    <div>
        <v-common-top></v-common-top>
        <div class="limit-box">

            <div v-if="withdraw" class="pay-status">

                <div class="ps-info">

                   <i :class="{'icon-font-order':withdraw.status==1,'icon-font-success':withdraw.status==3,'icon-font-warning':withdraw.status==4||withdraw.status==0}" class="icon-circle"></i>

                    <p style="margin-top: 30px;">{{(withdraw.approval_info||withdraw.withdraw_type==1?'提现详情':'提币详情')|langTranslation}}</p>

                </div>
                <div class="ps-info ps-detail" v-if="withdraw.withdraw_type==1">
                    <p><span>{{'付款成功金额'|langTranslation}}</span></p>
                    <p>
                        <strong v-if="withdraw.status==3 || withdraw.type==1">{{withdraw.amount|moneyToShow}} 円</strong>
                        <strong v-else>{{'审核中'|langTranslation}}</strong>
                    </p>

                </div>
                <div class="ps-info ps-detail" v-else>
                    <p><span>{{withdraw.withdraw_type==2?'BTC提币数量':'FIL提币数量'|langTranslation}}</span></p>
                    <p><strong>{{withdraw.amount|toFixed(8)}} {{withdraw.withdraw_type==2?'BTC':'FIL'}}</strong></p>

                </div>
                <div class="ps-bottom">
                    <p><em>
                        {{'提币时间'|langTranslation}}：{{withdraw.create_time|dateFormat}}
                    </em>
                    </p>
                    <template v-if="withdraw.withdraw_type==1">
                        <p><strong>{{'银行汇款账户信息'|langTranslation}}</strong></p>
                        <p><em>{{'银行名称'|langTranslation}}：{{withdraw.collection_info.bank_name}}</em></p>
                        <p><em>{{'本·支店名'|langTranslation}}：{{withdraw.collection_info.sub_name}}</em></p>
                        <p><em>{{'口座種别'|langTranslation}}：{{withdraw.collection_info.bank_type}}</em></p>
                        <p><em>{{'银行账号'|langTranslation}}：{{withdraw.collection_info.card_no}}</em></p>
                        <p><em>{{'口座名義'|langTranslation}}：{{withdraw.collection_info.name}}</em></p>

                    </template>
                    <template v-else>
                        <p><em>
                            {{'提币记录'|langTranslation}}：{{withdraw.collection_info.account_card_num}}
                        </em></p>
                    </template>


                </div>
            </div>
        </div>
        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {loadWithdraw} from '../api';

    export default {
        props: ['id'],
        data() {
            return {
               withdraw:null
            }
        },
        components: {
            vCommonTop
        },

        watch: {},
        methods: {

        },
        created() {
            if(!this.$store.getters.isLogin){
                return this.goLogin();
            }
           loadWithdraw(this.id).then(json=>{
                this.withdraw = json.data;
           },json=>{
               this.$message(json.message);
           })
        },
        template: '__template__'
    };
</script>

