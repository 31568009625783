<template>
    <div>
        <v-common-top :hasShadow="true" ></v-common-top>
        <div class="limit-box">
            <div class="user-nav-tab" v-if="!$route.params.date">
                <a href="javascript:;" @click="changeType('')"   :class="{current:type==''}">{{'利用履歴'|langTranslation}}</a>
                <a href="javascript:;" @click="changeType('pledge')" :class="{current:type=='pledge'}">{{'担保詳細'|langTranslation}}</a>
            </div>

            <div v-if="!list.length && !loading" class="address-empty">
                <h3>利用履歴がありません</h3>
            </div>
            <div v-if="list.length || loading" class="user-data-list">
                <div v-if="type=='pledge' && statData" style="overflow: hidden">
                    <div style="width: 50%; float: left">
                        <div style="margin-left: 25px; line-height: 180%; font-size: 12px; color:#4A4A4A">
                            本日担保凍結：<br/>
                            {{statData.pledge}} FIL
                        </div>
                        <div style="margin-left: 25px; margin-top: 10px; line-height: 180%; font-size: 12px; color:#4A4A4A">
                            本日担保解凍：<br/>
                            {{statData.expire}} FIL
                        </div>
                        
                    </div>
                    <div style="width: 50%; float: left">
                        <div style="margin-left: 25px; line-height: 180%; font-size: 12px; color:#4A4A4A">
                            累計担保凍結：<br/>
                            {{statData.sum_pledge||0}} FIL
                        </div>
                        <div style="margin-left: 25px; margin-top: 10px; line-height: 180%; font-size: 12px; color:#4A4A4A">
                            累計担保解凍：<br/>
                            {{statData.sum_expire||0}} FIL
                        </div>
                    </div>
                    
                    
                </div>
                <ul v-if="!type">

                    <template v-for="(it,index) in list">

                        <li :key="it.id">
                            <span style="line-height: 20px; width: 180px;">
                                {{it.time|formatPhpTimespan('yyyy-MM-dd')}} <br/>
                                {{it.type|transType}}
                            </span>
                            
                            <span style="padding: 4px 0px; flex: auto;text-align: center;min-width: 40px">
                                {{it.type==13?'マイニング残高':it.type==3?'送付可能残高':''}}
                            </span>
                            
                            <span style="line-height: 20px;padding: 4px 0; text-align: right;min-width:130px;">
                                {{it.type>10?'+':'-'}} {{it.fil|toFixed(8)}} FIL
                            </span>
                        </li>

                    </template>

                    <li class="loading" v-if="this.lastLoad">
                        <span>{{'读取中'|langTranslation}}</span>
                    </li>
                 
                </ul>
                <ul v-else>
                    <li>
                            <span style="min-width: 85px; width:27.4%;">
                            凍結日
                            </span>

                        <span style="min-width: 55px;width:17.7%">
                            凍結量
                            </span>
                        <span style="min-width: 85px;width:27.4%;">
                           解凍日
                            </span>
                        <span style="flex: 1 1 auto; min-width: 40px;">
                            ステータス
                            </span>
                      <span style="min-width: 45px; width:45px">
                            説明
                            </span>
                    </li>
                    <template v-for="(it,index) in list">

                        <li :key="it.id">
                            <span style="min-width: 85px;width:27.4%;">
                                {{ it.time|formatPhpTimespan('yyyy-MM-dd') }}
                            </span>
                          <span style="min-width: 55px;width:17.7%">
                             {{ it.fil|toFixed(4) }}
                            </span>
                          <span style="min-width: 85px;width:27.4%;">
                                {{ it.expire_time|formatPhpTimespan('yyyy-MM-dd') }}
                            </span>
                            <span style="flex: 1 1 auto; min-width: 40px;">
                                {{ it.is_freed ? '解凍済み' : '凍結中' }}
                            </span>
                          <span style="min-width: 45px; width:45px">
                                {{ it.seal|toFixed(2) }} T
                            </span>
                        </li>
                    </template>

                    <li class="loading" v-if="this.lastLoad">
                        <span>{{'读取中'|langTranslation}}</span>
                    </li>
                </ul>
            </div>

        </div>

        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import Big from 'big.js';
    import date from '@/common/date';
    import vCommonTop from './block/common-top';
    import {getFilMineLogList, getFilMineStat} from '../api';
    import Vuex from "vuex";

    export default {
        data() {
            return {
                loading:true,
                list:[],
                start:0,
                lastLoad:null,
                type:'',
                statData:null
            }
        },
        components: {
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['noBTC','currency']),
            
        },
        filters:{
            dateFormat: date.format,
            formatPhpTimespan: date.formatPhpTimespan,
            transType(type){
                //1 质押   2 gas扣除 3 转出到余额 11 后台充值 13 余额充值
                let map={
                    1:'担保FIL',
                    2:'GAS費',
                    3:'送付',
                    11:'FIL支払い',
                    13:'送付'
                }
                return map[type];
            }
        },
        watch: {},
        methods: {
          
            loadHistory(reset){
                if((this.lastLoad||this.listEnd) && !reset){
                    return ;
                }
                let start = reset?0:this.start;
                let opt = {
                    start:start,
                    limit:40,
                    sum:start==0?1:0,
                };
                if(this.type=='pledge'){
                    opt.type = 1;
                }


                if(reset){
                    this.listEnd = false;
                    this.list = [];
                }
                this.lastLoad = {...opt,reset};
                getFilMineLogList(opt).then(json=>{
                    if(JSON.stringify(this.lastLoad) != JSON.stringify({...opt,reset})){
                        //不是最后一次请求
                        return false;
                    }
                    this.lastLoad = false;
                    this.loading = false;
                    if(reset){
                        this.list=json.data.list;
                    }else{
                        this.list=this.list.concat(json.data.list);
                    }
                    
                    if(!json.data.list.length){
                        this.listEnd = true;
                    }
                    this.start = this.list.length;
                })
            },
            loadStat(){
                getFilMineStat().then(json=>{
                    console.log(json);
                    this.statData = json.data;
                })
            },
            changeType(t){
                this.type = t;
                if(t=='pledge'){
                    this.loadStat();
                }
                this.loadHistory(true);
            },
            scrollTable: function () {
                let visibleHeight = document.documentElement.clientHeight
                let scrollHeight = document.documentElement.scrollHeight
                let scrollTop = document.documentElement.scrollTop
                if (scrollHeight - (scrollTop + visibleHeight) <= 100) {
                    this.loadHistory()
                }
            }
        },
        beforeDestroy(){
            window.removeEventListener('scroll',this.scrollTable,true)
        },
        created() {
            if(!this.$store.getters.isLogin){
                return this.goLogin();
            }

            this.loadHistory();
            window.addEventListener('scroll', this.scrollTable, true)



        },
        template: '__template__'
    };
</script>
