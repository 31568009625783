<template>
  <div>
    <v-common-top></v-common-top>
    <div class="limit-box">

      <div class="pay-status">

        <div class="ps-info">

          <i class="icon-font-Transfer icon-circle"></i>

          <p>{{'FIL支払い' | langTranslation}}</p>
            <p style="margin: 40px 0">
                <img src="@/images/qrcode_fil_address.png" alt="" width="160">
            </p>
            <p class="clipboard-copy" :data-clipboard-text="'f1tyuin34m44ob6lzxe4ieyyx57ca4dhsyten2k7y'" style="text-align: center">
                {{'FIL専用アドレス' | langTranslation}}<br>
                f1tyuin34m44ob6lzxe4ieyyx57ca4dhsyten2k7y <i style="color:#FDD205" class="icon-font-copy"></i>
            </p>
            <hr style="margin: 60px 0" color="#DCDCDC">
            <p style="margin: 5px 15px"><em>
                上記FIL専用アドレスへFILを送付してください。送付手数料はお客様ご負担となります。送付が確認できるまでに時間がかかる場合があります。
            </em></p>
            <p style="margin: 5px 15px"><em>
                誤って上記以外のアドレスへ送付された場合の損失は、お客様負担になりますのでご了承ください。
            </em></p>
            <p style="margin: 5px 15px"><em>
                初回の支払いは６0日分の担保FILを支払い頂いた後、採掘開始できます。
            </em></p>
            <p style="margin: 5px 15px"><em>
            購入成功のご連絡はいたしませんので、お客様ご自身で「マイニング残高」のメニューよりご確認をお願いいたします。
            </em></p>


            <div class="ps-bottom">

            <div >
              <router-link to="/mine/history" class="button02">{{'利用履歴' | langTranslation}}</router-link>
            </div>
          </div>
        </div>


      </div>

    </div>
    <v-copyright></v-copyright>

  </div>

</template>

<script type="text/javascript">
import vCommonTop from './block/common-top';
import ClipboardJS from 'clipboard';
  export default {
    data() {
      return {
     
      }
    },
    components: {
      vCommonTop
    },
    computed: {
   


    },
    watch: {
    
    },
    methods: {
    
    },
    created() {
  

    },
    updated(){

    },
    beforeDestroy(){
    
    },
    template: '__template__'
  };
</script>
