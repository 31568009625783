<template>
  <div class="page-username">
    <div class="header">
      <v-common-top></v-common-top>
    </div>
      <div class="limit-box">

          <div class="reg-box">
              <h2>{{'修改用户名'|langTranslation}}</h2>
              <div>
                  <input v-model="form.user_name" class="text" :placeholder="'请输入用户名称'|langTranslation">
              </div>
              <div>
                  <a href="javascript:;" @click="saveUserName" class="button01">{{'发送'|langTranslation}}</a>
              </div>

          </div>
      </div>

    </div>
</template>

<script>
  import vCommonTop from '../block/common-top';
  import {mapState,mapMutations} from 'vuex';
  import {updateUserName} from '../../api'
  import common from '@/common/common'
  let langTranslation = common.langTranslation;
  export default {
    components: {
      vCommonTop
    },
    data () {
      return {
        form: {
            user_name:window.USER_INFO.user_name
        }
      }
    },
    computed:{
        ...mapState(['userInfo'])
    },
      methods:{
        ...mapMutations(['setUserName']),
          saveUserName(){
              if(this.form.user_name)
              updateUserName(this.form).then(()=>{
                  this.$message({
                      message: langTranslation('保存しました！'),
                      type: 'success'
                  });
                  this.setUserName(this.form.user_name);
                  USER_INFO.user_name = this.form.user_name;
                  setTimeout(()=>{
                      let to = '/user/edit';
                      this.$router.push(to);
                  },1000)
              },json=>{
                  this.$message(json.message);
              })
          }
      }
  }

</script>

<style lang="scss">
  @import "~@/scss/mixin.scss";
.page-username {
  .header {
    height: 50px;
  }
  .main {
    height: 87px;
    padding-top: 15px;
    padding-left: 34px;
    @include set-placeholder(#8B9795);
    border-bottom: 1px solid #DCDCDC;
    .name {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(54, 55, 55, 1);
      margin-bottom: 14px;
    }
    .van-cell {
      font-size: 16px;
    }
  }
}
</style>
