<template>
    <div>
        <v-header
          :class="{'index-header':newsShow}"
          :hasMenu="newsShow"
          :isMenuShow="newsShow"
          @openmenu="stopBanner"
          @shutmenu="startBanner"
          @showMenu="showNews"
          @closeMenu="closeNews"></v-header>

        <div class="limit-box">

            <div class="banner" id="js-banner-box" v-if="!showProduct && indexArticles && indexArticles.length">
                <ul class="images" ref="bannerBox" style="transform: translateX(15px)">
                    <!--<li>
                        <a href=""><img src="/Assets/tmp_img/banner.png" alt=""></a>
                    </li>-->

                    <li v-for="(art, idx) in indexArticles" :key="idx">
                        <a :href="art.art_link" v-if="art.art_link" target="_blank">
                            <img :src="art.art_pic||'/assets/tmp_img/def_banner.png'" alt="">
                        </a>
                        <router-link :to="'/article/'+art.id" v-else>
                            <img :src="art.art_pic||'/assets/tmp_img/def_banner.png'" alt="">
                        </router-link>
                    </li>

                    <!--<li>
                        <a href=""><img src="/Assets/tmp_img/banner.png" alt=""></a>
                    </li>-->
                </ul>
                <ul class="dots">
                    <li v-for="(art, idx) in indexArticles" :key="idx"></li>
                </ul>
            </div>

            <div v-if="!showProduct" class="main-btns">
                <div class="mb-item">
                    <router-link to="/product">
                        <i class="icon-circle icon-font-machine"></i>
                        <span>{{(currency=='FIL'?'購入する':'购买矿机')|langTranslation}}</span>
                    </router-link>
                </div>
                <div class="mb-item">
                    <router-link to="/user" v-if="currency=='FIL'">
                        <i class="icon-circle icon-font-loanout"></i>
                        <span>{{'マイページ'|langTranslation}}</span>
                    </router-link>
                    <router-link to="/lease" v-else>
                        <i class="icon-circle icon-font-loanout"></i>
                        <span>{{'出租矿机'|langTranslation}}</span>
                    </router-link>
                </div>
            </div>

            <div v-if="!showProduct && articles.index_01" class="main-content">
                <template v-if="currency=='FIL'">
                    <div class="mc-item">
                        <div class="mci-detail">
                            <h2>{{articles.index_01.art_title}}</h2>
                            <div>{{articles.index_01.art_desc}}</div>
                            <router-link :to="'/article/14'" class="button02">
                                {{'详情'|langTranslation}}
                            </router-link>

                        </div>
                        <div class="mci-img">
                            <img :src="articles.index_01.art_pic" alt="" >
                        </div>
                    </div>
                    <div class="mc-item">
                        <div class="mci-img">
                            <img :src="articles.index_02.art_pic" alt="" >
                        </div>
                        <div class="mci-detail">
                            <h2>{{articles.index_02.art_title}}</h2>
                            <div>{{articles.index_02.art_desc}}</div>

                            <router-link :to="'/article/16'" class="button02">
                                {{'详情'|langTranslation}}
                            </router-link>
                        </div>

                    </div>

                    <div class="mc-item">
                        <div class="mci-detail">
                            <h2>{{articles.index_03.art_title}}</h2>
                            <div>{{articles.index_03.art_desc}}</div>

                            <router-link :to="'/article/17'" class="button02">
                                {{'详情'|langTranslation}}
                            </router-link>
                        </div>
                        <div class="mci-img">
                            <img :src="articles.index_03.art_pic" alt="" >
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="mc-item">
                        <div class="mci-detail">
                            <h2>{{articles.index_01.art_title}}</h2>
                            <div v-html="articles.index_01.art_detail"></div>
                            <router-link to="/product" class="button02">
                                {{'详情'|langTranslation}}
                            </router-link>

                        </div>
                        <div class="mci-img">
                            <img :src="articles.index_01.art_pic||'/assets/tmp_img/article1.jpg'" alt="">
                        </div>
                    </div>
                    <div class="mc-item">
                        <div class="mci-img">
                            <img :src="articles.index_02.art_pic||'/assets/tmp_img/article2.jpg'" alt="" >
                        </div>
                        <div class="mci-detail">
                            <h2>{{articles.index_02.art_title}}</h2>
                            <div v-html="articles.index_02.art_detail"></div>

                            <router-link to="/lease" class="button02">
                                {{'详情'|langTranslation}}
                            </router-link>
                        </div>

                    </div>
                </template>


            </div>

            <div class="products" v-if="showProduct">
                <h1>{{'矿机列表'|langTranslation}}</h1>
                <ul>
                    <li v-for="(item, idx) in productList" :class="{'no-img':!item.pro_pic}" @click="item.pro_inventory!=0 && !item.sale_time_text && goProduct(item.id)" :key="idx" :style="{'padding-bottom':item.sale_time_text?'40px':'20px'}">
                        <div v-if="item.pro_pic" class="p-img" :style="{'background-image':'url('+item.pro_pic+')'}"></div>
                        <i v-if="item.pro_inventory==0" class="icon-sell-out"></i>
                        <div class="p-info" v-if="item.pro_type==0">
                            <p class="title">
                                {{item.pro_name}}
                                <em  v-if="!item.pro_pic">{{'库存数'|langTranslation}} {{item.pro_inventory}}</em>
                            </p>

                            <p class="desc">{{'挖矿效率'|langTranslation}}  {{item.pro_power}} W &nbsp; &nbsp; {{item.pro_base_calc}} {{'台/套'|langTranslation}}</p>
                            <p class="desc">{{'算力'|langTranslation}} {{item.pro_hash_power}} TH/S</p>
                            <p class="desc"  v-if="item.pro_pic">{{'库存数'|langTranslation}}  <em style="color: #ff2f65;">{{item.pro_inventory}}</em> {{'套'|langTranslation}}</p>
                            <div class="buy-info">
                                <span class="bi-price">
                                    <strong>{{item|productPrice|moneyToShow}}円</strong>
                                    <em>/{{'套'|langTranslation}}</em>
                                    <em style="display: block" v-if="!noBTC">
                                    ≒({{item|productPrice|priceToBtc(realTimeBTCPriceJP)}} BTC)
                                    </em>
                                </span>

                                <a v-if="item.pro_inventory==0" href="javascript:;" class="button01 bi-buy disable">{{'购买'|langTranslation}}</a>
                                <router-link v-else :to="'/detail/'+item.id" class="button01 bi-buy">{{'购买'|langTranslation}}</router-link>

                            </div>
                        </div>
                        <div class="p-info" v-else>
                            <p class="title">
                                <i class="icon-fli-product"></i><span>{{item.pro_name}}</span>
                                <!--<em  v-if="!item.pro_pic">{{'库存数'|langTranslation}} {{item.pro_inventory}}</em>-->
                            </p>

                            <p class="desc">
                                <template v-if="item.pro_type==2">
                                  {{item.pro_hash_power}} TB/{{'台'|langTranslation}}
                                </template>
                                <template v-else>
                                  {{item.pro_base_calc}} T
                                </template>

                                <span style="margin-left: 20px">サービス期間 {{item|productExpiration}}{{item.pro_type>100?'年':'日' | langTranslation}}{{item.id>=36 && item.id <= 40 ?'(更新の場合＋540日)':''}}</span>
                            </p>
                            <p class="desc" v-if="item.pro_type==2">
                                シーリング効率  {{item|productSeal}} T/24h
                            </p>
                            <p class="desc">
                                委託管理手数料 {{item|productFee}}
                            </p>

                            <!--<p class="desc"  v-if="item.pro_pic">{{'库存数'|langTranslation}}  <em style="color: #ff2f65;">{{item.pro_inventory}}</em> {{'套'|langTranslation}}</p>-->
                            <div class="buy-info">
                                <span class="bi-price">
                                    <strong>{{item|productPrice|moneyToShow}}円</strong>
                                    <em>/{{'套'|langTranslation}}</em>
                                    <em style="display: block" v-if="!noBTC">
                                    ≒({{item|productPrice|priceToBtc(realTimeBTCPriceJP)}} BTC)
                                    </em>
                                </span>

                                <a v-if="item.pro_inventory==0 || item.sale_time_text" href="javascript:;" class="button01 bi-buy disable">{{'购买'|langTranslation}}</a>
                                <router-link v-else :to="'/detail/'+item.id" class="button01 bi-buy">{{'购买'|langTranslation}}</router-link>

                            </div>

                        </div>
                        <div class="sale-time" v-if="item.sale_time_text" v-html="item.sale_time_text">
                        </div>
                    </li>


                </ul>
            </div>
        </div>

        <div class="index-menu" v-if="!showProduct && articles.index_list">
            <div class="logo"><a href="/" class="router-link-exact-active router-link-active"><i class="bitmining"></i></a></div>
            <h2 v-if="articles.index_list[23].length">{{23|cateName(articles)}}</h2>
            <ul v-if="articles.index_list[23].length">
                <li v-for="(art, idx) in articles.index_list[23]" :key="idx">
                    <router-link :to="'/article/'+art.id + '/'">{{art.art_title}}</router-link>
                </li>
            </ul>

            <h2>{{24|cateName(articles)}}</h2>
            <ul>
                <li><a href="javascript:;" @click="showNews">{{26|cateName(articles)}}</a></li>
                <li v-for="(art, idx) in articles.index_list[24]" :key="idx">
                    <router-link :to="'/article/'+art.id + '/'">{{art.art_title}}</router-link>
                </li>
            </ul>
            <h2>Follow us</h2>
            <ul class="contacts-ls">
                <template v-if="currency=='FIL'">
                    <li><a href="https://line.me/ti/g2/ye6TMBAwU-umncAHFHWUgQ?utm_source=invitation&utm_medium=link_copy&utm_campaign=default
" target="_blank"><i class="icon-font-line"></i></a></li>
                    <li><a href="https://twitter.com/RRMineJP" target="_blank"><i class="icon-font-twitter"></i></a></li>
                </template>
                <template v-else>
                    <li><a href="http://nav.cx/4Sm2Bi8" target="_blank"><i class="icon-font-line"></i></a></li>
                    <li><a href="https://twitter.com/BitMiningGlobal" target="_blank"><i class="icon-font-twitter"></i></a></li>
                </template>

            </ul>
            <h2 v-if="articles.index_list[25].length">{{25|cateName(articles)}}</h2>
            <ul v-if="articles.index_list[25].length">
                <li v-for="(art, idx) in articles.index_list[25]" :key="idx">
                    <router-link :to="'/article/'+art.id + '/'">{{art.art_title}}</router-link>
                </li>
            </ul>
            <hr/>
        </div>

        <div v-if="newsShow" class="index-news" >
            <h1>{{26|cateName(articles)}}</h1>
            <ul class="news-list">
                <li v-for="(art, idx) in newsList" :class="{'no-img':!art.art_pic}" :key="idx">
                    <router-link :to="'/article/'+art.id + '/'">
                        <i v-if="art.art_pic"><img :src="art.art_pic" alt=""></i>
                        <em>
                            <h3>{{art.art_title}}</h3>
                            <span>{{art.art_desc}}</span>
                            <!--<em>
                                <s>{{art.create_time|dateFormat}}</s>
                                &lt;!&ndash;<s>浏览数 {{art.view}}</s>&ndash;&gt;
                            </em>-->
                        </em>

                    </router-link>

                </li>
            </ul>
        </div>

        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">


    import Big from 'big.js';
    import Vuex from 'vuex';
    import vHeader from './block/header';
    import date from '@/common/date';
    import {getFrontProducts,loadIndexArticles,loadIndexSysArticles,getNewsList} from '../api';

    /* 动态设置过渡 */
    var addTransition = function (ele) {
        ele.style.transition = "all .6s";
        ele.style.webkitTransition = "all .6s";
    };
    /* 移除过渡效果 */
    var removeTransition = function (ele) {
        ele.style.transition = "none";
        ele.style.webkitTransition = "none";
    };
    /* 设置容器平移 -- 也就是滑动动画
        ele:当前元素
        xwidth:平移的距离
    */
    var setTranslateX = function (ele, xwidth) {
        ele.style.transform = "translateX(" + xwidth + "px)";
        ele.style.webkitTransform = "translateX(" + xwidth + "px)";
    }

    var bannerFn = function () {
        /** 轮播图分析 */
        /** 1 自动轮播且无缝 [定时器、过渡动画]
         *  2 点要随着图片的轮播而改变 [根据索引切换]
         *  3 滑动效果 [touch事件]
         *  4 图片随着点而变化
         *  5 滑动结束的时候，如果滑动的距离不超过屏幕的1/3，就恢复回去 [过渡]
         *  6 滑动结束的时候，如果滑动的距离超过屏幕的1/3，就切换 [滑动方向 + 过渡]]
         */

        if(bannerFn.doing) return ;
        bannerFn.doing = true;
        function getCurrentIndex(){
            if (index >= length - 1) {
                return 1;
                //  清过渡，瞬间定位imgBox

            }
            // 滑动的时候也需要无缝
            else if (index <= 0) {
                return length-2;
            }
            return index;
        }

        function setCurrentTranslateX(){
            setTranslateX(imgBox,offset -index * width);// 根据当前索引值计算平移的距离
        }
        function moveTo(){
            addTransition(imgBox); // 为轮播图片组的ul设置过渡函数
            setCurrentTranslateX();
            showPoint(index,true); // 改变分页器状态
        }

        /* 封装定时器函数 */
        var startInterval = function () {
            bannerFn.timer = setInterval(function () {
                index++;// 基于索引 做位移
                moveTo()
            }, 3000);
        }
        var banner = document.querySelector("#js-banner-box");
        var boxWidth = banner.offsetWidth;
        var imgBox = banner.querySelector("ul:first-child");  // 轮播图片ul
        var list = imgBox.querySelectorAll("li")
        var length = list.length; // 图片的个数
        var width = list[0].offsetWidth+30;  // 屏幕的宽度
        var offset = (boxWidth-width - 30)/2;
        var pointBox = banner.querySelector("ul:last-child");// 分页器ul
        var points = pointBox.querySelectorAll("li");// 分页器集合


        var index = 1; // 初始化索引，由于要无缝衔接，索引从1开始
        removeTransition(imgBox);
        setCurrentTranslateX();
        showPoint(index,true)
        bannerFn.start = startInterval;
        startInterval();

        /* transitionend事件：当元素过渡动画结束后执行
            每次轮播动画结束后，判断当前索引值
         */
        imgBox.addEventListener("transitionend", function () {
            index = getCurrentIndex();
            removeTransition(imgBox);
            setCurrentTranslateX();
            showPoint(index)
        });

         function showPoint(index,tf) {
            for(let i=0;i<list.length;i++){
                list[i].className = '';
            }
             list[index] && (list[index].className = "current");

            if(tf){
                for (let i = 0; i < points.length; i++) {
                    points[i].className = '';
                }
                points[getCurrentIndex()].className = "current";
            }
          /*  for (var i = 0; i < points.length; i++) {
                points[i].className = '';
            }
            points[index].className = "now";*/
        }
        /* 滑动切换 (touch事件) */
        var startX = 0; // 触摸起始点
        imgBox.addEventListener("touchstart", function (e) {
            // 记录起始位置的X坐标
            clearInterval(bannerFn.timer);
            startX = e.touches[0].clientX;
        });
        var distanceX = 0;
        var translateX = 0;
        // **** 加一个标识，判断用户有没有滑动
        var isMove = false
        imgBox.addEventListener("touchmove", function (e) {
            var moveX = e.touches[0].clientX;
            // 计算位移,有正负方向
            distanceX = moveX - startX;
            // 计算目标元素的位移
            // 元素将要的定位 = 当前定位 + 手指移动的距离
            translateX = -index * width + distanceX;
            // ********** 要想图片实时地跟着手指走，一定要把过渡清楚掉
            removeTransition(imgBox)
            setTranslateX(imgBox, translateX);
            isMove = true;// 确定用户有滑动
            e.preventDefault(); // 去除移动端浏览器默认的滑动事件
        });

        imgBox.addEventListener("touchend", function () {
            if (isMove) {
                if (Math.abs(distanceX) > width / 3) {
                    // 切换
                    if (distanceX > 0) {
                        // 上一张
                        index = index - 1;
                    } else {
                        // 下一张
                        index = index + 1;
                    }

                    if(index<=0){
                        index=length -2;
                        removeTransition(imgBox);
                        setTranslateX(imgBox,translateX -index * width);// 根据当前索引值计算平移的距离
                    }else if(index>=length-1){
                        index = 1;
                        removeTransition(imgBox);
                        setTranslateX(imgBox, translateX + (length-2) * width);// 根据当前索引值计算平移的距离
                    }
                    setTimeout(function(){
                        moveTo()
                    },13)

                   /* if (index >= length-1) {
                        index = 1;
                    }
                    if (index <= 0) {
                        index = length-2;
                    }
                    showPoint(index - 1);*/

                } else {
                    // 不满足滑动条件，回退到之前的状态
                    moveTo()
                    /*showPoint(index - 1);*/
                }
            }
            // **** 最好做一次参数的重置
            startX = 0;
            distanceX = 0;
            isMove = false;
            // ****** 为了严谨，再清一次定时器
            clearInterval(bannerFn.timer)
            // 加上定时器
            startInterval();
        });
    };
    bannerFn.stop = function(){
        clearInterval(bannerFn.timer);
        bannerFn.doing = false;
    }

    export default {
        props:['product'],
        data() {
            return {
                recommend:null,
                productList:[],
                quotes:[],
                quoteType:'btc',
                quoteTypes:['btc', 'eth', 'ltc'/*, 'bhpc'*/ ],
                articles:{
                    index_list:null
                },
                indexArticles:[],
                newsShow:false,
                newsList:null,
            }
        },
        components:{
            vHeader
        },
        filters:{
            expectIncome(v){
                return Big(v.income_btc).times(v.pro_base_calc).round(8,0).toFixed(8) ;
            },
            productPrice(v){
                return Big(v.pro_price).times(v.pro_base_calc).toFixed(2);
            },
            cateName(id,data){
                let rs = data.cates.find(it=>it.id==id);
                return rs && rs.cate_name || id;
            },
            productExpiration(item){
                let other = JSON.parse(item.other);
                let data = other.expirations_income.find(it => it.income && it.income != 0)
                return data.expiration
            },
            productFee(item){
                let other = JSON.parse(item.other);
                let data = other.expirations_income.find(it => it.income && it.income != 0)
                return data.fee+'%'
            },
            productSeal(item){
                let other = JSON.parse(item.other);
                let data = other.expirations_income.find(it => it.income && it.income != 0)
                if(!data.seal || data.seal==0){

                    let t = item.pro_base_calc;
                    if(item.pro_type==2){
                        t*=item.pro_hash_power;
                    }

                    return Big(t).div(data.expiration).toFixed(8)
                }
                return data.seal
            },

        },
        computed: {
            ...Vuex.mapState(['realTimeBTCPriceJP','noBTC','currency']),
            showProduct(){
                return !!this.product;
            }
        },
        methods:{
          startBanner(){
            if(!this.showProduct && this.indexArticles && this.indexArticles.length>3){
              bannerFn();
            }
          },
          stopBanner(){
            bannerFn.stop()
          },
            loadNews(){
                if(!this.newsList){
                    getNewsList().then(json=>{
                        this.newsList = json.data;
                    })
                }
            },
            goProduct(id){
                this.$router.push({  path: '/detail/'+id,})
            },
            loadProducts(){
                if(this.loaded||this.loading) return ;
                this.loading = true;
                getFrontProducts().then(json=>{
                    this.loaded = true;
                    this.recommend = json.data.rec && json.data.rec[0];
                    this.productList = json.data.list || [];
                },()=>{
                    this.loading = false;
                })
            },
            closeNews(){
                this.newsShow = false;
            },
            showNews(){
                this.newsShow = true;
                this.loadNews();
            }
        },
        watch: {

        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                bannerFn.stop();
                if(vm.showProduct){
                    vm.loadProducts();
                }else{
                    if(vm.indexArticles && vm.indexArticles.length){
                        vm.$nextTick(()=>{
                            bannerFn();
                          if(vm.indexArticles.length<=3){
                            bannerFn.stop();
                          }
                        })
                    }

                }
            })
        },
        beforeDestroy() {
          this._timer&&clearTimeout(this._timer);
        },
      created() {
          let sn = ()=>{
            let now = +date.serverNow();
            if(this.productList && this.productList.length){
              this.productList.forEach((item,index)=>{
                let str = '';
                  if(item.sale_time && item.sale_time*1000>now+1000){
                    let t = date.numFormat(item.sale_time*1000 - now,'h:mm:ss').split(':');
                    str = '販売開始まで：'
                    if(t[0]!=0){
                      str+='<em>'+t[0]+'</em>時間'
                    }
                    if(t[0]!=0||t[1]!='00'){
                      str+='<em>'+t[1]+'</em>分'
                    }
                    if(t[0]!=0||t[1]!='00'||t[2]!='00'){
                      str+='<em>'+t[2]+'</em>秒'
                    }

                  }
                this.$set(this.productList[index],'sale_time_text',str);
              })
            }


            this._timer = setTimeout(sn,1000);
          }
          sn();
            /*if(this.showProduct){
                this.loadProducts();

            }*/
/*
            common.jsonp('https://apiv1.renrenmine.com/quotes/last',(error,json)=>{
                let list = ['Coinbase', 'Bithumb', 'Bitfinex', 'Bcex', 'Digifinex', 'Fcoin'];
                if(!error && json){
                    for(var i =0;i<list.length;i++){
                        list[i] = json[list[i]];
                    }

                    this.quotes = list;
                }
            })*/
            loadIndexArticles().then(json =>{
                let list = json.data;
                if(!list.length) return ;
                list.unshift(list[list.length-1])
                list.push(list[1]);
                this.indexArticles = list;
                this.$nextTick(()=>{
                    if(!this.showProduct && this.indexArticles){
                        bannerFn();

                      if(this.indexArticles.length<=3){
                        bannerFn.stop()
                      }
                    }

                })
            });
            loadIndexSysArticles().then(json => {
                this.articles = json.data;
            }, () => {

            });
        },
        template: '__template__'
    };
</script>
