<template>
    <div class="black-ui" style="height: 100%">
        <v-common-top :rightBtn="{text:'提币记录',to:'/withdraw/record/fil'}"></v-common-top>
        <el-form :model="form" :rules="rules" ref="mainForm" label-width="0">
            <div class="pay-bottom pay-set">
                <div class="account-info ai-fil">
                    <h3 class="title">{{'送付可能残高'|langTranslation}}</h3>
                    <div class="content">
                        {{fil|toFixed(8)}} <em>FIL</em>
                        <!--<div class="desc">≒ {{money|toFixed(2)}}円</div>-->
                    </div>
                    <div class="health-info">
                        マイニング効率 <span class="el-icon-warning" @click="showFirst=true"></span>

                        <br/><strong>{{health}}%</strong>
                    </div>
                    <div class="account-component">
                        <div>
                            <p>
                                効率影響残高
                                <el-popover
                                    placement="bottom"
                                    :title="'効率影響残高について'"
                                    width="220"
                                    trigger="click"
                                    content="">
                                    <span class="el-icon-warning" slot="reference"></span>
                                    <div class="pop-text">
                                        ファイルコインのマイニングを行うには、プロトコルラボ公式ルールに従い、担保FILが必要になります。マイニングを起動する担保FILはRRMineが立て替えて負担しております。担保FILは、最短180日後に回収できるルールになっております。送付可能残高180日未満の分を送付される場合は、マイニング効率が100%を下回ります。
                                    </div>

                                </el-popover>
                            </p>
                            <p><strong>{{lt180}} FIL</strong></p>
                        </div>
                        <div>
                            <p>効率影響外残高
                                <el-popover
                                    placement="bottom"
                                    :title="'効率影響外残高について'"
                                    width="220"
                                    trigger="click"
                                    content="">
                                    <span class="el-icon-warning" slot="reference"></span>
                                    <div class="pop-text">
                                        このFILの送付分はマイニング効率に影響がありません。
                                    </div>
                                </el-popover>
                            </p>
                            <p><strong>{{gt180}} FIL</strong></p>
                        </div>
                    </div>
                </div>
                <div class="wallet-content">
                    <el-form-item prop="walletFIL" :error="formError.walletFIL" ref="fi_wallet">
                        <el-input @focus="handleFocus" @blur="handleBlur" class="text" v-model="form.walletFIL" ref="walletFIL" :placeholder="'提币地址'|langTranslation"></el-input>
                    </el-form-item>
                    <el-form-item prop="withdrawBTC" :error="formError.withdrawFIL" ref="fi_btc">
                        <el-input @focus="handleFocus" @blur="handleBlur" class="text" v-model="form.withdrawFIL"  placeholder=""></el-input>
                    </el-form-item>

                    <div class="ps-tab-sel">
                        <div class="left">
                            <a href="javascript:;" :class="{'current':currentScale==1}" @click="changeScale(1)">10%</a>
                            <a href="javascript:;" :class="{'current':currentScale==2}" @click="changeScale(2)">20%</a>
                            <a href="javascript:;" :class="{'current':currentScale==5}" @click="changeScale(5)">50%</a>
                            <a href="javascript:;" :class="{'current':currentScale==10}" @click="changeScale(10)">100%</a>
                        </div>
                        <!--<a href="javascript:;" :class="{'current':currentScale==10}" @click="changeScale(10)">100%</a>-->
                        <!--<span class="input-rate">
                                <input  type="text" placeholder="カスタム" maxlength="3" />
                            </span>-->
                    </div>
                </div>
            </div>
            <div class="p-detail">
                <p>{{'提币手续费'|langTranslation}}：{{handlingFeeFIL}}FIL &nbsp; {{'最少提币数量'|langTranslation}}：{{minHandlingFIL}}FIL</p>
                <p>
                    送付先は必ずFIL対応のアドレスをご入力ください。FILに対応していないアドレスには送付できませんので、ご注意ください。また誤ってFIL以外のアドレスへ送付された場合の損失は客さまの自己負担となリますのでご了承ください。
                </p>
                <p>
                    送付手数料は一回の送付に付き、{{handlingFeeFIL}}FIL必要になります。残高が送付手数料以下の場合、または、最低送付量を満たさない場合は受付できませんのでご了承ください。
                </p>
                <p style="color:#ff0000">
                    送付手続き完了には１〜7営業日かかります。
                </p>
            </div>

            <div class="pay-bottom">
                <div class="code-box">
                    <el-form-item prop="code" :error="formError.code" ref="fi_mobile">
                        <el-input class="text" v-model="form.code" ref="code" :placeholder="'验证码'|langTranslation"></el-input>
                    </el-form-item>
                    <label @click="getMobileCode">
                        {{countDown>0?countDown+'s':'获取验证码'|langTranslation}}
                    </label>
                </div>

                <a href="javascript:;" @click="withdraw()" class="button01">{{'进行提币'|langTranslation}}</a>
            </div>
        </el-form>


        <van-dialog
            class="van-dialog-rs-btns"
            v-model="isShow"
            width="280"
            cancel-button-color="#363737"
            confirm-button-color="#999999"
            show-cancel-button
            @confirm="withdraw(true)"
            confirmButtonText="送付を行う"
            cancelButtonText="キャンセルする"
        >
            <div class="dialog-content">
                <div class="top">
                  <span class="close" @click.stop="isShow = false">
                    <van-icon name="cross"/>
                  </span>
                </div>
                <van-icon name="warning" class="icon"/>
                <div class="message">
                    マイニング効率は<em>{{newHealthShow}}%</em>に低下します！この場合リーシング量が低下するほか、更に採掘ブーストリワードが得られなくなります。
                    </div>
            </div>
        </van-dialog>

        <van-dialog
            v-model="showFirst"
            width="310"
            confirm-button-color="#363737"
            :show-cancel-button="false"
            @confirm="showFirst=false"
            confirmButtonText="閉じる"
        >
            <div class="dialog-content" style="height: auto!important;">
                <div class="top">

                  <span class="close" @click.stop="showFirst = false">
                    <van-icon name="cross"/>
                  </span>
                </div>

                <div class="message" style="font-weight: normal;padding:0 15px 0 9px" >
                    <h3 style="color:#AE996A;position: absolute;top: -50px;text-align: center;width: 100%;left: -3px;">マイニング効率について</h3>
                  <p style="margin:0 0 10px;color:#363737">マイニング効率100%を満たした日は、その日のリーシング量が最大になるほか、更にプラスアルファとして<em>ブーストリワード</em>が得られます。</p>
                  <p style="margin:0 0 10px;color:#363737">効率影響残高の送付指示を行う場合は、翌日マイニング効率が最大<em>{{ punishmentPercen* 100 }}％</em>低下し、フルパワーでマイニングができなくなるため、採掘できる量に影響を及ぼします。</p>
                  <p style="color:#363737">効率影響残高の送付量を減らしたり、あるいはストレージを追加購入することで採掘量を増やしたりすることで、マイニング効率は回復されます。</p>
                </div>
            </div>
        </van-dialog>

    </div>

</template>


<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {getWithdrawInfo,sendMobileCode,withdraw} from '../api';
    import Big from 'big.js' ;
    import common from '@/common/common'
    let langTranslation = common.langTranslation;
    import Vuex from 'vuex';

    export default {

        data() {
            return {
                showFirst:true,
                isShow:false,
                radio: '1',
                active: 'fil',
                fil: 0,
                lock_fil:0,
                fil180:0,
                filOut: 0,
                money: 0,
                handlingFeeFIL: '0.0015',
                minHandlingFIL:'0.002',
                punishmentPercen:0.3,
                currentScale: 2,


                countDown:0,
                countDownMax: 60,

                form:{
                    code:'',
                    withdrawFIL: 0,
                    walletFIL:''
                },

                formError :{
                    code:'',
                    withdrawFIL: '',
                    walletFIL:''
                },
                rules: {
                    walletFIL:[
                        {
                            validator(rule, value, callback) {
                                if(!value||!value.trim()){
                                    return callback(new Error(langTranslation('请填写钱包地址')));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    withdrawFIL: [
                        {
                            validator:(rule, value, callback)=>{
                                if(!value||!value.trim()){
                                    return callback(new Error(langTranslation('请填写提现数量')));
                                }
                                if(value>this.fil){
                                    return callback(new Error(langTranslation('FIL申请提现数量不能超过当前余额')));
                                }

                                if(value<this.minHandlingFIL){
                                    return callback(new Error(langTranslation('提现数量不能少于{minHandlingFIL}FIL',{'minHandlingFIL':this.minHandlingFIL})));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    code: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机验证码')));
                                }
                                if (value.length != 4) {
                                    return callback(new Error(langTranslation('请输完整的验证码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],

                }

            }
        },
        computed: {
            ...Vuex.mapState(['userInfo']),
            ...Vuex.mapGetters(['isActiveUser']),
            allFil(){
              return Big(this.fil).add(this.lock_fil).toFixed(8)
            },
            health(){
                if(this.fil180>0 && Big(this.allFil).cmp(this.fil180)<0){
                    return Big(1).sub(this.punishmentPercen).add(Big(this.allFil).div(this.fil180).times(this.punishmentPercen)).times(100).toFixed(2)
                }
                return 100
            },
            newHealth(){
                let fil = Big(this.allFil).sub(this.form.withdrawFIL||0);
                if(fil.cmp(this.fil180)<0){
                    return Big(1).sub(this.punishmentPercen).add(fil.div(this.fil180).times(this.punishmentPercen)).times(100).toFixed(2)
                }
                return 100
            },
            newHealthShow(){
                return Big(this.newHealth).toFixed(2);
            },
            gt180(){
                if(Big(this.allFil).cmp(this.fil180)<0) return Big(0).toFixed(8);
                let left =  Big(this.allFil).sub(this.fil180).toFixed(8);
                if(Big(left).cmp(this.fil)>0) return this.fil;
                return left;
            },
            lt180(){
              var tmp= Big(this.fil).sub(this.gt180);
              if(tmp.cmp(0)<0) return Big(0).toFixed(8);
              return  tmp.toFixed(8)

            }
        },
        components: {
            vCommonTop
        },
        methods: {
            handleFocus(e){
              e.target.classList.add('input-focus')
            },
            handleBlur(e){
              e.target.classList.remove('input-focus')
            },
            changeScale(scale) {
                this.currentScale = scale;
                this.form.withdrawFIL = Big(this.fil).times(scale).div(10).round(8,0).toFixed(8)
                if(this.form.withdrawFIL <0){
                    this.form.withdrawFIL = 0;
                }
            },
            startCountDown(cur) {
                this.countDown = cur;
            },
            getMobileCode() {
                if (this.countDown > 0) return;
                sendMobileCode('withdraw_fil').then(json => {

                    if (json.data && json.data.count_down) {
                        this.countDownMax = json.data.count_down;
                    }

                    if(json.data && json.data.code){
                        this.form.code = json.data.code;
                    }

                    this.startCountDown(this.countDownMax);
                    this.$message({
                        message: langTranslation('認証コード送付成功'),
                        type: 'success'
                    });
                }, json => {

                    if (json.errorData && json.errorData.leftTime) {
                        this.startCountDown(json.errorData.leftTime);
                    }

                    this.$message(json.message);
                })
            },
            withdraw(tf) {


                this.$refs.mainForm.validate(rs => {



                    if (rs) {

                        if(!tf && this.newHealth<100){
                            this.isShow = true;
                            return ;
                        }
                        this.isShow = false;

                        if(this.withdrawing) return ;
                        this.withdrawing = true;

                        withdraw('fil',this.form).then(()=>{
                            this.$message({
                                message: langTranslation('提现申请提交成功'),
                                type: 'success'
                            });
                            setTimeout(()=>{
                                let to = '/withdraw/record/fil';
                                this.$router.push(to);
                            },1000)
                        },json=>{
                            this.withdrawing = false;
                            this.$message(json.message);
                        })
                    }
                })

            }
        },
        watch: {},
        created() {
            if(!this.isActiveUser){
                this.$router.push({path:'/ht'});
                return ;
            }
            getWithdrawInfo('fil').then(json => {
                this.fil = json.data.fil || 0;
                this.lock_fil = json.data.lock_fil || 0;
                this.fil180 = json.data.fil180 || 0;
                this.filOut = json.data.filOut || 0;
                this.money = json.data.money || 0;
                this.handlingFeeFIL = json.data.handlingFeeFIL || 0.0015;
                this.minHandlingFIL = json.data.minHandlingFIL || 0.002;
                this.form.withdrawFIL = Big(this.fil).times(this.currentScale).div(10).round(8,0).toFixed(8);
                this.punishmentPercen = json.data.punishment_percen || 0.3
            });

            let _ = () => {
                if (this.countDown > 0) {
                    this.countDown -= 1;
                }
                this.timer = setTimeout(_, 1000)
            }
            _();
        },
        template: '__template__'
    };
</script>
<style scoped lang="scss">
body {
    height: 100%;
}

.p-detail{
    height: auto!important;
    line-height: 150%!important;
}
.account-info{
    position: relative;
    height: 160px!important;
}
.health-info{
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 14px;
    color:#FFF;
    text-align: right;
    strong{
        color:#FFE5A8;
    }
}
.account-component{
    margin: 10px 0 20px 0;
}
.account-component>div{
    display: inline-block;
    width: 50%;
}
.account-component strong{
    color:#FFE5A8;
    font-size: 14px;
}
.van-dialog{
    .top{
        margin-bottom: 0;
    }
    .icon{
        color:#FDD205;
        box-shadow: 0px 0px 5px rgba(255,99,45,40);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        &::before{
            position: absolute;
            top:-4px;
            left:-4px;
        }
    }
    .message{
        color: #363737;
        margin: 16px 10px 0 0px;
        em{
            color:#FF8E8E;
        }
        font-family: PingFang SC;

    }
}


</style>
<style>
.van-dialog-rs-btns .van-dialog__footer--buttons{
    flex-direction:row-reverse;
}
</style>
