<template>
    <div>
        <div class="user-loading" v-if="loading"></div>
        <v-common-top ></v-common-top>

        <div class="limit-box">

            <el-form :model="form" :rules="rules" ref="phoneForm" v-if="step=='phone'" label-width="0">
                <div class="reg-box">
                    <h2>{{'请输入原电话号码'|langTranslation}}</h2>
                    <div>
                        <el-form-item prop="mobile" :error="formError.mobile" ref="fi_mobile">
                            <el-input class="text" v-model="form.mobile" ref="mobile" :placeholder="'输入原手机号码'|langTranslation"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <a href="javascript:;" @click.prevent.stop="next('code','phoneForm')" class="button01">{{'下一步'|langTranslation}}</a>
                    </div>
                </div>

            </el-form>
            <el-form :model="form" :rules="rules" ref="codeForm" v-if="step=='code'" label-width="0">
                <div class="reg-box">
                    <h2>{{'请输入验证码'|langTranslation}}</h2>
                    <div class="ipt-code">
                        <v-mobile-code v-model="form.code" :length="codeLength"></v-mobile-code>
                        <el-form-item prop="code" ref="fi_mobile" :error="formError.code"></el-form-item>
                    </div>

                    <div class="btn-countdown">
                        <a href="javascript:;" :class="{disabled:countDown>0}" @click="getMobileCode('change_mobile')">{{'再一次发送验证码'|langTranslation}}</a>
                        <em v-if="countDown>0">{{countDown}}</em>
                    </div>
                    <div>
                        <a href="javascript:;" @click.prevent.stop="next('new_phone','codeForm')" class="button01">{{'下一步'|langTranslation}}</a>
                    </div>
                </div>
            </el-form>
            <el-form :model="form" :rules="rules" ref="newPhoneForm" v-if="step=='new_phone'" label-width="0">
                <div class="reg-box">
                    <h2>{{'请输入新电话号码'|langTranslation}}</h2>
                    <div>
                        <el-form-item prop="new_mobile" :error="formError.new_mobile" >
                            <el-input class="text" v-model="form.new_mobile" ref="new_mobile" :placeholder="'输入新手机号码'|langTranslation"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <a href="javascript:;" @click.prevent.stop="next('new_code','newPhoneForm')" class="button01">{{'下一步'|langTranslation}}</a>
                    </div>
                </div>

            </el-form>
            <el-form :model="form" :rules="rules" ref="newCodeForm" v-if="step=='new_code'" label-width="0">
                <div class="reg-box">
                    <h2>{{'请输入验证码'|langTranslation}}</h2>
                    <div class="ipt-code">
                        <v-mobile-code v-model="form.new_code" :length="codeLength"></v-mobile-code>
                        <el-form-item prop="code" :error="formError.new_code"></el-form-item>
                    </div>

                    <div class="btn-countdown">
                        <a href="javascript:;" :class="{disabled:countDown>0}" @click="getMobileCode('change_mobile_new')">{{'再一次发送验证码'|langTranslation}}</a>
                        <em v-if="countDown>0">{{countDown}}</em>
                    </div>
                    <div>
                        <a href="javascript:;" @click.prevent.stop="change()" class="button01">{{'完了する'|langTranslation}}</a>
                    </div>
                </div>
            </el-form>
        </div>
        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {changeMobile, editPassword, sendMobileCode} from '../api';
    import common from '@/common/common'
    import vMobileCode from './block/mobileCode';
    let langTranslation = common.langTranslation;

    export default {
        data() {
            return {
                loading:false,
                step:'phone',
                form: {
                    mobile: '',
                    code: '',
                    new_mobile:'',
                    new_code:''

                },
                formError :{
                    code:'',
                    mobile:'',
                    new_mobile:'',
                    new_code:'',
                },
                rules: {
                    mobile: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机号码')));
                                }
                                if (!/^\d{5,15}$/.test(value)) {
                                    return callback(new Error(langTranslation('请输正确的手机号码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    code: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机验证码')));
                                }
                                if (value.length != 4) {
                                    return callback(new Error(langTranslation('请输完整的验证码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    new_mobile: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机号码')));
                                }
                                if (!/^\d{5,15}$/.test(value)) {
                                    return callback(new Error(langTranslation('请输正确的手机号码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    new_code: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机验证码')));
                                }
                                if (value.length != 4) {
                                    return callback(new Error(langTranslation('请输完整的验证码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                },
                countDownMax: 60,
                countDown: 0,
                codeLength: 4,

        }
        },
        components:{
            vCommonTop,
            vMobileCode
        },
        computed: {

        },
        methods:{
            next(step, form) {
                let c = () => {
                    this.step = step;
                    if (step == 'code') {
                        this.getMobileCode('change_mobile');
                    }
                    if (step == 'new_code') {
                        this.countDown = 0;
                        this.getMobileCode('change_mobile_new');
                    }
                }
                if (!form) {
                    c();
                } else {
                    this.$refs[form].validate(rs => {
                        if (rs) {
                            c();
                        }
                    })
                }


            },
            startCountDown(cur) {
                this.countDown = cur;
            },
            change(){
                if (this.loading) return;
                this.loading = true;
                changeMobile(this.form).then(() => {
                    this.$message({
                        message: langTranslation('设置成功'),
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/user/edit');
                    }, 1000)
                }).catch(()=>{

                    this.loading = false;
                    this.$message(json.message);
                });
            },
            getMobileCode(type) {
                if (this.countDown > 0) return;
                sendMobileCode(type, type=='change_mobile_new'? this.form.new_mobile: this.form.mobile).then(json => {

                    if (json.data && json.data.count_down) {
                        this.countDownMax = json.data.count_down;
                    }

                    if(json.data && json.data.code){
                        if(type=='change_mobile_new'){
                            this.form.new_code = json.data.code;
                        }else{
                            this.form.code = json.data.code;
                        }

                    }

                    this.startCountDown(this.countDownMax);
                    this.$message({
                        message: langTranslation('发送成功'),
                        type: 'success'
                    });
                }, json => {

                    if(json.code=='1011'){
                        this.next('phone')
                    }
                    if(json.code=='1046'){
                        this.next('new_phone')
                    }

                    if (json.errorData && json.errorData.leftTime) {
                        this.startCountDown(json.errorData.leftTime);
                    }

                    this.$message(json.message);
                })
            },
        },
        watch: {

        },
        created() {
            let _ = () => {
                if (this.countDown > 0) {
                    this.countDown -= 1;
                }
                this.timer = setTimeout(_, 1000)
            }
            _();

        },
        template: '__template__'
    };
</script>
