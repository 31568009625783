<template>
    <div style="height: 100%">
        <v-common-top></v-common-top>


        <div class="user-income-box wallet-box">
            <div class="uib-detail">
                <p class="title">{{'可用余额'|langTranslation}}</p>
                <div class="income">
                    <strong ><span>{{money|moneyToShow}}</span> <em>円</em></strong>
                    <!--<router-link to="/withdraw/btc"><span>出金</span></router-link>-->
                </div>
                <p v-if="moneyOut" class="desc">{{moneyOut|moneyToShow}} 円 {{'提取现金'|langTranslation}}</p>
            </div>
        </div>

        <div class="wallet-nav">
            <ul>
                <!--<li>
                    <a href="">銀行カード</a>
                    <span>アンバウンド</span>
                </li>-->
                <li>
                    <router-link to="/withdraw/btc">{{'虚拟资产钱包'|langTranslation}}</router-link>
                </li>
                <li>
                    <router-link to="/withdraw/record/btc">{{'提现记录'|langTranslation}}</router-link>
                </li>
                <!--<li>
                    <a href="">取引記録</a>
                </li>-->
            </ul>
        </div>

       <!-- <div class="wallet-bank-list">
            <ul>
                <li>
                    <a href="" class="info">
                        <span>三井住友銀行</span>
                        <span>**** **** **** 7944</span>
                    </a>
                    <a href="" class="del"></a>
                </li>
                <li>
                    <a href="" class="info">
                        <span>三井住友銀行</span>
                        <span>**** **** **** 7944</span>
                    </a>
                    <a href="" class="del"></a>
                </li>
            </ul>
        </div>

        <div class="wallet-bank-add">
            <a href="">
                <i class="icon-add-g"></i>
                <strong>銀行カードを追加する</strong>
            </a>
        </div>-->
    </div>

</template>
<style type="text/css">
    body{
        min-height: 100%;
    }
</style>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import Vuex from 'vuex';
    import {getMyBalance} from '../api';

    export default {
        data() {
            return {
                money:0,
                moneyOut:0
            }
        },
        components:{
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['userInfo'])
        },
        methods:{

        },
        watch: {

        },
        created() {
            getMyBalance('money').then(json=>{
                this.money = json.data.money || 0;
                this.moneyOut = json.data.moneyOut || 0;
            })
        },
        template: '__template__'
    };
</script>