<template>
    <div class="vcode">
        <input type="tel" maxlength='6' ref='code' class='code' id='code'
               @focus='focus=true'
               v-model='code'
               @blur='focus=false'
               :disabled='disabled'
        >
        <div class="labels">
            <label class='label' for="code"
                   v-for='(item,index) in length'
                   :class='{active: focus===true && index===currentIndex}'
                   v-text='arrCode[index]'
                   :key="index"
            >
            </label>
        </div>
    </div>
</template>

<style scoped>
    @keyframes animate {
        100% {
            opacity: 0;
        }
    }

    .vcode {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .code {
        width: 100%;
        padding: 0;
        height: 40px;
        font-size: 35px;
        overflow: hidden;
        border: none;
        outline: none;
        opacity: 0;
        margin-left: -100%;
        -webkit-tap-highlight-color: transparent;
    }

    .labels {
        display: flex;
        display: -webkit-flex;
        width: 100%;
        height: 40px;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        margin-top: -40px;
        -webkit-tap-highlight-color: transparent;
    }

    .label {
        height: 34px;
        width: 20%;
        border-bottom: solid 2px #313131;
        float: left;
        color: #313131;
        font-size: 35px;
        text-align: center;
        padding-bottom: 4px;
    }

    .active:after {
        content: ' ';
        display: inline-block;
        height: 100%;
        width: 2px;
        background: #313131;
        animation: 1.2s animate infinite;
    }

</style>

<script type="text/javascript">
    export default {
        data() {
            return {

                code: this.value,
                focus: false,

                disabled: false
            }
        },
        props: {
            value:String,
            length:{
              type:Number,
                default:6
            }
        },
        computed: {
            arrCode: function () {
                return this.code.split('');
            },
            currentIndex: function () {
                return this.code.length;
            }
        },
        watch: {
            value(n){
                this.code = n;
            },
            code: function (newV) {
                this.code = newV.replace(/[^\d]/g, ''); // 限制非数字

                this.$emit('input',this.code);
            }
        },
        template: '__template__'
    }
</script>