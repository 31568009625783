<template>
    <div class="page-my">
        <v-common-top></v-common-top>


        <div class="user-box">
            <div class="ub-face-box">
                <img :src="userInfo.face" alt="">
            </div>
            <div class="ub-user-name">
                <p>{{'こんにちは'|langTranslation}}</p>
                <p class="ubun-edit" @click.stop="handleEdit">
                    <span>{{userInfo.user_name}}</span>
                    <a href="javascript:;"><i class="icon-font-edit"></i></a>
                </p>
            </div>
            <div class="ub-nav">
                <ul>
                    <li>
                        <router-link to="/user/passwd">
                            <span>{{'密码'|langTranslation}}<br/>{{'变更'|langTranslation}}</span>
                            <i class="icon-font-password"></i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/user/phone">
                            <span>{{'手机号码'|langTranslation}}<br/>{{'变更'|langTranslation}}</span>
                            <i class="icon-font-phone"></i>
                        </router-link>
                    </li>

                    <li v-if="currency!='FIL'">
                        <router-link to="/user/address">
                            <span>{{'收货地址'|langTranslation}}<br/>{{'管理'|langTranslation}}</span>
                            <i class="icon-font-location"></i>
                        </router-link>
                        <!--<a href="">
                            <span>アドレス<br/>管理</span>
                            <i class="icon-font-location"></i>
                        </a>-->
                    </li>
                    <li v-else>
                        <router-link to="/user/address?business=1">
                            <span>{{'契約名義の'|langTranslation}}<br/>{{'管理'|langTranslation}}</span>
                            <i class="iconicon-editor iconfont"></i>
                        </router-link>
                    </li>
                    <li  v-if="currency!='FIL'">
                        <router-link to="/user/address?business=1">
                            <span>{{'契約名義の'|langTranslation}}<br/>{{'管理'|langTranslation}}</span>
                            <i class="iconicon-editor iconfont"></i>
                        </router-link>
                    </li>
                    <li  v-if="currency!='FIL'">
                        <router-link to="/user/bankcard">
                            <span>{{'口座'|langTranslation}}<br/>{{'管理'|langTranslation}}</span>
                            <i class="iconicon-bank-card iconfont"></i>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="ub-bottom">
                <a href="javascript:;" @click="logout" class="button01">
                    {{'登出'|langTranslation}}
                </a>
            </div>

        </div>

    </div>

</template>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import Vuex from 'vuex';
    import {logout} from "@/page/api";


    export default {
        data() {
            return {
                loading:true
            }
        },
        components:{
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['userInfo','noBTC','currency'])
        },
        methods: {
            handleEdit() {
                this.$router.push({name: 'username'})
            },
            logout() {
                logout().then(res => {
                    if (res.state) {
                        location.reload();
                        //this.$router.push('/');
                    }
                })

            }
        },
        watch: {

        },
        created() {
            setTimeout(()=>{
                this.loading=false;
            },2000);
        },
        template: '__template__'
    };
</script>
<style lang="scss">
    .page-my{

        .ub-nav ul{
            display: block!important;
            overflow: hidden;
            min-height: calc(100vh - 314px);
        }
        .ub-nav li{
            width: calc(100% / 2 - 5px);
            float: left;
            margin-bottom: 10px;
            margin-right: 10px;
            &:nth-child(even){
                margin-right: 0;
            }
            &:last-child a{
                margin-left: 0;
            }
            a{
                width: 100%;
                padding-top: 100%;
            }
            span.empty{
                position: relative;
                border-radius: 10px;
                display: block;
                width: 95%;
                padding-top: 95%;
            }
        }
    }
    .icon-font-phone{
        background-image: url('../../images/icon_phone.svg')!important;
        background-size: 24px 24px !important;
        background-position: center center!important;
        background-repeat: no-repeat!important;
        &:before{
            content: " ";
            width: 24px;
            height: 22px;
            display: inline-block;
        }
    }

</style>
