<template>
  <div class="income-DatePicker">
    <v-common-top :hasShadow="true"></v-common-top>
    <ul class="date-picker">
      <li>
        <span class="title">
          {{'開始期間'|langTranslation}}
        </span>
        <el-date-picker
          v-model="date.start"
          type="date"
          :placeholder="'选择日期'|langTranslation">
        </el-date-picker>
      </li>
      <li>
        <span class="title">
          {{'終了期間'|langTranslation}}
        </span>
        <el-date-picker
          v-model="date.end"
          type="date"
          :placeholder="'选择日期'|langTranslation">
        </el-date-picker>
      </li>
    </ul>
    <div class="income-type"  v-if="!down">
      <div class="income-type-title">
        {{'収益種別'|langTranslation}}
      </div>
      <div class="income-btn" :class="{current: type == 1}" @click="type=1">
        {{'レンタル収益'|langTranslation}}
      </div>
      <div class="income-btn" :class="{current: type == 2}" @click="type=2">
        {{'リワード'|langTranslation}}
      </div>

    </div>
    <div class="footer">
      <div v-if="!down" class="btn" @click="$router.replace({name: 'income',params: {date,type}})">{{'提出する'|langTranslation}}</div>
        <div v-if="down" @click="exportExcel()" class="btn">ダウンロードする</div>

    </div>
  </div>
</template>

<script>
  import vCommonTop from '../block/common-top';
  import date from '@/common/date';
  import qs from 'qs';
  import Vuex from "vuex";
export default {
  components: {
    vCommonTop
  },
  mounted(){
    let {income_type} = this.$route.params;
    if(income_type)this.type = income_type;
    if(this.$route.name=='income_down') this.down  = 1;
  },
  data () {
    return {
      date: {
        start: '',
        end: ''
      },
        down:0,
      type: 1
    }
  },
    computed: {
        ...Vuex.mapState(['noBTC','currency']),
    },
  watch: {
    $route:{
      handler($route){
        let {income_type} = $route.params;
        if(income_type)this.type = income_type;
      },
      deep: true
    }
  },
    methods:{
        exportExcel(){
            var data = {
                type:'time',
                export:1,
                currency:this.noBTC?'money':this.currency.toLowerCase(),
                begin : date.format(this.date.start,'full_to_date'),
                end : date.format(this.date.end,'full_to_date'),
            }

            window.open('/api/1.0/web/1.0/user/income_list?'+  qs.stringify(data));
        }
    }
}

</script>


