<template>
    <div>
        <v-common-top></v-common-top>
        <div class="limit-box">

            <v-address-add :address_id="id" :business="business" @callback="saved"></v-address-add>


        </div>

        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">

    import vCommonTop from './block/common-top';
    import vAddressAdd from './block/addressAdd';

    export default {
        props: ['id'],
        data() {
            return {
                business:location.href.indexOf('business=1')>=0
            }
        },

        components: {
            vCommonTop,
            vAddressAdd
        },
        computed: {

        },
        filters:{

        },
        watch: {},
        methods: {
            saved(e){
                setTimeout(()=>{
                    this.$router.push({  path: '/user/address'+(e.business?'?business=1':'')})
                },1000)
            }
        },

        created() {
            if(!this.$store.getters.isLogin){
                return this.goLogin();
            }


        },
        template: '__template__'
    };
</script>