<template>
    <div>
        <v-common-top :hasShadow="true" :income_type="$route.params.type"></v-common-top>
        <div class="limit-box">
            <div class="user-nav-tab" v-if="!$route.params.date">
                <a v-if="!noBTC" href="javascript:;" @click="changeType('time')" :class="{current:type=='time'}">{{currency=='FIL'?'日次収益':'日出租收益'|langTranslation}}</a>
                <a v-if="!noBTC" href="javascript:;" @click="changeType('month')" :class="{current:type=='month'}">{{currency=='FIL'?'月次収益':'月出租收益'|langTranslation}}</a>
            </div>
            <div class="user-data-sum" v-if="$route.params.date">
                <div class="income-type">
                    {{$route.params.type == 1 ? 'レンタル収益': 'リワード' }}
                </div>
                <div class="date" v-if="$route.params.date">
                    {{$route.params.date.start | dateFormat('full_to_date2')}}
                    -
                    {{$route.params.date.end | dateFormat('full_to_date2')}}
                </div>
                <p>
                    {{'選択期間内合計収益'|langTranslation}}
                </p>
                <template v-if="noBTC">
                    <p>
                        <strong>
                            {{sum|moneyToShow(2)}} <em>円</em>
                        </strong>
                    </p>
                </template>
                <template v-else-if="currency=='FIL'">
                    <p>
                        <strong>
                            {{(sum || sum1) | toFixed(8)}} <em>FIL</em>
                        </strong>
                    </p>
                </template>
                <template v-else>
                    <p>
                        <strong>
                            {{sum_money|moneyToShow(2)}} <em>円</em>
                        </strong>
                    </p>
                    <p style="margin-top: -10px;">
                        ({{sum|toFixed(8)}} <em>BTC</em>)
                    </p>
                </template>

            </div>
            <div class="user-data-sum" v-else>
                <p>
                    {{(type=='time'?'昨日の収益':'今月の収益')|langTranslation}}
                    <el-popover
                        v-if="currency=='FIL'"
                        placement="bottom"
                        :title="'マイニング収益に関して'"
                        width="220"
                        trigger="click"
                        content="">
                        <span class="el-icon-warning" slot="reference"></span>
                        <div class="pop-text">
                            昨日の収益に関しましては、翌日午後3時に反映されます。
                        </div>

                    </el-popover>
                </p>
                <template v-if="noBTC">
                    <p>
                        <strong>
                            {{sum|moneyToShow(2)}} <em>円</em>
                        </strong>
                    </p>
                </template>
                <template v-else-if="currency=='FIL'">
                    <p>
                        <strong>
                            {{(sum||sum1)|toFixed(8)}} <em>FIL</em>
                        </strong>
                    </p>
                </template>
                <template v-else>
                    <p>
                        <strong>
                            {{sum_money|moneyToShow(2)}} <em>円</em>
                        </strong>
                    </p>
                    <p style="margin-top: -10px;">
                        ({{sum|toFixed(8)}} <em>BTC</em>)
                    </p>
                </template>

                <p style="margin-top: -10px;" v-if="type == 'time'">
                    {{currency=='FIL'?'採掘量について':'レンタル収益について'}}
                    <el-popover
                      placement="bottom"
                      :title="currency=='FIL'?'採掘量について':'レンタル収益について'"
                      width="220"
                      trigger="click"
                      content="">
                        <span class="el-icon-warning" slot="reference"></span>
                        <div class="pop-text" v-if="currency=='FIL'">
                            採掘量は、毎日採掘されたFILから25％の管理費を差し引きいた数量となります。
                        </div>
                        <div class="pop-text" v-else>
                            レンタル料は日本円に基づいてビットコインで支払われます。 <br/><br/>
                            なお、換算基準については主要な取引所 であるbitFlyerの過去30日間におけるビッ トコインのレートに基づいての平均価格になります。
                        </div>
                    </el-popover>
                </p>
            </div>

            <!--<div class="user-data-sum">
                <p>
                    2018.10.22-2018.12.02総所得
                </p>
                <p>
                    <strong>
                        0.1240292 <em>BTC</em>
                    </strong>
                </p>
            </div>-->


            <div class="user-data-list" style="position: relative">
                <router-link :to="{name: 'income_down', params: {income_type:$route.params.type}}" style="position: absolute;right: 0;top: 30px;">
                    <div class="percolator" style="font-size: 14px">
                        EXCELダウンロード
                    </div>
                </router-link>

                <ul>

                    <template v-for="(it,index) in list">
                        <template v-if="!$route.params.date">
                            <li class="date-txt" v-if="type=='time' && (index==0 || it.income_time.substr(0,7)!=list[index-1].income_time.substr(0,7))">
                                {{it.income_time.substr(0,7).replace('-','年')}}月
                            </li>
                            <li class="date-txt" v-if="type=='month' && (index==0 || it.income_month.substr(0,4)!=list[index-1].income_month.substr(0,4))">
                                {{it.income_month.substr(0,4)}}年
                            </li>
                        </template>

                        <li :key="it.id" @click="currency=='FIL'?goOrder(it.order_code):goLease(it.order_code)">
                            <span style="line-height: 20px; width: 180px;">
                                {{it.income_time||it.income_month}} <br/>
                                {{it.pro_name}}
                            </span>
                            <span v-if="currency=='FIL' && type=='time'" style="line-height: 20px;padding: 4px 0px; text-align: center;flex: auto;min-width: 80px;" >
                                {{it.type==1||it.type==2?'SRリワード':'マイニング'}}<br/>
                                {{(it.all_calc||it.calc_count)|toFixed(-1)}}{{currency=='FIL'?'T':'台'|langTranslation}}
                            </span>
                            <span v-else style="padding: 4px 0px; flex: auto;text-align: center;min-width: 40px">
                                {{(it.all_calc||it.calc_count)|toFixed(-1)}}{{currency=='FIL'?'T':'台'|langTranslation}}
                            </span>
                            
                            <span style="line-height: 20px;padding: 4px 0; text-align: right;min-width:130px;">
                                <template v-if="noBTC">
                                    {{it.money|moneyToShow(2)}} 円
                                </template>
                                <template v-else-if="currency=='FIL'">
                                        <em style="color:#606f6d">凍結 {{it.show_lock_fil}} FIL
                                        <template v-if="it.show_initial_fil>0">
                                           <br/>即時 {{it.show_initial_fil|toFixed(8)}} FIL
                                        </template>
                                            </em>
                                </template>
                                 <template v-else>
                                    {{it.income|moneyToShow(2)}} 円<br/>
                                    ({{it.btc|toFixed(8)}} BTC)

                                </template>
                            </span>
                        </li>

                        <li :key="it.id+'_'" @click="goOrder(it.order_code)" v-if="currency=='FIL' && it.type==0 && it.show_lock_random_fil>0 || it.show_random_initial_fil>0" >
                              <span style="line-height: 20px; width: 180px;">
                                {{it.income_time||it.income_month}} <br/>
                                {{it.pro_name}}
                            </span>
                            
                            <span v-if="currency=='FIL' && type=='time'" style="line-height: 20px;padding: 4px 0px; text-align: center;flex: auto;min-width: 80px;" >
                                ブーストリワード<br/>
                                {{(it.all_calc||it.calc_count)|toFixed(-1)}}{{currency=='FIL'?'T':'台'|langTranslation}}
                            </span>

                            <span style="line-height: 20px;padding: 4px 0; text-align: right;min-width:130px;">
                                   <em style="color:#606f6d">凍結 {{it.show_lock_random_fil}} FIL
                                        <template v-if="it.show_random_initial_fil>0">
                                           <br/>即時 {{it.show_random_initial_fil|toFixed(8)}} FIL
                                        </template>
                                    </em>
                            </span>
                        </li>
                    </template>

                    <li class="loading" v-if="this.lastLoad">
                        <span>{{'读取中'|langTranslation}}</span>
                    </li>
                  <!--  <li>
                        <span>2018.1</span>
                        <span>20セット</span>
                        <span>0.1240292 BTC</span>
                    </li>
                    <li class="subtitle">
                        <strong>2017</strong>
                    </li>
                    <li>
                        <span>2017.12</span>
                        <span>20セット</span>
                        <span>0.1240292 BTC</span>
                    </li>
                    <li>
                        <span>2017.11</span>
                        <span>20セット</span>
                        <span>0.1240292 BTC</span>
                    </li>
                    <li>
                        <span>2018.12.12 23:32:30</span>
                        <span class="red">-0.1240292 BTC</span>
                    </li>

                    <li>
                        <span>2018.12.12 23:32:30</span>
                        <span class="green">0.1240292 BTC</span>
                    </li>
                    <li>
                        <span>2018.12.12 23:32:30</span>
                        <span class="green">4,134,000円</span>
                    </li>

                    <li>
                        <span>2018.12.12 23:32:30</span>
                        <span class="red">4,134,000円</span>
                    </li>-->
                </ul>
            </div>

        </div>

        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import Big from 'big.js';
    import date from '@/common/date';
    import vCommonTop from './block/common-top';
    import {getIncomeList} from '../api';
    import Vuex from "vuex";

    export default {

        data() {
            return {
                list:[],
                type:!this.noBTC && window.CURRENCY!='FIL'?'month':'time',
                start:0,
                sum:0,
                sum1:0,
                sum_money:0,
                lastLoad:null
            }
        },
        components: {
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['noBTC','currency']),
        },
        filters:{
            dateFormat: date.format,
            getLockFil(fil,it){
                if(it.initial_fil>0){
                    return Big(fil).sub(it.initial_fil).toFixed(8)
                }
                return Big(fil).toFixed(8);
            }
        },
        watch: {},
        methods: {
            goLease(order_code){
                this.$router.push({path: '/lease/' + order_code});
            },
            goOrder(order_code){
                this.$router.push({path: '/order_detail/' + order_code});
            },
            loadIncome(reset){
                if((this.lastLoad||this.listEnd) && !reset){
                    return ;
                }
                let start = reset?0:this.start;
                let opt = {
                    type:this.type,
                    start:start,
                    limit:40,
                    sum:start==0?1:0,
                    currency:this.noBTC?'money':this.currency.toLowerCase()

                };

                if(this.$route.params.date){
                    opt.begin = date.format(this.$route.params.date.start,'full_to_date');
                    opt.end = date.format(this.$route.params.date.end,'full_to_date');
                    opt.type = 'time';
                }

                if(reset){
                    this.listEnd = false;
                    this.list = [];
                }
                this.lastLoad = {...opt,reset};
                getIncomeList(opt).then(json=>{
                    if(JSON.stringify(this.lastLoad) != JSON.stringify({...opt,reset})){
                        //不是最后一次请求
                        return false;
                    }
                    this.lastLoad = false;

                    let list = [];
                    json.data.list.forEach(it=>{
                        if(this.currency=='FIL'){
                            it.show_lock_fil = Big(it.fil).sub(it.initial_fil).toFixed(8);
                            it.show_initial_fil = it.initial_fil;

                            if(it.type==0 && Big(it.random_fil).cmp(0)>0){

                                let bl = Big(it.random_fil).div(it.fil);
                                it.show_lock_random_fil = bl.times(it.show_lock_fil).toFixed(8);
                                it.show_random_initial_fil = Big(it.random_fil).sub(it.show_lock_random_fil).toFixed(8);
                                it.show_lock_fil = Big(it.show_lock_fil).sub(it.show_lock_random_fil).toFixed(8);
                                it.show_initial_fil = Big(it.initial_fil).sub(it.show_random_initial_fil).toFixed(8)

                            }else{
                                it.show_lock_random_fil = 0;
                                it.show_random_initial_fil = 0
                            }
                        }

                        list.push(it);
                    })

                    if(reset){
                        this.list=json.data.list;
                    }else{
                        this.list=this.list.concat(json.data.list);
                    }
                    if(start==0){
                        this.sum = json.data.sum[0]
                        this.sum1 = json.data.sum1[0]
                        this.sum_money = json.data.sum[1]
                    }
                    if(!json.data.list.length){
                        this.listEnd = true;
                    }
                    this.start = this.list.length;
                })
            },
            changeType(t){
                this.type = t;
                this.loadIncome(true);
            },
            scrollTable: function () {
                let visibleHeight = document.documentElement.clientHeight
                let scrollHeight = document.documentElement.scrollHeight
                let scrollTop = document.documentElement.scrollTop
                if (scrollHeight - (scrollTop + visibleHeight) <= 100) {
                    this.loadIncome()
                }
            }
        },
        beforeDestroy(){
            window.removeEventListener('scroll',this.scrollTable,true)
        },
        created() {
            if(!this.$store.getters.isLogin){
                return this.goLogin();
            }

            this.loadIncome();
            window.addEventListener('scroll', this.scrollTable, true)



        },
        template: '__template__'
    };
</script>
