<template>
  <div>
    <v-common-top ></v-common-top>

    <div class="limit-box">

        <div class="user-income-box user-income-box-min uib-gray">
            <div class="uib-detail" style="left: 20px">
                <div class="title">送付可能残高</div>

                <strong>{{fil|toFixed(8)}} FIL</strong>
                <div style="font-size: 14px; color:#fff;margin-top: 25px; line-height: 150%">
                    <span style="display: inline-block; width: 50%">
                        効率影響残高
                        <br/>
                        <em>{{lt180|toFixed(8)}} FIL</em>
                    </span>
                    <span style="display: inline-block">
                        効率影響外残高<br/>
                        <em>{{gt180|toFixed(8)}} FIL</em>
                    </span>
                </div>
            </div>
            <div class="uib-right-nav">
                <span  @click="$router.push({  path:'/withdraw/record/fil'})" class="iconclock iconfont" style="font-size: 24px; font-weight: 600;"></span>
            </div>
        </div>

        <div class="user-income-box user-income-box-min uib-white" style="height: 190px">
            <div class="uib-detail" style="left: 20px">
                <div class="title">マイニング残高</div>

                <strong>{{allMine|toFixed(8)}} FIL</strong>
                <div style="font-size: 14px; margin-top: 25px; line-height: 150%">
                    <span style="display: inline-block; width: 50%">
                        使用可能残高
                        <br/>
                        <em>{{mine_fil|toFixed(8)}} FIL</em>
                    </span>
                    <span style="display: inline-block">
                        担保中残高<br/>
                        <em>{{pledge|toFixed(8)}} FIL</em>
                    </span>
                </div>
                <div class="ubid-buttons">
                    <router-link :to="'/mine/recharge'">FILを支払う</router-link>
                    <router-link :to="'/mine/transfer'">送付する</router-link>
                </div>
            </div>
            
         
            
            <div class="uib-right-nav">
                <span  @click="$router.push({  path:'/mine/history'})" class="iconclock iconfont" style="font-size: 24px; font-weight: 600;"></span>
            </div>
        </div>
 
    </div>

    <v-copyright></v-copyright>
  </div>

</template>

<script type="text/javascript">
  import Big from 'big.js' ;
  import vCommonTop from './block/common-top';
  import {loadUserInfo, getYesterdayIncome, getWithdrawInfo, getMineInfo} from '../api';
  import Vuex from 'vuex';

  export default {
    data() {
      return {
          fil:0,
          lock_fil:0,
          fil180:0,
          filOut:0,
          mine_fil:0,
          pledge:0,
      }
    },
    components: {
      vCommonTop
    },
    computed: {
      ...Vuex.mapState(['realTimeBTCPriceJP','noBTC','currency']),
        allFil(){
            return Big(this.fil).add(this.lock_fil).toFixed(8)
        },
        gt180(){
            if(Big(this.allFil).cmp(this.fil180)<0) return Big(0).toFixed(8);
            let left =  Big(this.allFil).sub(this.fil180).toFixed(8);
            if(Big(left).cmp(this.fil)>0) return this.fil;
            return left;
        },
        lt180(){
            return Big(this.fil).sub(this.gt180).toFixed(8)
        },
        allMine(){
            return Big(this.mine_fil).add(this.pledge).toFixed(8)
        }
    },
    methods: {},
    watch: {},
    created() {
        getWithdrawInfo('fil').then(json => {
            this.fil = json.data.fil || 0;
            this.lock_fil = json.data.lock_fil || 0;
            this.fil180 = json.data.fil180 || 0;
            this.filOut = json.data.filOut || 0;
        
        });
        getMineInfo().then(json=>{
            this.mine_fil = json.data.mine_fil || 0;
            this.pledge = json.data.pledge || 0;
            
        })
   
    },
    template: '__template__'
  };
</script>

<style type="text/css" scoped>

  .user-income-box-min{
      height: 150px;
      padding: 0;
  }
</style>
