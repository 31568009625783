<template>
    <div>
        <v-header></v-header>
        <div class="limit-box">

            <el-form :model="form" :rules="rules" ref="phoneForm" v-if="step=='phone'" label-width="0">
                <div class="reg-box">
                    <h2>{{'请输入电话号码'|langTranslation}}</h2>
                    <div>
                        <el-form-item prop="mobile" :error="formError.mobile" ref="fi_mobile">
                            <el-input class="text" v-model="form.mobile" ref="mobile" :placeholder="'输入手机号码'|langTranslation"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <a href="javascript:;" @click.prevent.stop="next('code','phoneForm')" class="button01">{{'下一步'|langTranslation}}</a>
                    </div>
                </div>

                <div class="reg-bottom">
                    {{'现有有的账户'|langTranslation}}  <router-link to="/login">{{'登录'|langTranslation}}</router-link>
                </div>

            </el-form>
            <el-form :model="form" :rules="rules" ref="codeForm" v-if="step=='code'" label-width="0">
                <div class="reg-box">
                    <h2>{{'请输入验证码'|langTranslation}}</h2>
                    <div class="ipt-code">
                        <v-mobile-code v-model="form.code" :length="codeLength"></v-mobile-code>
                        <el-form-item prop="code" ref="fi_mobile" :error="formError.code"></el-form-item>
                    </div>

                    <div class="btn-countdown">
                        <a href="javascript:;" :class="{disabled:countDown>0}" @click="getMobileCode">{{'再一次发送验证码'|langTranslation}}</a>
                        <em v-if="countDown>0">{{countDown}}</em>
                    </div>
                    <div>
                        <a href="javascript:;" @click.prevent.stop="next('password','codeForm')" class="button01">{{'下一步'|langTranslation}}</a>
                    </div>
                </div>
            </el-form>

            <el-form :model="form" :rules="rules" ref="passwordForm" v-if="step=='password'" label-width="0">
                <div class="reg-box">
                    <h2>{{'设置密码'|langTranslation}}</h2>

                    <el-form-item prop="passwd" ref="fi_passwd">
                        <el-input class="text" type="password" v-model="form.passwd" ref="passwd"
                                  :placeholder="'密码'|langTranslation"></el-input>
                    </el-form-item>

                    <el-form-item prop="passwd2" ref="fi_passwd2">
                        <el-input class="text" type="password" v-model="form.passwd2" ref="passwd2"
                                  :placeholder="'请重新输入密码'|langTranslation"></el-input>
                    </el-form-item>

                    <div>
                        <a href="javascript:;" @click.prevent.stop="next('name','passwordForm')" class="button01">{{'下一步'|langTranslation}}</a>
                    </div>
                </div>

            </el-form>
            <el-form :model="form" :rules="rules" ref="nameForm" v-if="step=='name'" label-width="0">
                <div class="reg-box">
                    <h2>{{'设置用户名'|langTranslation}}</h2>
                    <el-form-item prop="user_name" ref="fi_user_name">
                        <el-input class="text" v-model="form.user_name" ref="user_name"
                                  :placeholder="'输入用户名'|langTranslation"></el-input>
                    </el-form-item>
                    <hr>
                    <el-form-item prop="invite_code" ref="fi_invite_code">
                        <el-input class="text" v-model="form.invite_code" ref="invite_code"
                                  :placeholder="'アクセスコード'+(mustInviteId?'':'（オプション）')" :disabled="hasShareKey"></el-input>
                    </el-form-item>
                    <div>
                        <label class="bm-checkbox"><input id="js_aggression" v-model="aggression" type="checkbox"><i></i> <span >{{'已经确认服务条款'|langTranslation}}</span></label>
                    </div>
                    <div>
                        <a href="javascript:;" @click="register" class="button01">{{'注册'|langTranslation}}</a>
                    </div>
                </div>

            </el-form>
        </div>

        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import vHeader from './block/header';
    import vMobileCode from './block/mobileCode';
    import {sendMobileCode,register} from '../api';
    import Vuex from 'vuex';
    import common from '@/common/common'
    let langTranslation = common.langTranslation;

    export default {
        data() {
            return {
                step: 'phone',
                mustInviteId:window.MUST_INVITE_ID,
                form: {
                    mobile: '',
                    user_name: '',
                    invite_code: window.SHARE_KEY||'',
                    code: '',
                    passwd: '',
                    passwd2: '',
                },
                formError :{
                    code:'',
                    mobile:''
                },
                hasShareKey:!!window.SHARE_KEY,
                aggression:true,
                countDownMax: 60,
                countDown: 0,
                codeLength: 4,
                rules: {
                    mobile: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机号码')));
                                }
                                if (!/^\d{5,15}$/.test(value)) {
                                    return callback(new Error(langTranslation('请输正确的手机号码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    code: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机验证码')));
                                }
                                if (value.length != 4) {
                                    return callback(new Error(langTranslation('请输完整的验证码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    passwd: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入密码')));
                                }
                                if (value.length < 6) {
                                    return callback(new Error(langTranslation('密码不得少于6位')));
                                }
                                if (value.length > 18) {
                                    return callback(new Error(langTranslation('密码不得大于18位')));
                                }
                                if (this.passwd2 !== '') {
                                    this.$refs.passwordForm.validateField('passwd2');
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    passwd2: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入确认密码')));
                                }
                                if (value != this.form.passwd) {
                                    return callback(new Error(langTranslation('两次输入的密码不相同')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    invite_code: [
                        {
                            validator: (rule, value, callback) => {
                                if(this.mustInviteId && !value ){
                                    return callback(new Error(langTranslation('必须填写邀请码')));
                                }
                                if (value && !/^[a-z0-9]+$/i.test(value)) {
                                    return callback(new Error(langTranslation('邀请码格式错误')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    user_name: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入用户名')));
                                }
                                if (!/^([^\x00-\xff]|[a-z0-9_])+$/i.test(value)) {
                                    return callback(new Error(langTranslation('用户名只允许包含a-z、0-9、_、双字节字符')));
                                }
                                if (value.length < 2) {
                                    return callback(new Error(langTranslation('用户名至少包含2个字符')));
                                }
                                if (value.length > 20) {
                                    return callback(new Error(langTranslation('用户名最多包含20个字符')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },
        components: {
            vHeader,
            vMobileCode
        },
        computed: {},
        methods: {
            ...Vuex.mapMutations(['setUserId']),
            next(step, form) {

                this.$refs[form].validate(rs => {
                    if (rs) {
                        this.step = step;
                        if(step=='code'){
                            this.getMobileCode();
                        }
                    }
                })

            },
            startCountDown(cur) {
                this.countDown = cur;
            },
            getMobileCode() {
                if (this.countDown > 0) return;
                sendMobileCode('register', this.form.mobile).then(json => {

                    if (json.data && json.data.count_down) {
                        this.countDownMax = json.data.count_down;
                    }

                    if(json.data && json.data.code){
                        this.form.code = json.data.code;
                    }

                    this.startCountDown(this.countDownMax);
                    this.$message({
                        message: langTranslation('发送成功'),
                        type: 'success'
                    });
                }, json => {

                    if (json.errorData && json.errorData.leftTime) {
                        this.startCountDown(json.errorData.leftTime);
                    }

                    this.$message(json.message);
                })
            },
            register(){
                this.$refs.nameForm.validate(rs => {
                    if(!this.aggression){
                        this.$message(langTranslation('承诺后请打勾确认'));
                        return;
                    }

                    if (rs) {
                        let form = {...this.form};
                        form.passwd = common.encodePassword(form.passwd,null,window.LOGINKEY)

                        delete form.passwd2;

                        register(form).then(json=>{
                            this.$message({
                                message: langTranslation('注册成功'),
                                type: 'success'
                            });
                            this.setUserId(json.data.user_id);
                            setTimeout( ()=>{
                                let to = '/';
                                if(this.$route.query.redirect){
                                    to = this.$route.query.redirect;
                                }
                                location.href = to;
                                //this.$router.push(to);
                            },1000)
                        },json=>{

                            if(json.code==1033 || json.code==1032 || json.code==1031 || json.code==1042){ //验证码错误
                                this.step = 'code';
                                this.formError.code =json.message;

                            }
                            if(json.code==1046){
                                this.step = 'phone';
                                this.formError.mobile =json.message;
                            }

                            this.$message(json.message);
                        });

                    }
                })

            }
        },
        watch: {},
        created() {
            let _ = () => {
                if (this.countDown > 0) {
                    this.countDown -= 1;
                }
                this.timer = setTimeout(_, 1000)
            }
            _();

        },
        template: '__template__'
    };
</script>