<template>
    <div>
        <v-common-top :class="{'ct-white':mode=='lease'}"></v-common-top>

        <div class="limit-box">

            <div v-if="mode=='view'">
                <div v-if="articles" class="p-detail">
                    <strong style="font-size:18px;">{{articles.lease_service.art_title}}</strong>
                    <div v-html="articles.lease_service.art_detail">

                    </div>
                </div>

                <div class="expirations">
                    <h3 v-if="expirations">{{'选择出租期限' | langTranslation}}</h3>
                    <div class="ps-tab-sel" v-if="expirations">
                        <template v-for="(item,index) in expirations">
                            <a :key="index"
                               v-if="item.income && item.income !=='0'"
                               :class="{'current':index==expirationIndex}"
                               @click.stop="expirationIndex=index;"
                               href="javascript:;">
                                {{item.expiration}}{{product.pro_type>100?'年':'日' | langTranslation}}
                            </a>
                        </template>

                    </div>
                    <div class="pay-status">


                        <div class="ps-bottom">
                            <router-link v-if="!order_code" to="/machine/paid" class="button01">
                                {{'选择出租的设备' | langTranslation}}
                            </router-link>
                            <a v-if="order_code && order" @click="mode='lease';" class="button01">
                                {{'下一步' | langTranslation}}
                            </a>
                        </div>

                    </div>

                </div>
            </div>

            <div class="pd-contract" v-if="product && mode=='lease'">
                <div class="pdc-desc">{{(canLease ? 'レンタルサービス確認画面' : '订单详情') | langTranslation}}
                </div>
                <div class="pdc-box">
                    <div class="pdc-top">
                        <div class="pdct-img">
                            <img :src="product.pro_pic" alt="" v-if="product.pro_pic">
                        </div>
                        <div class="pdct-info">
                            <span class="pdct-num">
                                {{'订单号' | langTranslation}} {{product.pro_code}}
                            </span>
                            <h2 class="pdct-title">
                                {{product.pro_name}}
                            </h2>
                            <span class="pdct-num">
                                {{'购买矿机金额' | langTranslation}}
                            </span>
                            <div class="buy-info">
                            <span class="bi-price">
                                <strong>{{order.order_amount}}円</strong>
                                <s style="margin-left: 10px" v-if="productOrgPrice!=0">{{productOrgPrice | moneyToShow}} 円</s>
                                <em v-if="order.pay_amount && order.pay_amount!=0 && order.pay_type==2"
                                    style="display: block;">=({{order.pay_amount}}BTC）</em>
                                <!--<em v-if="!noBTC" style="display: block;">≒({{order.order_amount | priceToBtc(realTimeBTCPriceJP)}}BTC）</em>-->
                            </span>
                            </div>
                        </div>
                    </div>
                    <div class="pdc-detail">
                        <ul>

                            <li>
                                <span>
                                    {{'出租数量' | langTranslation}}
                                </span>
                                <em>
                                    {{calcCount}} {{'台' | langTranslation}}
                                </em>
                            </li>
                            <li class="sublist">
                                <div>
                                    <span>
                                        {{'出租设备列表' | langTranslation}}

                                    </span>
                                    <em>

                                    </em>
                                </div>

                                <div>
                                    <ul>
                                        <li v-for="i in Math.ceil(calcCount)" :key="i">
                                            <span>{{i}}</span><em>{{product.pro_type==101?'F':'T'}}{{order.order_code}}-{{i}}</em>
                                        </li>


                                    </ul>
                                </div>
                            </li>
                            <li>
                                <span>
                                    {{'出租期限' | langTranslation}}
                                </span>
                                <em>
                                    {{expiration.expiration}} {{product.pro_type>100?'年':'日' | langTranslation}}
                                </em>
                            </li>
                            <li>
                                <span>
                                    {{'出租开始时间' | langTranslation}}
                                </span>
                                <em>
                                    {{startTime | dateFormat}}
                                </em>
                            </li>
                            <li>
                                <span>
                                    {{'出租结束时间' | langTranslation}}
                                </span>
                                <em>
                                    {{endTime | dateFormat}}
                                </em>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--<div class="pdc-box">
                  <div class="pdc-set">
                    <div class="head">
                      <h3 class="title">{{'请设置矿机布置比例' | langTranslation}}</h3>
                      <div class="refresh">
                        <van-icon name="replay" class="icon"/>
                        换一批
                      </div>
                    </div>

                    <ul>
                      <li>
                        <div class="name">矿场aaaaa</div>
                        <div class="content">
                          <van-slider data-key="a"
                                      bar-height="4"
                                      :value="pdc_rate.a"
                                      @input="function (val){changeSlider(val,'a')}"
                                      @change="function (val){onChange(val,'a')}">
                            <span slot="button" class="slider-btn">

                            </span>
                          </van-slider>
                          <div class="show-value">{{pdc_rate.a}}%</div>
                        </div>
                      </li>
                      <li>
                        <div class="name">矿场bbbbb</div>
                        <div class="content">
                          <van-slider data-key="b"
                                      bar-height="4"
                                      :value="pdc_rate.b"
                                      @input="function (val){changeSlider(val,'b')}"
                                      @change="function (val){onChange(val,'b')}">
                            <span slot="button" class="slider-btn">

                            </span>
                          </van-slider>
                          <div class="show-value">{{pdc_rate.b}}%</div>
                        </div>
                      </li>
                      <li>
                        <div class="name">矿场ccccc</div>
                        <div class="content">
                          <van-slider data-key="c"
                                      bar-height="4"
                                      :value="pdc_rate.c"
                                      @input="function (val){changeSlider(val,'c')}"
                                      @change="function (val){onChange(val,'c')}">
                            <span slot="button" class="slider-btn">

                            </span>
                          </van-slider>
                          <div class="show-value">{{pdc_rate.c}}%</div>
                        </div>
                      </li>
                      <li>
                        <div class="name">矿场ddddd</div>
                        <div class="content">
                          <van-slider data-key="d"
                                      bar-height="4"
                                      :value="pdc_rate.d"
                                      @input="function (val){changeSlider(val,'d')}"
                                      @change="function (val){onChange(val,'d')}">
                            <span slot="button" class="slider-btn">

                            </span>
                          </van-slider>
                          <div class="show-value">{{pdc_rate.d}}%</div>
                        </div>
                      </li>
                      <li>
                        <div class="name">矿场eeeee</div>
                        <div class="content">
                          <van-slider data-key="e"
                                      bar-height="4"
                                      :value="pdc_rate.e"
                                      @input="function (val){changeSlider(val,'e')}"
                                      @change="function (val){onChange(val,'e')}">
                            <span slot="button" class="slider-btn">

                            </span>
                          </van-slider>
                          <div class="show-value">{{pdc_rate.e}}%</div>
                        </div>
                      </li>
                      <li class="global-set">
                        <div class="name">コストパフォーマンス</div>
                        <div class="value">{{pdc_rate.global}}%</div>
                        <div class="slider-box">
                          <van-slider bar-height="4" :value="pdc_rate.global" @change="function (val){onChange(val,'global')}">
                            <span slot="button" class="global-slider-btn">

                            </span>
                          </van-slider>
                        </div>

                      </li>
                    </ul>
                  </div>
                </div>-->
                <div class="pdc-box" v-if="order.detail[0].status!=3|| order.detail[0].ext_times<2">
                    <div class="pdc-detail">
                        <h3>{{'出租预计收益' | langTranslation}}</h3>
                        <ul>
                            <li v-if="product.pro_type<100">
                                <span>
                                    {{'日次レンタル予測収益' | langTranslation}}
                                </span>
                                <em>
                                    <span>{{1 | execIncome(expiration, calcCount,noBTC,order)}}円</span>
                                    <em v-if="!noBTC">≒({{1 | execIncomeBTC(expiration, calcCount,order)}}BTC）</em>
                                </em>
                            </li>
                            <li>
                                <span>
                                    {{'单月出租预测收益' | langTranslation}}
                                </span>
                                <em>
                                    <span>{{30 | execIncome(expiration, calcCount,noBTC,order)}}円</span>
                                    <em v-if="!noBTC">≒({{30 | execIncomeBTC(expiration, calcCount,order)}}BTC）</em>
                                </em>
                            </li>
                            <li>
                                <span>
                                    {{'出租预测合计收益' | langTranslation}}
                                </span>
                                <em>
                                    <strong
                                            style="font-size: 14px">{{expiration.expiration | execIncome(expiration,
                                        calcCount,noBTC,order)}}円</strong>
                                    <em v-if="!noBTC">≒({{expiration.expiration | execIncomeBTC(expiration,
                                        calcCount,order)}}BTC）</em>
                                </em>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="pdc-box" v-if="order.detail[0].status==3">
                    <div class="pdc-detail">

                        <ul>

                            <li>
                                <span>
                                    {{'現在までのレンタル収益' | langTranslation}}
                                </span>
                                <em>
                                    <strong v-if="order.no_btc" style="font-size: 14px">{{order.income_info|moneyToShow}} 円</strong>
                                    <strong v-else style="font-size: 14px">{{order.income_info}} BTC</strong>
                                </em>
                            </li>

                        </ul>
                    </div>
                </div>


                <div class="pdc-box" v-if="articles">
                    <div class="pdcb-tab">
                        <a href="javascript:;" class="current">{{articles.lease_terms.art_title}}</a>
                        <!--<a href="javascript:;" @click="contractDetail=2" :class="{current:contractDetail==2}">{{articles.terms_use.art_title}}</a>-->
                    </div>
                    <div class="pdcb-detail">
                        <div v-html="articles.lease_terms.art_detail"
                             style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                        </div>
                    </div>

                </div>
                <div class="pdc-box" v-if="articles" style="padding-bottom: 1px">
                    <div class="pdcb-tab">
                        <a href="javascript:;" class="current">{{articles.terms_use.art_title}}</a>
                        <!--<a href="javascript:;" @click="contractDetail=2" :class="{current:contractDetail==2}">{{articles.terms_use.art_title}}</a>-->
                    </div>
                    <div class="pdcb-detail">
                        <div v-html="articles.terms_use.art_detail"
                             style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                        </div>
                    </div>
                    <!--<div class="pdcb-detail" v-if="contractDetail==2">
                        <div v-html="articles.terms_use.art_detail" style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                        </div>
                    </div>-->
                    <div v-if="canLease" class="pdcb-checkbox">
                        <label class="bm-checkbox"><input id="js_aggression" v-model="aggression"
                                                          type="checkbox"><i></i></label>
                        <label
                                for="js_aggression"> {{'确认以上内容' | langTranslation}}</label>
                    </div>
                    <div v-if="!order.contract_code && canLease" class="code-box" style="margin: 10px">
                        <input id="js_contract_code" v-model="contractCode" type="text" class="text"
                               :placeholder="'活动码'|langTranslation">
                        <label for="js_contract_code">
                            <a @click="useContractCode" href="javascript:;">{{'提交' | langTranslation}}</a>
                        </label>
                    </div>
                </div>
                <div v-if="canLease" class="pdc-bottom">
                    <a @click="leaseOrder" class="button01">{{'出租' | langTranslation}}</a>
                </div>
            </div>

            <div v-if="mode=='success'" class="pay-status">
                <div class="ps-info">
                    <i class="icon-suc"></i>
                    <p>{{'出租成功' | langTranslation}}</p>
                    <p>{{product.pro_name}}</p>
                </div>
                <div class="ps-info ps-detail">
                    <p><span>{{'出租预测合计收益' | langTranslation}}</span></p>

                    <p><strong> {{expiration.expiration | execIncome(expiration, calcCount,noBTC,order)}} 円</strong></p>
                    <p>
                        <span v-if="!noBTC">≒({{expiration.expiration | execIncomeBTC(expiration, calcCount,order)}}BTC）</span>
                    </p>


                </div>
                <div class="ps-bottom">
                    <p>{{'订单编号' | langTranslation}}：{{order.order_code}}
                        <br>
                        {{'出租期限' | langTranslation}}：   {{expiration.expiration}} {{product.pro_type>100?'年':'日' | langTranslation}}
                        <br>
                        {{'出租日期' | langTranslation}}：    {{startTime | dateFormat}}
                    </p>
                    <div>
                        <router-link to="/machine/hosting" class="button01">{{'出租中的设备' | langTranslation}}</router-link>
                    </div>
                </div>
            </div>

        </div>


        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import Big from 'big.js' ;
    import vCommonTop from './block/common-top';
    import {loadOrder, loadLeaseSysArticles, leaseOrder, useContractCode} from '../api';
    import Vuex from 'vuex';
    import common from '@/common/common'

    let langTranslation = common.langTranslation;

    export default {
        props: ['order_code'],
        data() {
            return {
                pdc_rate: {
                    a: 38,
                    b: 12,
                    c: 20,
                    d: 18,
                    e: 12,
                    global: 80
                },
                mode: '',
                articles: null,
                order: null,
                product: null,
                expirations: null,
                aggression: 0,
                expirationIndex: 0,
                contractCode: '',
                inputting: false
            }
        },
        components: {
            vCommonTop
        },
        filters: {
            execIncome(day, exp, calc, noBTC, order) {
                let annualized = exp.annualized2 || exp.annualized;
                let income = exp.income;
                if (order.detail[0].status == 3) {
                    // 已到期订单
                    if (exp.renew_annualized) {
                      annualized = exp.renew_annualized;
                    } else {
                      annualized -= 5;
                    }
                    if (exp.renew_income) {
                      income = exp.renew_income;
                    } else {
                      income = Big(income).times(annualized).div(annualized + 5).toFixed(8)
                    }
                }

                if (noBTC) {
                    let str
                    if(exp.annualized2){
                        if(day==30){
                            str = Big(annualized).times(order.order_amount).div(1200).toFixed(3);
                        }else{
                            str = Big(annualized).times(order.order_amount).div(100).times(day).toFixed(3);
                        }

                    }else{
                         str = Big(annualized).times(order.order_amount).div(36500).times(day).toFixed(3);
                    }

                    return str.substr(0,str.length-1);
                } else {
                    return Big(income).times(day).times(calc).times(window.BTCPriceJP).toFixed(2);
                }
            },
            execIncomeBTC(day, exp, calc,order) {
                let annualized = exp.annualized;
                let income = exp.income;
                if (order.detail[0].status == 3) {
                  // 已到期订单
                  if (exp.renew_annualized) {
                    annualized = exp.renew_annualized;
                  } else {
                    annualized -= 5;
                  }
                  if (exp.renew_income) {
                    income = exp.renew_income;
                  } else {
                    income = Big(income).times(annualized).div(annualized + 5).toFixed(8)
                  }
                }
                return Big(income).times(day).times(calc).round(8, 0).toFixed(8)
            }
        },
        computed: {
            ...Vuex.mapState(['realTimeBTCPriceJP', 'noBTC']),
            ...Vuex.mapGetters(['isLogin']),
            expiration() {
                return this.expirations && this.expirations[this.expirationIndex];
            },
            calcCount() {
                return Big(this.order.detail[0].pro_base_calc).times(this.order.detail[0].count).toFixed();
            },
            startTime() {
                let detail = this.order.detail[0];
                if (detail.status == 2 && detail.income_start_time) {
                    return detail.income_start_time;
                }
                let now = new Date(window["DIFFTIME"] + (+new Date));
                let next = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
                return (+next) / 1000;
            },
            endTime() {
                let detail = this.order.detail[0];
                if (detail.status == 2) {
                    return detail.income_end_time;
                }

                let start = new Date(this.startTime * 1000);
                let end
                if(this.product.pro_type>100){
                    end = new Date(start.getFullYear() , start.getMonth() + this.expiration.expiration * 12, 1);
                    let emonth = [end.getFullYear(),end.getMonth()];
                    end =  new Date(emonth[0] , emonth[1], start.getDate());
                    if(end.getFullYear()!=emonth[0]||end.getMonth()!=emonth[1]){
                        end = new Date(emonth[0] , emonth[1], 0);
                    }
                }else{
                    end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + this.expiration.expiration * 1);
                }
                return (+end) / 1000;
            },

            productOrgPrice() {
                return Big(this.product.pro_org_price).times(this.product.pro_base_calc);
            },
            canLease(){
                let detail = this.order.detail[0];
                if (detail.status == 4 || (detail.status==3 && detail.ext_times<2)) {
                    return true;
                }
                return false;

            }
        },
        watch: {},
        methods: {
            changeSlider(val, key) {
                clearTimeout(timer);
                let keys;
                let decrement_sum;
                let dir = 'increment';
                if (val - this.pdc_rate[key] < 0) dir = 'decrement';
                let limit = dir == 'increment' ? 0 : 100;
                var timer = setTimeout(() => {
                    run.call(this);
                    //误差校验
                    let count = this.getCount();
                    let diffVal = count - 100;
                    if (diffVal != 0) {
                        let index = Math.floor(Math.random() * keys.length);
                        let key = keys[index];
                        this.pdc_rate[key] -= diffVal;
                    }
                }, 50);

                function run() {
                    this.pdc_rate[key] = val;
                    //所有矿场分配总和为100%，某个矿场分配比增加，其他矿场等比减少
                    //性价比：所有矿场分配比相同时，性价比100%，越不平均性价比越低
                    //性价比公式 svg/max == 1 时，性价比是100
                    //          svg/max == 0 时，性价比是0
                    //    得： 性价比 = svg/max * num/5 * 100,svg为非0矿场的平均值，num为分配比>0的矿场数

                    keys = ['a', 'b', 'c', 'd', 'e'];
                    keys = keys.filter(keyItem => {
                        return keyItem != key;
                    });
                    //记录其他矿场分配的比值
                    let oldVal_other = {};
                    oldVal_other.sum = 0;
                    keys.forEach(key => {
                        let otherVal = +this.pdc_rate[key];
                        oldVal_other[key] = otherVal;
                        oldVal_other.sum += otherVal;
                    });
                    let newOtherSum = 100 - val;
                    //计算消减总数

                    decrement_sum = oldVal_other.sum - newOtherSum;
                    //平均减少值
                    let decrement = Math.round(decrement_sum / keys.length);
                    //根据当前操作方向，来限制其他矿场的临界值
                    //如果当前是增加操作，其他矿场比值减少，临界值为0
                    //如果当前是减少操作，其他矿场比值增加，临界值为100


                    //其他矿场根据decrement递减
                    keys.forEach((key, index) => {
                        let newval = oldVal_other[key] - decrement;
                        let reg = newval >= limit;
                        if (dir == 'decrement') reg = newval <= limit;
                        if (reg) {
                            this.pdc_rate[key] = newval;
                            decrement_sum += decrement;
                        } else {
                            this.pdc_rate[key] = 0;
                            decrement_sum += oldVal_other[key];
                        }
                        if (index == keys.length - 1 && decrement_sum > 0) {
                            setVal_end.call(this);
                        }
                        global.call(this);

                    });

                }

                //收尾工作：将没有减完的值分配给其他有占比的矿场
                function setVal_end() {
                    keys.forEach((key, index) => {
                        if (this.pdc_rate[key] - decrement_sum > 0) {
                            this.pdc_rate[key] = this.pdc_rate[key] - decrement_sum;
                            decrement_sum = 0;
                        } else if (this.pdc_rate[key] > 0) {
                            decrement_sum -= this.pdc_rate[key];
                            this.pdc_rate[key] = 0;
                        }
                    });
                };

                function global() {
                    let max = val;

                    let svg = 0;
                    let sum = 0;
                    let num = 0;
                    //找最大值，获取有分配的矿场总数
                    let keyarr = ['a', 'b', 'c', 'd', 'e'];
                    keyarr.forEach(key => {
                        let value = this.pdc_rate[key];
                        max = value > max ? value : max;
                        if (value > 0) {
                            num++;
                        }
                    });
                    //计算平均值（不包含最大值）
                    keyarr.forEach(key => {
                        let value = this.pdc_rate[key];
                        if (value < max) {
                            sum += value;
                        }
                    });
                    //只有一个最大值100，其他都为0时。
                    // 要处理逻辑使其符合参与平均值计算的矿场个数
                    if (num == 1) num++;
                    svg = sum / (num - 1);
                    let use = num / 5;
                    let global = svg / max * 50;
                    global = Math.ceil(global);
                    this.pdc_rate.global = global + 50;

                };

            },
            onChange(val, key) {
                if (val < 100 && key == 'global') {
                    val = 100;
                }
                if (key == 'global' && val == 100) {
                    let key;
                    Object.entries(this.pdc_rate).forEach(item => {
                        key = item[0];
                        if (key == 'global') return;
                        this.pdc_rate[key] = 20;
                    });
                    this.pdc_rate.global = val;
                }

            },
            useContractCode() {
                if (!this.contractCode) {
                    this.$message(langTranslation('请输入合约码'));
                    return;
                }
                useContractCode(this.order_code, this.contractCode).then(() => {
                    this.$message({
                        message: langTranslation('使用成功'),
                        type: 'success'
                    });
                    loadLeaseSysArticles(this.order_code).then(json => {
                        this.articles = json.data;
                        this.order.contract_code = this.contractCode;
                    }, () => {

                    });
                }, json => {
                    this.$message(json.message);
                })
            },
            leaseOrder() {
                if (!this.aggression) {
                    this.$message(langTranslation('承诺后请打勾确认'));
                    return;
                }
                leaseOrder(this.order_code, this.expirationIndex).then(json => {
                    this.$message({
                        message: langTranslation('申请成功'),
                        type: 'success'
                    });
                    this.mode = 'success';
                  this.order.detail[0] = json.data;
                    /*this.$router.push({
                     path: '/machine/hosting',
                     })*/
                }, json => {
                    this.$message(json.message);
                })
            },
            getCount() {
                let count = 0;
                let arr = ['a', 'b', 'c', 'd', 'e'];
                count = arr.reduce((sum, key) => {
                    return sum + this.pdc_rate[key];
                }, 0);
                return count;
            }
        },
        created() {
            loadLeaseSysArticles(this.order_code).then(json => {
                this.articles = json.data;
            }, () => {

            });

            if (this.order_code) {
                loadOrder(this.order_code, 1, 1).then(json => {
                    this.order = json.data;
                    this.product = json.data.product;
                    let other = json.data.product.other;
                    this.expirations = other.expirations_income;

                    this.mode = 'view';


                    if (json.data.detail && json.data.detail[0] && (json.data.detail[0].status < 2 || json.data.detail[0].status > 4)) {
                        this.$router.push({
                            path: '/machine/hosting',
                        });
                        return;
                    }

                    if (json.data.detail[0].status == 2 || json.data.detail[0].status == 3) {

                        this.mode = 'lease'
                    }

                    let index = -1;

                    for (let i = 0; i < this.expirations.length; i++) {
                        let ex = this.expirations[i];
                        if (ex.income && ex.income != '0') {
                            if (index == -1 || this.expirations[index].expiration < ex.expiration) {
                                index = i;
                            }
                        }
                    }

                    this.expirationIndex = index;

                })
            } else {
                this.mode = 'view';
            }
        }
        ,
        template: '__template__'
    }
    ;
</script>
