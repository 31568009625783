<template>
    <div>
        <v-common-top></v-common-top>

        <div class="limit-box">

            <div v-if="!list.length" class="address-empty">
                <h3>{{(business?'没有新的公司信息':'没有地址')|langTranslation}}</h3>
            </div>
            <div v-if="list.length" class="address-list">
                <ul>
                    <li v-for="item in list" :key="item.id">
                        <div class="info">
                            <h3>
                                {{item.name}}
                                <em>{{item.mobile}}</em>
                            </h3>
                            <p>{{item.address}}</p>
                        </div>
                        <div class="btns">
                            <router-link :to="'/user/address/edit/'+item.id">
                                <i class="icon-edit"></i>
                            </router-link>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="user-btns">
                <router-link :to="'/user/address/edit'+(business?'?business=1':'')" class="button01">
                    <i class="icon-font-add"></i>
                    <span>{{(business?'添加新的公司信息':'添加新地址')|langTranslation}}</span>
                </router-link>
            </div>

        </div>

        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {getAddressList} from '../api';

    export default {
        data() {
            return {
                list:[],
                business:location.href.indexOf('business=1')>=0
            }
        },
        components: {
            vCommonTop
        },
        computed: {

        },
        filters:{

        },
        watch: {},
        methods: {
            loadList(){
                getAddressList(this.business).then(json=>{
                    this.list=json.data||[];
                })
            }
        },

        created() {
            if(!this.$store.getters.isLogin){
                return this.goLogin();
            }

            this.loadList();


        },
        template: '__template__'
    };
</script>
