<template>
    <div>
        <v-header no-logo="1"></v-header>

        <div class="limit-box">
            <div class="reg-box">
                <h2 class="logo">
                    <router-link to="/">
                        <i class="icon-logo-big"></i>
                    </router-link>
                </h2>
                <el-form :model="loginInfo" :rules="loginRules" ref="loginForm" label-width="0" @validate="validated">
                    <el-form-item prop="account" ref="fi_account" :error="loginError.account">
                        <el-input class="text" v-model="loginInfo.account" ref="account" @keydown.enter="focusPassword" :placeholder="'输入手机号码'|langTranslation">

                        </el-input>
                    </el-form-item>
                    <el-form-item prop="passwd" ref="fi_passwd">
                        <el-input type="password" class="text" v-model="loginInfo.passwd" ref="account" @keydown.enter="focusPassword" :placeholder="'输入密码'|langTranslation">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div class="rb-login-info">
                    <router-link to="/forgot">{{'忘记密码'|langTranslation}}</router-link>
                    <router-link v-if="currency!='FIL'&&site_mark!='pro2'" to="/register">{{'注册'|langTranslation}}</router-link>
                </div>
                <div>
                    <a href="javascript:;" @click="login" class="button01">{{'登录'|langTranslation}}</a>
                </div>
              <div>
                <a v-if="currency=='FIL'||site_mark=='pro2'" href="https://www.rrm.jp" class="button03">新規の方はこちら</a>
              </div>
            </div>
        </div>


        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">

    import vHeader from './block/header';
    import Vuex from 'vuex';
    import common from '@/common/common';

    let langTranslation = common.langTranslation;

    export default {
        data() {
            return {
                loginInfo:{
                    account:'',
                    passwd:''
                },
                loginRules:{
                    account: [
                        { required: true, message: langTranslation('请输入手机号码'), trigger: 'blur' },
                    ],
                    passwd: [
                        { required: true, message: langTranslation('请输入密码'), trigger: 'blur' },
                    ],
                },
                loginError:{
                    account:'',
                }

            }
        },
        components:{
            vHeader
        },
        computed: {
            ...Vuex.mapState(['currency','site_mark'])
        },
        methods:{
            ...Vuex.mapMutations(['setUserInfo']),
            logined(){
                let to = '/';
                if(this.$route.query.redirect){
                    to = this.$route.query.redirect;
                }
                setTimeout(()=>{
                    location.href= to;
                },1000);

                //this.$router.push(to);

            },
            login(){
                this.$refs.loginForm.validate(rs=>{
                    if(!rs) {
                        return ;
                    }
                    common.login(this.loginInfo.account,this.loginInfo.passwd).then(json=>{
                        if(json.state){
                            this.$message(langTranslation('登录成功'))
                            this.setUserInfo(json.data);
                            this.logined()
                        }else{
                            this.loginError.account = '';
                            this.loginError.account = json.message;
                        }
                    })

                })
            },
            validated(field,rs,msg){
                this.loginError[field]=msg;
            }
        },
        watch: {

        },
        created() {

        },
        template: '__template__'
    };
</script>
