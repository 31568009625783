<template>
    <div>
        <v-header></v-header>
        <div class="limit-box">
            <el-form :model="form" :rules="rules" ref="phoneForm" v-if="step=='phone'" label-width="0">
                <div class="reg-box">
                    <h2>{{'忘记密码'|langTranslation}}</h2>
                    <div>
                        <el-form-item prop="mobile" :error="formError.mobile" ref="fi_mobile">
                            <el-input class="text" v-model="form.mobile" ref="mobile" :placeholder="'请输入电话号码'|langTranslation"></el-input>
                        </el-form-item>
                    </div>
                    <div class="code-box">
                        <el-form-item prop="code" :error="formError.code" ref="fi_mobile">
                            <el-input class="text" v-model="form.code" ref="code" :placeholder="'请输入验证码'|langTranslation"></el-input>
                        </el-form-item>
                        <label @click="getMobileCode">
                            {{countDown>0?countDown+'s':'获取验证码'|langTranslation}}
                        </label>
                    </div>
                    <div>
                        <a href="javascript:;" @click="next('password','phoneForm')" class="button01">{{'下一步'|langTranslation}}</a>
                    </div>
                </div>
            </el-form>
            <el-form :model="form" :rules="rules" ref="passwordForm" v-if="step=='password'" label-width="0">
                <div class="reg-box">
                    <h2>{{'设置密码'|langTranslation}}</h2>

                    <el-form-item prop="passwd" ref="fi_passwd">
                        <el-input class="text" type="password" v-model="form.passwd" ref="passwd"
                                  :placeholder="'请输入新的密码'|langTranslation"></el-input>
                    </el-form-item>

                    <el-form-item prop="passwd2" ref="fi_passwd2">
                        <el-input class="text" type="password" v-model="form.passwd2" ref="passwd2"
                                  :placeholder="'请重新输入密码'|langTranslation"></el-input>
                    </el-form-item>

                    <div>
                        <a href="javascript:;" @click.prevent.stop="forgot()" class="button01">{{'完成'|langTranslation}}</a>
                    </div>
                </div>

            </el-form>
        </div>
        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import vHeader from './block/header';
    import common from '@/common/common'
    let langTranslation = common.langTranslation;
    import {sendMobileCode,resetPassword} from '../api';

    export default {
        data() {
            return {
                step: 'phone',
                countDown:0,
                countDownMax: 60,
                form: {
                    mobile: '',
                    user_name: '',
                    invite_code: window.SHARE_KEY||'',
                    code: '',
                    passwd: '',
                    passwd2: '',
                },
                formError :{
                    code:'',
                    mobile:''
                },
                rules: {
                    mobile: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机号码')));
                                }
                                if (!/^\d{5,15}$/.test(value)) {
                                    return callback(new Error(langTranslation('请输正确的手机号码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    code: [
                        {
                            validator(rule, value, callback) {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入手机验证码')));
                                }
                                if (value.length != 4) {
                                    return callback(new Error(langTranslation('请输完整的验证码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    passwd: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入密码')));
                                }
                                if (value.length < 6) {
                                    return callback(new Error(langTranslation('密码不得少于6位')));
                                }
                                if (value.length > 18) {
                                    return callback(new Error(langTranslation('密码不得大于18位')));
                                }
                                if (this.passwd2 !== '') {
                                    this.$refs.passwordForm.validateField('passwd2');
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                    passwd2: [
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入确认密码')));
                                }
                                if (value != this.form.passwd) {
                                    return callback(new Error(langTranslation('两次输入的密码不相同')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ],
                }
            }
        },
        components: {
            vHeader
        },
        computed: {},
        methods: {
            next(step, form) {

                this.$refs[form].validate(rs => {
                    if (rs) {
                        this.step = step;
                    }
                })

            },
            forgot(){
                this.$refs.passwordForm.validate(rs => {
                    if (rs) {
                        let form = {...this.form};
                        form.passwd = common.encodePassword(form.passwd,null,window.LOGINKEY)

                        delete form.passwd2;

                        resetPassword(form).then(()=>{
                            this.$message({
                                message: langTranslation('密码修改成功'),
                                type: 'success'
                            });

                            setTimeout( ()=>{
                                let to = '/login';
                                if(this.$route.query.redirect){
                                    to = this.$route.query.redirect;
                                }
                                this.$router.push(to);
                            },1000)
                        },json=>{

                            if(json.code==1033 || json.code==1032 || json.code==1031 || json.code==1042){ //验证码错误
                                this.step = 'phone';
                                this.formError.code =json.message;

                            }
                            if(json.code==1046){
                                this.step = 'phone';
                                this.formError.mobile =json.message;
                            }

                            this.$message(json.message);
                        });

                    }
                })
            },
            startCountDown(cur) {
                this.countDown = cur;
            },
            getMobileCode() {
                if (this.countDown > 0) return;
                sendMobileCode('forgot', this.form.mobile).then(json => {

                    if (json.data && json.data.count_down) {
                        this.countDownMax = json.data.count_down;
                    }

                    if(json.data && json.data.code){
                        this.form.code = json.data.code;
                    }

                    this.startCountDown(this.countDownMax);
                    this.$message({
                        message: langTranslation('发送成功'),
                        type: 'success'
                    });
                }, json => {

                    if (json.errorData && json.errorData.leftTime) {
                        this.startCountDown(json.errorData.leftTime);
                    }

                    this.$message(json.message);
                })
            },
        },
        watch: {},
        created() {
            let _ = () => {
                if (this.countDown > 0) {
                    this.countDown -= 1;
                }
                this.timer = setTimeout(_, 1000)
            }
            _();
        },
        template: '__template__'
    };
</script>