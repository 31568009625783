<template>
    <div class="copyright">
        ©{{title}} All rights reserved.
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title:window.SITE_TITLE
            }
        },
        components:{

        },
        computed: {

        },
        methods:{

        },
        watch: {

        },
        created() {

        },
        template: '__template__'
    }
</script>