<template>
    <div>
        <div class="user-loading" v-if="loading"></div>
        <v-common-top ></v-common-top>

        <div class="limit-box">

            <div class="reg-box">
                <h2>{{'更改密码'|langTranslation}}</h2>
                <div class="rb-org-pwd">
                    <input type="password" v-model="form.org_passwd" class="text" :placeholder="'原密码'|langTranslation">
                </div>
                <div>
                    <input type="password" v-model="form.passwd" class="text" :placeholder="'新的密码'|langTranslation">
                </div>
                <div>
                    <input type="password" v-model="form.passwd2" class="text" :placeholder="'重新输入新的密码'|langTranslation">
                </div>
                <div>
                    <a href="javascript:;" @click="setPassword" class="button01">{{'发送'|langTranslation}}</a>
                </div>
            </div>
        </div>
        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import {editPassword} from '../api';
    import common from '@/common/common'
    let langTranslation = common.langTranslation;

    export default {
        data() {
            return {
                form : {
                    org_passwd: '',
                    passwd: '',
                    passwd2: ''
                }

        }
        },
        components:{
            vCommonTop
        },
        computed: {

        },
        methods:{
            setPassword(){
                let form = {
                    ...this.form
                }

                if(!form.org_passwd){
                    this.$message(langTranslation('请输入原密码'));
                    return ;
                }

                if(!form.passwd){
                    this.$message(langTranslation('请输入新密码'));
                    return ;
                }

                if(!form.passwd2){
                    this.$message(langTranslation('请输入确认密码'));
                    return ;
                }

                if(form.passwd!=form.passwd2){
                    this.$message(langTranslation('两次输入的密码不一致'));
                    return ;
                }

                form.time = +new Date;
                form.org_passwd = common.encodePassword(form.org_passwd,form.time,window.LOGINKEY);


                form.passwd =  common.encodePassword(form.passwd,null,window.LOGINKEY);
                delete form.passwd2;

                if (this.loading) return;
                this.loading = true;
                editPassword(form).then(() => {
                    this.$message({
                        message: langTranslation('设置成功'),
                        type: 'success'
                    });
                    setTimeout(()=>{
                        this.$router.push('/user/edit');
                    },1000)


                }, json => {

                    this.loading = false;
                    this.$message(json.message);
                });

            },
        },
        watch: {

        },
        created() {

        },
        template: '__template__'
    };
</script>
