<template>
  <div>
    <v-common-top></v-common-top>

    <div v-if="mode=='view'" class="limit-box">

      <template v-if="orderDetail.status==3">
        <div class="bhpa-logo-box">
          <i class="icon-bhpa-logo"></i>
          <p>買取先 BHPA,INC.</p>
        </div>
        <div class="pd-contract">
          <div class="pdc-box" v-if="article">
            <div class="pdcb-tab">
              <a href="javascript:;">{{article.art_title}}</a>
            </div>
            <div class="pdcb-detail" v-html="article.art_detail">

            </div>
            <div class="pdcb-checkbox">
              <label class="bm-checkbox"><input id="js_aggression" v-model="argument" type="checkbox"><i></i></label><label for="js_aggression"> {{'确认以上内容' | langTranslation}}</label>
            </div>
          </div>
          <div class="pdc-box">
            <div class="pdcb-detail pdcb-location" @click="showBankList">
              <i class="icon-font-bank-add"></i>
              <div v-if="bankcardInfo">
                <strong>{{bankcardInfo.bank_name}}</strong>
                <em>{{bankcardInfo.card_no}}</em>
              </div>
              <div v-else>
                <strong>{{'请输选择银行账户'|langTranslation}}</strong>
              </div>
              <div v-if="bankcardInfo">{{bankcardInfo.sub_name}}</div>
              <i class="icon-font-right-arrow"></i>


            </div>
          </div>
          <div class="pdc-bottom"><a @click="redemption" class="button01">買取査定を申請する</a></div>
        </div>
      </template>
      <template v-else>
        <div class="pay-status">
          <div class="ps-info">

            <i class="icon-font-Transfer icon-circle"></i>

            <p>{{'回购申请成功' | langTranslation}}</p>
          </div>
          <div class="ps-info ps-detail">
            <p><span>{{order.order_title}}</span></p>
            <p>
              <strong v-if="orderDetail.redemption_money">{{orderDetail.redemption_money|moneyToShow}} 円</strong>

              <strong style="font-size: 16px" v-else>{{'回购金额审核中'|langTranslation}}</strong></p>

          </div>
          <div class="ps-info"  v-if="orderDetail.withdraw">
            <p><strong style="font-size: 16px">{{'銀行振込口座情報' | langTranslation}}</strong></p>

            <p><em>{{'银行名' | langTranslation}}：{{orderDetail.withdraw.collection_info.bank_name}}</em></p>
            <p><em>{{'本·支店名' | langTranslation}}：{{orderDetail.withdraw.collection_info.sub_name}}</em></p>
            <p><em>{{'口座種別' | langTranslation}}：{{orderDetail.withdraw.collection_info.bank_type}}</em></p>
            <p><em>{{'口座番号' | langTranslation}}：{{orderDetail.withdraw.collection_info.card_no}}</em></p>
            <p><em>{{'口座名義' | langTranslation}}：{{orderDetail.withdraw.collection_info.name}}</em></p>

          </div>
          <div class="ps-bottom">
            <p>{{'回购金额将在回购申请发起的次月底，回款至以上账户'|langTranslation}}</p>

            <div>
              <router-link to="/machine/hosting" class="button01">{{'回到回购页面' | langTranslation}}</router-link>
            </div>
          </div>
        </div>
      </template>




    </div>

    <div v-if="mode=='bankcard_list'">
      <div class="address-list" v-if="bankCarList.length" >
        <ul>
          <li v-for="(item,index) in bankCarList" :key="index" @click="selectBankCard(index)">
            <div class="info">
              <h3>
                {{item.bank_name}}
                <em>{{item.card_no}}</em>
              </h3>
              <p>{{item.sub_name}}</p>
            </div>
            <div class="btns">
              <i v-if="bankCardIndex==index" class="icon-font-agree"></i>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="!bankCarList.length" class="bankcard-empty">
        <h3>{{('没有银行账户信息')|langTranslation}}</h3>
      </div>


      <div class="user-btns">
        <a href="javascript:;" @click="mode='bankcard_add'" class="button01">
          <i class="icon-font-add"></i>
          <span>{{'添加银行账户信息'|langTranslation}}</span>
        </a>
      </div>

    </div>

    <v-bankcard-add v-if="mode=='bankcard_add'" @saved="saveBankCarded" :showDel="true"></v-bankcard-add>

    <v-copyright></v-copyright>
  </div>

</template>

<script type="text/javascript">
  import Big from 'big.js' ;
  import vCommonTop from './block/common-top';
  import vBankcardAdd from './block/bankcardAdd';
  import {loadOrder, loadRedemptionArticle, getBankCardList, redemption} from '../api';
  import Vuex from 'vuex';
  import common from '@/common/common'
  let langTranslation = common.langTranslation;

  export default {
    props: ['order_code'],
    data() {
      return {
        mode:'view',
        article: null,
        order: null,
        orderDetail:null,
        product: null,
        expirations: null,
        argument:false,
        bankcardInfo:null,
        bankCardIndex:0,
        bankCarList : [],
      }
    },
    components: {
      vCommonTop,
      vBankcardAdd
    },
    filters: {

    },
    computed: {
      ...Vuex.mapState(['realTimeBTCPriceJP','noBTC']),
      ...Vuex.mapGetters(['isLogin']),

    },
    watch: {},
    methods: {
      selectBankCard(index){
        this.bankcardInfo = this.bankCarList[index];
        this.bankCardIndex = index;
        this.mode='view'
      },
      showBankList(){
        this.mode='bankcard_list'
      },
      saveBankCarded(data){
        this.bankCarList.unshift(data);
        this.selectBankCard(0)
      },
      reloadOrder(){
        loadOrder(this.order_code, 1, 1,0,1).then(json => {
          this.order = json.data;
          this.orderDetail = this.order.detail[0] || {};
          this.product = json.data.product;
          let other = json.data.product.other;
          this.expirations = other.expirations_income;
        })

      },
      redemption(){
        if(!this.argument){
          this.$message(langTranslation('承诺后请打勾确认'));
          return;
        }

        if(!this.bankcardInfo){
          this.$message(langTranslation('请填写银行卡信息'));
          return;
        }
        redemption(this.order_code,this.bankcardInfo).then(()=>{
          this.$message({
            message: langTranslation('回购申请成功'),
            type: 'success'
          });
          this.reloadOrder()
        },json=>{
          this.$message(json.message);
        });

      }
    },
    created()
    {
      loadRedemptionArticle().then(json => {
        this.article = json.data;
      }, () => {

      });

      this.reloadOrder();

      getBankCardList().then(json => {
        this.bankCarList = json.data && json.data.length ? json.data :  [];
        if (this.bankCarList.length) {
          this.bankcardInfo = this.bankCarList[0]
        }
      })

    }
    ,
    template: '__template__'
  }
  ;
</script>
<style type="text/css" scoped>
  .bhpa-logo-box{
    text-align: center;
    font-size: 20px;
    margin: 20px 0;
  }

  .icon-bhpa-logo{
    display: inline-block;
    background: url('../../images/bhpa_logo.svg');
    background-size: 130px 100px;
    width: 130px;
    height: 100px;
  }
  .pd-contract{
    background: none;
    margin-top: 10px !important;
  }
  .pdcb-detail{
    max-height: 180px;
    overflow: auto;
  }
  .pd-contract .pdc-box .pdcb-location{
    background: none;
  }
  .pdcb-location .icon-font-bank-add{
    position: absolute;
    left: 18px;
    font-size: 24px;
    top: 32px;
  }
</style>
