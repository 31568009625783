<template>
    <div class="scroll-box">
        <v-common-top :class="{'ct-white':mode=='buy'||mode=='order'}" :backEvent="true" @back="back"></v-common-top>

        <div class="limit-box">

            <div class="product" v-if="product && mode=='view'">
                <div class="p-img" style="min-height: 120px">
                    <img :src="product.pro_pic" alt="" v-if="product.pro_pic && currency!='FIL'">
                </div>
                <div class="p-top-info">
                    <div class="pti-main">
                        <h2 class="pi-title">
                            {{product.pro_name}}
                        </h2>
                        <div class="pi-package">
                            {{product.pro_base_calc|toFixed}}
                            <template v-if="product.pro_type==2">
                              {{'台'|langTranslation}}/{{'套'|langTranslation}}
                            </template>
                            <template v-else>
                              TB/{{'套'|langTranslation}}
                            </template>
                        </div>

                        <!--<span class="pi-desc">BTC予測発掘量 {{expectIncome}}BTC/日</span>-->
                        <!--<div class="pi-info">
                            <div>
                                <span>累計販売量 {{product.pro_sales_volume}} 台</span>
                            </div>

                        </div>-->
                        <div class="buy-info">

                            <span class="bi-price">
                                <strong>{{productPrice|moneyToShow}} <small>円</small> </strong>
                                <em>/{{'套'|langTranslation}}
                                       <el-tooltip v-if="product.pro_type==0" class="item" effect="light" placement="right-start">
                                           <div slot="content">
                                               <p style="max-width: 128px">
                                                   {{'结算使用法币等值的比特币数量'|langTranslation}}

                                               </p>
                                           </div>
                                          <i class="icon-font-question-mark"></i>
                                        </el-tooltip>
                                </em>
                                <s style="margin-left: 10px" v-if="productOrgPrice!=0">{{productOrgPrice|moneyToShow}} 円/KIT</s>
                            </span>

                        </div>
                        <div class="pi-info" v-if="!noBTC">
                            <div>
                                <span>≒ ( {{this.productPrice|priceToBtc(realTimeBTCPriceJP)}} BTC ）</span>
                            </div>

                        </div>
                    </div>

                    <div class="pdc-detail">
                        <ul>
                            <li>
                                <span>
                                    {{'购买数量'|langTranslation}}
                                    <em v-if="product.pro_type==0">（{{'库存数'|langTranslation}}{{product.pro_inventory}} {{'台'|langTranslation}}）</em>
                                </span>
                                <em v-if="!product.pro_do_php" class="ipt-num">
                                    <a href="javascript:;" @click="changeCount(-1)">－</a>
                                    <input type="number" v-model="count" :disabled="isUpgrade">
                                    <a href="javascript:;" @click="changeCount(1)">＋</a>
                                </em>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="p-detail">
                    <div v-if="product.pro_type>0 && product.pro_type<100" style="line-height: 150%">
                        <h3>製品説明</h3>
                        <div style="overflow:hidden">
                            <span>サービス期間 {{expiration}}日{{product.id>=36 && product.id <= 40 ?'(更新の場合＋540日)':''}}</span>
                            <span v-if="product.pro_type==2" style="float: right">シーリング効率 {{seal}}T/日</span>
                        </div>
                        <div>
                            委託管理手数料 {{fee}}
                        </div>
                        <div v-if="product.pro_type>0 &&product.income_start_day">
                            入金確認後{{product.income_start_day}}日後より採掘開始となります。
                        </div>
                    </div>
                    <div v-html="product.pro_detail"></div>
                </div>

                <div class="p-bottom" v-if="product.pro_type<3 || product.pro_type>100">
                    <div class="pdcb-checkbox" style="margin-bottom: 15px" v-if="product.pro_type==0">
                        <label class="bm-checkbox"><input id="js_business_checkbox" v-model="business" type="checkbox"><i></i></label> <label
                            for="js_business_checkbox"> {{'大客户购买请勾选'|langTranslation}}</label>
                    </div>
                    <a href="javascript:;" @click.stop="toBuy" class="button01">{{(product.pro_type==0?'购买设备':'購入する')|langTranslation}}</a>
                </div>
              <div class="p-bottom pb-suspension" v-else>
                <a href="javascript:;" @click="toCalc" class="button-icon"><i class="icon-calc-large"></i><span>计算器</span></a>
                <a href="javascript:;" @click.stop="toBuy" class="button01">{{(product.pro_type==0?'购买设备':'購入する')|langTranslation}}</a>
              </div>
              <div v-if="hasScroll" style="height: 48px">&nbsp;</div>
            </div>

            <div class="pd-contract" v-if="product && (mode=='buy'|| mode=='order')">
                <div class="pdc-desc">{{(mode=='buy'?'購入確認画面':'注文明細')|langTranslation}}</div>
                <template v-if="product.pro_type==0 || product.pro_type>100">
                    <div class="pdc-box">
                        <div class="pdc-top">
                            <div class="pdct-img">
                                <img :src="product.pro_pic" alt="" v-if="product.pro_pic">
                            </div>
                            <div class="pdct-info">
                            <span class="pdct-num color-grey" >
                                {{'订单号'|langTranslation}} {{product.pro_code}}
                            </span>
                                <h2 class="pdct-title">
                                    {{product.pro_name}}
                                </h2>
                                <span class="pdct-num">
                                {{'产品单价'|langTranslation}}
                            </span>
                                <div class="buy-info">
                            <span class="bi-price">
                                <strong>{{product.pro_price|moneyToShow}} 円</strong>
                                <s style="margin-left: 10px" v-if="productOrgPrice!=0">{{product.pro_org_price|moneyToShow}} 円</s>
                                  <div class="color-grey" v-if="!noBTC">
                                        ≒({{product.pro_price|priceToBtc(realTimeBTCPriceJP)}} BTC）
                                </div>
                            </span>
                                </div>
                            </div>
                        </div>
                        <div class="pdc-detail">
                            <ul>
                                <li>
                                <span>
                                    {{'购买数量'|langTranslation}}

                                </span>
                                    <em>
                                        {{count}} {{'套'|langTranslation}}
                                    </em>
                                </li>
                                <li>
                                <span>
                                    {{'设备数量'|langTranslation}}
                                </span>
                                    <em>
                                        {{calcCount}} {{'台'|langTranslation}}
                                    </em>
                                </li>

<!--                                <li class="pdcd-amount">
                                    <div>
                                        <div>
                                            <span>{{'商品总金额'|langTranslation}}</span>
                                            <strong>{{allPrice|moneyToShow}} 円</strong>
                                        </div>
                                        <div v-if="!noBTC">
                                            ≒({{allPrice|priceToBtc(realTimeBTCPriceJP)}} BTC）
                                        </div>

                                    </div>


                                </li>-->
                                <li  v-if="allSaleTax!=0">
                                    <span>{{'消費税'|langTranslation}}</span>
                                    <em>
                                        {{allSaleTax|moneyToShow}} 円
                                    </em>
                                </li>
                                <li >
                                    <span>{{'商品合計金額'|langTranslation}}</span>
                                    <em style="text-align: right;" >
                                        <strong>{{allPriceBySale|moneyToShow}} 円</strong>
                                        <div v-if="!noBTC" class="color-grey">
                                            ≒({{allPriceBySale|priceToBtc(realTimeBTCPriceJP)}} BTC)
                                        </div>
                                    </em>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="pdc-box">
                        <div class="pdcb-detail pdcb-location" :class="{'icon-font-business':business}" @click="showAddress">
                            <div v-if="!product.pro_do_php">
                                <strong>{{address.name||(business?'请输入签约名':'住所を入力してください')|langTranslation}}</strong>
                                <em>{{address.mobile}}</em>
                            </div>
                            <div v-if="!product.pro_do_php">{{address.address}}</div>
                            <i class="icon-font-right-arrow" v-if="!product.pro_do_php && mode=='buy'"></i>
                            <p v-if="product.pro_do_php">{{'此商品为体验商品，无需输入收货地址。'|langTranslation}}</p>
                        </div>
                    </div>

                    <div class="pdc-box" v-if="business && address.name">
                        <div class="pdcb-detail">
                            {{'我们会将购买协议发送到您输入的邮箱地址'|langTranslation}}。
                        </div>
                    </div>
                    <!-- <div class="pdc-box" v-if="articles && articles.sla">
                         <div class="pdcb-detail">
                             <p><strong>免責事項</strong></p>
                             <div v-html="articles.sla.art_detail" style="max-height: 80px;overflow: auto; -webkit-overflow-scrolling : touch;">

                             </div>
                         </div>
                     </div>-->

                    <div class="pdc-box" v-if="articles && !business">
                        <div class="pdcb-tab">
                            <a href="javascript:;" @click="contractDetail=1" :class="{current:contractDetail==1}">{{articles.contract.art_title}}</a>
                            <!--<a href="javascript:;" @click="contractDetail=2" :class="{current:contractDetail==2}">{{articles.terms_use.art_title}}</a>-->
                        </div>
                        <div class="pdcb-detail" v-if="contractDetail==1">
                            <div v-html="articles.contract.art_detail" style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                            </div>
                        </div>
                        <!--<div class="pdcb-detail" v-if="contractDetail==2">
                            <div v-html="articles.terms_use.art_detail" style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                            </div>
                        </div>-->
                        <div class="pdcb-checkbox" v-if="mode=='buy'">
                            <label class="bm-checkbox"><input id="js_aggression" v-model="aggression" type="checkbox"><i></i></label> <label
                            for="js_aggression"> {{'确认以上内容'|langTranslation}}</label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="pdc-box">
                        <div class="pdc-top">
                            <div class="pdct-img">
                                <img :src="product.pro_pic" alt="" v-if="product.pro_pic">
                            </div>
                            <div class="pdct-info">
                                <span class="pdct-num color-grey" >
                                    {{'订单号'|langTranslation}} {{order?order.order_code:product.pro_code}}
                                </span>
                                <h2 class="pdct-title" style="position: relative">
                                    {{product.pro_name}}
                                    <span v-if="detail && (detail.status==2||detail.status==4)" style="font-weight: normal;font-size: 12px;color: #1FA47E;letter-spacing: 0;text-align: right;float: right">
                                        <template v-if="now/1000 - order.pay_time>detail.income_start_day * 86400||detail.status==2">
                                          <template v-if="product.pro_type==1 && detail.new_order && detail.new_order.status!=0 ">
                                            更新中
                                            <em style="display: block;position: absolute;left: 0px;top: 23px;">更新完了まで{{ detail.new_order.income_start_day }}日かかります、しばらくお待ち下さい。</em>
                                          </template>
                                          <template v-else>
                                            稼働中
                                          </template>

                                        </template>
                                        <template v-else>
                                          準備中
                                          <em v-if="product.pro_type==4||product.pro_type==6" style="display: block;position: absolute;left: 0px;top: 23px;">稼働開始まで{{ detail.income_start_day }}日かかります、しばらくお待ち下さい。</em>
                                        </template>
                                    </span>
                                    <span v-if="detail && (detail.status==2||detail.status==4)" style="font-weight: normal;font-size: 12px;color: #1FA47E;letter-spacing: 0;text-align: right;float: right"></span>
                                </h2>
                                <div class="pdc-detail" v-if="!isOrderUpgrade && mode=='order' && order && order.paid && (order.sale_tax!=0 || order.discounted_amount!=0)">
                                    <ul>
                                        <li style="background-color:transparent">
                                            <span>{{'小計'|langTranslation}}</span>
                                            <em>
                                                {{allPrice|moneyToShow}} 円
                                            </em>
                                        </li>
                                        <li v-if="order.sale_tax!=0">
                                            <span>{{'消費税'|langTranslation}}</span>
                                            <em>
                                                {{order.sale_tax|moneyToShow}} 円
                                            </em>
                                        </li>
                                        <li v-if="order.discounted_amount!=0">
                                            <span>{{'クーポン'|langTranslation}}</span>
                                            <em>
                                                -{{order.discounted_amount|moneyToShow}} 円
                                            </em>
                                        </li>
                                        <li>
                                            <span>{{'商品合計金額'|langTranslation}}</span>
                                            <em style="text-align: right;" v-if="order.pay_type==1">
                                                <strong>{{order.pay_amount|moneyToShow}} 円</strong>
                                                <div class="color-grey">
                                                    ≒({{order.pay_amount|priceToBtc(realTimeBTCPriceJP)}} BTC)
                                                </div>
                                            </em>
                                            <em v-else>
                                                <strong>{{order.pay_amount|toFixed(8)}} BTC</strong>
                                            </em>
                                        </li>
                                    </ul>
                                </div>
                                <div class="pdc-detail"  v-else-if="mode=='buy' && product.pro_sale_tax!=0">
                                    <ul>
                                        <li style="background-color:transparent">
                                            <span>{{'小計'|langTranslation}}</span>
                                            <em>
                                                {{allPrice|moneyToShow}} 円
                                            </em>
                                        </li>
                                        <li>
                                            <span>{{'消費税'|langTranslation}}</span>
                                            <em>
                                                {{allSaleTax|moneyToShow}} 円
                                            </em>
                                        </li>
                                        <li>
                                            <span>{{'商品合計金額'|langTranslation}}</span>
                                            <em style="text-align: right;" >
                                                <strong>{{allPriceBySale|moneyToShow}} 円</strong>
                                                <div class="color-grey">
                                                    ≒({{allPriceBySale|priceToBtc(realTimeBTCPriceJP)}} BTC)
                                                </div>
                                            </em>

                                        </li>
                                    </ul>
                                </div>
                                <template v-else-if="isOrderUpgrade">
                                     <span class="pdct-num">
                                        {{'商品总金额'|langTranslation}}
                                    </span>
                                    <div class="buy-info">
                                    <span class="bi-price">
                                        <strong>0 円</strong>
                                        
                                    </span>
                                    </div>
                                </template>
                                <template v-else>
                                     <span class="pdct-num">
                                        {{'商品总金额'|langTranslation}}
                                    </span>
                                    <div class="buy-info">
                                    <span class="bi-price">
                                        <strong>{{allPrice|moneyToShow}} 円</strong>
                                          <div class="color-grey">
                                                ≒({{allPrice|priceToBtc(realTimeBTCPriceJP)}} BTC）
                                        </div>
                                    </span>
                                    </div>
                                </template>

                            </div>
                        </div>

                    </div>
                    <div class="pdc-box">
                        <div class="pdc-detail">
                            <div><h3 >{{'商品明細'|langTranslation}}</h3></div>
                            <ul>
                                <li style="background-color:transparent">
                                    <span>{{'产品单价'|langTranslation}}</span>
                                    <em>
                                        {{price}} 円
                                    </em>
                                </li>
                                <li>
                                    <span>
                                        {{'购买数量'|langTranslation}}
                                    </span>
                                    <em>
                                        {{count}} {{(product.pro_type==2?'台':'')|langTranslation}}
                                    </em>
                                </li>
                                <li>
                                    <span>
                                        {{'合計ストレージ'|langTranslation}}
                                    </span>
                                    <em>
                                        {{allCalcCount}} {{'T'|langTranslation}}
                                    </em>
                                </li>
                                <!--
                                                                <li class="pdcd-amount">
                                                                    <div>
                                                                        <div>
                                                                            <span>{{'商品总金额'|langTranslation}}</span>
                                            <strong>{{allPrice|moneyToShow}} 円</strong>
                                        </div>
                                        <div v-if="!noBTC">
                                            ≒({{allPrice|priceToBtc(realTimeBTCPriceJP)}} BTC）
                                        </div>

                                    </div>


                                </li>-->
                            </ul>
                        </div>
                    </div>
                    <div class="pdc-box">
                        <div class="pdc-detail">
                            <div><h3 >{{'委託管理明細'|langTranslation}}</h3></div>
                            <ul>

                                <li style="background-color:transparent" v-if="detail">
                                    <span>{{'累計獲得枚数'|langTranslation}}</span>
                                    <em>
                                        {{detail.sum_fil}} FIL
                                    </em>
                                </li>
                                <li style="background-color:transparent" v-if="detail && SR1_ALL>0">
                                    <span>
                                        <div style="display: inline-block;">
                                             {{'SR1リワード配布'|langTranslation}}
                                         <el-popover
                                             placement="right"
                                             title="SR1リワード配布"
                                             width="160"
                                             trigger="click"
                                             content=""

                                         >
                                            <span class="el-icon-warning" slot="reference"></span>
                                            <div class="pop-text">
                                                プロトコルラボのルールに基づいて、180日にかけて毎日配布されます。
                                            </div>

                                        </el-popover>
                                        </div>

                                    </span>
                                    <em>
                                        {{SR1}} / {{SR1_ALL}} FIL
                                    </em>
                                </li>


                                <li style="background-color:transparent" v-if="detail"><span>
                                    {{'シーリング量(累計)'|langTranslation}}
                                      <el-popover
                                          v-if="currency=='FIL'"
                                          placement="right"
                                          title="シーリング量について"
                                          width="200"
                                          trigger="click"
                                          content=""

                                      >
                                            <span class="el-icon-warning" slot="reference"></span>
                                            <div class="pop-text">
                                               シーリング量とはブロックリワードを採掘するための採掘作業量を表している数値であり、RRMineマイナーID全体に対する貢献度によって採掘効率及び採掘量が決められます。
                                            </div>

                                        </el-popover>
                                </span><em>{{detail.seal_calc}} T</em></li>
                                <!--<li style="background-color:transparent"  v-if="detail">
                                    <span>{{'シーリング量(前日)'|langTranslation}}

                                </span><em>0 T</em></li>-->

                                <!--<li style="background-color:transparent" v-if="product.pro_type==2">
                                    <span>{{'シーリング効率'|langTranslation}}</span>
                                    <em>
                                        {{seal}}T/24h
                                    </em>
                                </li>
                                <li style="background-color:transparent"  v-if="product.pro_type==2 && detail">
                                    <span>
                                        {{'残りストレージ'|langTranslation}}
                                    </span>
                                    <em>
                                        {{detail|leftCalc}} T
                                    </em>
                                </li>-->
                                <li style="background-color:transparent" >
                                    <span>
                                        {{'委託管理手数料'|langTranslation}}
                                    </span>
                                    <em>
                                        {{fee}}
                                    </em>
                                </li>

                                <li v-if="product.pro_type>0 && product.income_start_day && (!detail || !detail.income_start_time)">
                                    <span>
                                        {{'採掘開始日'|langTranslation}}
                                    </span>
                                    <em>
                                        入金確認後{{ product.income_start_day }}日
                                    </em>
                                </li>
                                <li>
                                    <span>
                                        {{'サービス期間'|langTranslation}}
                                    </span>
                                    <em>
                                        {{ product.pro_type>0 && (!detail || !detail.income_start_time)?'採掘開始後':'' }}{{expiration}}日
                                    </em>
                                </li>
                                <li v-if="!upgradeOrderCode && detail && detail.income_start_time"><span>{{'開始期間'|langTranslation}}</span><em>{{detail.income_start_time|dateFormat}}</em></li>
                                <li v-if="!upgradeOrderCode && detail && detail.income_end_time"><span>{{'終了期間'|langTranslation}}</span><em>{{ (isOrderUpgrade?detail.new_order.income_start_time: detail.income_end_time)|dateFormat}}</em></li>


                            </ul>
                        </div>
                    </div>


                    <template v-if="product.pro_type==1 || product.pro_type==3 || product.pro_type==4|| product.pro_type==5 || product.pro_type==6">
                        <div class="pdc-box" v-if="articles ">
                            <div class="pdcb-tab">
                                <a href="javascript:;" @click="contractDetail=1" :class="{current:contractDetail==1}">{{articles.contract.art_title}}</a>
                                <!--<a href="javascript:;" @click="contractDetail=2" :class="{current:contractDetail==2}">{{articles.terms_use.art_title}}</a>-->
                            </div>
                            <div class="pdcb-detail" v-if="contractDetail==1">
                                <div v-html="articles.contract.art_detail" style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                                </div>
                            </div>
                            <!--<div class="pdcb-detail" v-if="contractDetail==2">
                                <div v-html="articles.terms_use.art_detail" style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                                </div>
                            </div>-->
                            <!--<div class="pdcb-checkbox" v-if="mode=='buy'">
                                <label class="bm-checkbox"><input id="js_aggression" v-model="aggression" type="checkbox"><i></i></label> <label
                                for="js_aggression"> {{'确认以上内容'|langTranslation}}</label>
                        </div>-->
                        </div>
                        <div class="pdc-box" v-if="articles">
                            <div class="pdcb-tab">
                                <a href="javascript:;" @click="contractDetail=1" :class="{current:contractDetail==1}">{{articles.terms_use.art_title}}</a>

                            </div>
                            <div class="pdcb-detail" v-if="contractDetail==1">
                                <div v-html="articles.terms_use.art_detail" style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                                </div>
                            </div>
                            <!--<div class="pdcb-detail" v-if="contractDetail==2">
                                <div v-html="articles.terms_use.art_detail" style="max-height: 180px;overflow: auto; -webkit-overflow-scrolling : touch;">

                                </div>
                            </div>-->

                        </div>
                    </template>
                    <div class="pdc-box" v-if="product.pro_type==2">
                        <div class="pdcb-detail pdcb-location" :class="{'icon-font-business':business,'pdcb-location-selected':address.name}" @click="showAddress">

                            <div>
                                <strong>{{(address.name?'契約名義':'契約名義をご記入ください')|langTranslation}}</strong>
                            </div>
                            <div>{{address.name}}</div>
                            <div>{{address.charge}}</div>
                            <div>{{address.address}}</div>
                            <div>{{address.mobile}}</div>
                            <div>{{address.email}}</div>
                            <i class="icon-font-right-arrow" v-if="mode=='buy'"></i>
                        </div>
                    </div>
                </template>


                <div class="pdc-bottom" v-if="mode=='buy'">
                    <div style="margin-bottom: 10px" v-if="product.pro_type==1 || product.pro_type==3||product.pro_type==4||product.pro_type==5||product.pro_type==6">
                        <div class="pdcb-checkbox" v-if="mode=='buy'">
                            <label class="bm-checkbox"><input id="js_aggression" v-model="aggression" type="checkbox"><i></i></label> <label
                            for="js_aggression"> {{'上記規約内容をすべて確認し同意した'|langTranslation}}</label>
                        </div>
                    </div>
                    <div style="margin:5px 0 20px 10px" v-if="(product.pro_type==2) && mode=='buy'">
                        契約書はお客様が記載したメールアドレスに送信されます
                    </div>

                    <a @click="createOrder" class="button01">{{'完成'|langTranslation}}</a>
                </div>
            </div>
            <div v-if="product && mode=='address'">
                <div class="address-list" v-if="list.length" >
                    <ul>
                        <li v-for="(item,index) in list" :key="index" @click="selectAddress(index)">
                            <div class="info">
                                <h3>
                                    {{item.name}}
                                    <em>{{item.mobile}}</em>
                                </h3>
                                <p>{{item.address}}</p>
                            </div>
                            <div class="btns">
                                <i v-if="addressIndex==index" class="icon-font-agree"></i>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="!list.length" class="address-empty">
                    <h3>
                        {{(business?'没有新的公司信息':'没有地址')|langTranslation}}
                    </h3>
                </div>

                <div class="user-btns">
                    <a href="javascript:;" @click="mode='address_add'" class="button01">
                        <i class="icon-font-add"></i>
                        <span>{{(business?'添加新的公司信息':'添加新地址')|langTranslation}}</span>
                    </a>
                </div>

            </div>

            <v-address-add v-if="mode=='address_add'" @callback="addressAdded" :business="business"></v-address-add>

        </div>
        <v-copyright class="copyright-float"></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import Big from 'big.js' ;
    import vCommonTop from './block/common-top';
    import vAddressAdd from './block/addressAdd';
    import {loadProduct,loadOrder,createOrder,loadProductSysArticles,getAddressList} from '../api';
    import Vuex from 'vuex';
    import common from '@/common/common'
    let langTranslation = common.langTranslation;

    export default {
        props: ['id','order_code'],
        data() {
          let query = this.$route.query;
            return {
                product: null,
                mode: 'view', // view 查看 , buy 购买
                count: query.count || 1,
                contractDetail:1,
                aggression:false,
                aggression1:false,
                business:false,
                articles:null,
                addressIndex:0,
                list:[],
                order:null,
                detail:null,
                now:new Date(+new Date + (window.DIFF_TIME || 0)),
                hasScroll:false
            }
        },
        components: {
            vCommonTop,
            vAddressAdd
        },
        computed: {
            ...Vuex.mapState(['realTimeBTCPriceJP','noBTC','currency']),
            ...Vuex.mapGetters(['isLogin']),
            /**
             * 期待收益
             */
            expectIncome() {
                return Big(this.product.income_btc).times(this.calcCount).round(8,0).toFixed(8) ;
            },
            address(){
                return this.list[this.addressIndex]||{};
            },
            calcCount() {
                return Big(this.product.pro_base_calc).times(this.count).toFixed();
            },
            allCalcCount(){
                if(this.product.pro_type==2){
                    return Big(this.product.pro_base_calc).times(this.product.pro_hash_power).times(this.count).toFixed();
                }else{
                    return Big(this.product.pro_base_calc).times(this.count).toFixed();
                }

            },
            productPrice(){
                return Big(this.product.pro_price).times(this.product.pro_base_calc);
            },
            productOrgPrice(){
                return Big(this.product.pro_org_price).times(this.product.pro_base_calc);
            },
            allPrice(){
                return Big(this.product.pro_price).times(this.calcCount).toFixed(2);
            },
            allSaleTax(){
                return Big(this.product.pro_sale_tax||0).times(this.calcCount).toFixed(2);
            },
            allPriceBySale(){
                return Big(this.allPrice).add(this.allSaleTax).toFixed(2);
            },
            price(){
                return Big(this.product.pro_price).times(this.product.pro_base_calc).toFixed(2);
            },

            expectRate(){
                return Big(this.product.calc_expect_rate).times(100).toFixed(0);
            },
            expiration(){
                return this.product.expirations_income.expiration
            },
            fee(){
                return this.product.expirations_income.fee+'%'
            },
            upgradeOrderCode(){
                return this.$route.query.order_code;
            },
            isUpgrade() {
              return this.upgradeOrderCode && this.product && this.product.pro_type == 3;
            },
            isOrderUpgrade(){
                return this.detail && this.detail.new_order_code && this.detail.status==-2
            },

            seal(){
                let data =this.product.expirations_income
                if(!data.seal || data.seal==0){

                    let t = this.product.pro_base_calc;
                    if(this.product.pro_type==2){
                        t*=this.product.pro_hash_power;
                    }

                    return Big(t).div(data.expiration).toFixed(8)
                }
                return data.seal
            },
            SR1_ALL(){
              let end = 1600099200; // 2020-09-15
              let st = 1598284800; // 2020-08-25
              let pt = this.product.pro_type == 1 || this.product.pro_type == 3 ? 1 : 2;
              let income = pt == 1 ? Big('0.02417241') : Big('10.7100');
              let pay_time = this.order.pay_time;
              if(!this.order.pay_time) return 0;
              if (this.detail.upgrade_order) {
                pay_time = this.detail.upgrade_order.pay_time;
              }
              if (pay_time > st) {
                st = pay_time + 86400 * (pt == 1 ? 3 : 7)
              }
              let count = Math.ceil((end - st) / 86400)

              return income.times(count).times(pt== 1? this.calcCount : this.detail.count).toFixed(8)
            },
            SR1(){
                return Big(this.detail.SR1).div(0.75).toFixed(8)
            },
            
        },
        watch: {
            count(n) {
                console.log(this.product)
                /*if (n <= 0) this.count = 1;
                if (n > Math.min(this.product.pro_inventory,99) ) this.count = Math.min(this.product.pro_inventory,99);*/

            }
        },
        filters:{
            leftCalc(v){
                if(v.pro_type==2){
                    return Big(v.pro_base_calc).times(v.pro_hash_power).times(v.count).sub(v.seal_calc||0).toFixed(2);
                }else{
                    return Big(v.pro_base_calc).times(v.count).sub(v.seal_calc||0).toFixed(2);
                }
            },
        },
        methods: {
            checkScroll(){
                let sh = Math.max(document.documentElement.scrollHeight,document.body.scrollHeight);
                this.hasScroll = sh > window.innerHeight;
            },
            loadProduct() {
                if(this.id){
                    loadProduct(this.id).then(json => {

                        this.product = json.data;
                        try {
                          this.product.other = JSON.parse(this.product.other);
                          if (this.product.pro_type > 0) {
                            this.product.expirations_income = this.product.other.expirations_income.find(it => it.income && it.income != 0)
                          }
                        } catch (e) {
                        }

                        if(this.product.pro_type==2 || this.product.pro_type==101){
                            this.business = 1;
                        }
                        this.$nextTick(()=>{
                          this.checkScroll();
                        });
                    }, json => {
                        this.$message(json.message);
                    })
                    if(this.upgradeOrderCode){
                      loadOrder(this.upgradeOrderCode,1,1,0).then(json => {

                        this.order  = json.data;
                        this.detail = json.data.detail[0]

                        this.count = this.detail.count;
                        this.$nextTick(()=>{
                          this.checkScroll();
                        });
                      }, json => {
                        if(json.code==99){
                          return this.goLogin();
                        }
                        this.$message(json.message);
                      });
                    }
                }else if(this.order_code){
                    this.mode = 'order';
                    loadOrder(this.order_code,1,1,0).then(json => {
                        this.product = json.data.product;

                        try{
                            if(this.product.pro_type>0){
                                this.product.expirations_income = this.product.other.expirations_income.find(it=>it.income && it.income!=0)
                            }
                            delete json.data.product;
                        }catch(e){}

                        if(json.data.address_info){
                            this.list = [JSON.parse(json.data.address_info)];
                        }


                        this.order  = json.data;
                        this.detail = json.data.detail[0]
                        this.detail.pro_info =JSON.parse(this.detail.pro_info);
                        this.detail.pro_hash_power = this.detail.pro_info.pro_hash_power;

                        this.product.pro_price = this.detail.pro_info.pro_price;
                        this.product.pro_org_price = this.detail.pro_info.pro_org_price;
                        loadProductSysArticles(this.product.pro_type).then(json => {
                          this.articles = json.data;
                          this.$nextTick(()=>{
                            this.checkScroll();
                          });
                        }, () => {

                        });
                        this.count = this.detail.count;
                        this.$nextTick(()=>{
                          this.checkScroll();
                        });
                    }, json => {
                        if(json.code==99){
                            return this.goLogin();
                        }
                        this.$message(json.message);
                    });

                }

            },
            changeCount(add){
              if (this.isUpgrade) {
                return;
              }
              this.count = Math.max(1, Math.min(this.count + add, this.product.pro_inventory))
            },
            createOrder(){
                if (!this.aggression && !this.business && this.product.pro_type != 2) {
                    this.$message(langTranslation('承诺后请打勾确认'));
                    return;
                }

                if(this.product.pro_type==0 || this.product.pro_type==2 || this.product.pro_type==101){
                    if(!this.address.id && !this.product.pro_do_php){
                        this.$message(langTranslation(this.business?'请输入签约名':'请填写收货地址'));
                        return;
                    }
                }else{
                    this.addressIndex = -1
                }

                createOrder(this.id,this.count,this.address.id,this.upgradeOrderCode).then(json=>{
                    this.$router.push({
                        path: '/order/'+json.data.order_code
                    });
                },json=>{
                    if(json.code==99){
                        return this.goLogin();
                    }
                    this.$message(json.message);
                });

            },
            toBuy(){
                if(!this.isLogin){
                    return this.goLogin();
                }else{
                    this.mode='buy';
                    getAddressList(this.business).then(json=>{
                        this.list=json.data||[];
                    })
                    if(!this.business || this.product.pro_type>0){
                        loadProductSysArticles(this.product.pro_type).then(json => {
                            this.articles = json.data;
                        }, () => {

                        });
                    }
                    document.documentElement.scrollTop = 0
                }

            },
          toCalc(){
              let src =  '/calculator?cost='+Big(this.productPrice).times(this.count).toFixed(2);
              src+='&total_power='+this.allCalcCount
              if(this.product.pro_type>2){
                src+= '&power_per_day='+this.allCalcCount
              }else{

              }
              src+='&pid='+this.product.id + '&c='+this.count
              location.href = src;


          },
            showAddress(){

                if(this.product.pro_do_php || this.mode=='order'){
                    return ;
                }
                this.mode='address';
            },
            selectAddress(index){
                this.addressIndex = index;
                this.mode='buy';
            },
            addressAdded(){
                getAddressList(this.business).then(json=>{
                    this.list=json.data||[];
                    this.selectAddress(0);
                })
            },
            back(){
                switch(this.mode){
                    case 'address':
                        this.mode='buy';
                        break;
                    case 'address_add':
                        this.mode='buy';
                        break;
                    case 'buy':
                        this.mode='view';
                        break;
                    case 'order':
                        this.$router.replace('/machine'+(this.order.paid?'/paid':''))
                        break;
                    default:

                        this.$router.go(-1);
                        break;

                }

            }
        },
        created() {
            this.loadProduct();
            let self = this;
            window.onresize = function(){
              self.checkScroll();
            }

        },
        template: '__template__'
    };
</script>
