<template>
    <div class="page-my">
        <v-common-top></v-common-top>
        <div class="images-box" ref="imageBox">
            <div>
                <img src="@/images/ht/1.jpg">
            </div>
            <div>
                <img src="@/images/ht/2.jpg">
            </div>
            <div>
                <img src="@/images/ht/3.jpg">
            </div>
        </div>
    </div>

</template>

<script type="text/javascript">
    import vCommonTop from './block/common-top';
    import Vuex from 'vuex';
    import {logout} from "@/page/api";
    import Viewer from "viewerjs";


    export default {
        data() {
            return {
                loading:true
            }
        },
        components:{
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['userInfo','noBTC','currency'])
        },
        methods: {

        },
        watch: {

        },
        created() {

        },
        mounted() {
            let viewer = new Viewer(this.$refs.imageBox, {
                inline: false,
                toolbar: false,
                title:false,
                viewed() {
                    viewer.zoomTo(1);
                },
            });
        },
        template: '__template__'
    };
</script>
<style lang="scss">
   .images-box{
       img{
           width: 100%;
       }
   }

</style>
