import pg_index from './components/index';
import pg_user from './components/user';
import pg_ht from './components/ht';
import pg_mine_info from './components/mineInfo';
import pg_mine_recharge from './components/mineRecharge';
import pg_mine_transfer from './components/mineTransfer';
import pg_mine_history from './components/mineHistory';
import pg_login from './components/login';
import pg_forgot from './components/forgot';
import pg_register from './components/register';
import pg_product from './components/product';
import pg_article from './components/article';
import pg_machine from './components/machine';
import pg_machine_FIL from './components/machine_FIL';
import pg_order from './components/order';
import pg_my from './components/my';
import pg_wallet from './components/wallet';
import pg_withdraw from './components/withdraw';
import pg_withdrawRecord from './components/withdrawRecord';
import pg_withdrawInfo from './components/withdrawInfo';
import pg_withdrawBtc from './components/withdrawBtc';
import pg_withdrawFil from './components/withdrawFil';
import pg_income from './components/income';
import pg_income_percentage from './components/income_percentage';
import pg_income_datePicker from './components/new/IncomeDatePicker.vue';
import pg_address from './components/address';
import pg_address_add from './components/addressAdd';
import pg_userPasswd from './components/userPasswd';
import pg_userPhone from './components/userPhone';
import pg_userName from './components/new/Username.vue';
import pg_leaseService from './components/leaseService';
import pg_redemption from './components/redemption';

import pg_bankcard_admin from './components/new/BankcardAdmin.vue';
import pg_new_bankcard from './components/new/NewBankcard.vue';

export default [
  {name: 'index', path: '/', component: pg_index},
  {
    name: 'list',
    path: '/:product(product)',
    props: true,
    component: pg_index,

  },
  {
    name: 'user', path: '/user', component: pg_user, meta: {requiresAuth: true},
    children: []
  },
  {
    name: 'ht', path: '/ht', component: pg_ht, meta: {requiresAuth: true},
    children: []
  },
  {
    name: 'mine', path: '/mine', component: pg_mine_info, meta: {requiresAuth: true},
    children: []
  },
  {name: 'mineRecharge', path: '/mine/recharge', component: pg_mine_recharge, meta: {requiresAuth: true}},
  {name: 'mineTransfer', path: '/mine/transfer', component: pg_mine_transfer, meta: {requiresAuth: true}},
  {name: 'mineHistory', path: '/mine/history/:type?', component: pg_mine_history, props: true, meta: {requiresAuth: true}},
    
  {name: 'my', path: '/user/edit', component: pg_my, meta: {requiresAuth: true}},
  {name: 'address', path: '/user/address', component: pg_address, props: true, meta: {requiresAuth: true}},
  {
    name: 'address_edit',
    path: '/user/address/edit/:id(\\d+)',
    component: pg_address_add,
    props: true,
    meta: {requiresAuth: true}
  },
  {name: 'address_add', path: '/user/address/edit', component: pg_address_add, meta: {requiresAuth: true}},
  {name: 'wallet', path: '/wallet', component: pg_wallet, meta: {requiresAuth: true}},
  {name: 'withdraw', path: '/withdraw', component: pg_withdraw, meta: {requiresAuth: true}},
  {name: 'withdraw_btc', path: '/withdraw/btc', component: pg_withdrawBtc, meta: {requiresAuth: true}},
  {name: 'withdraw_fil', path: '/withdraw/fil', component: pg_withdrawFil, meta: {requiresAuth: true}},
  {
    name: 'withdraw_record',
    path: '/withdraw/record/:type(\\w+)',
    props: true,
    component: pg_withdrawRecord,
    meta: {requiresAuth: true}
  },
  {
    name: 'withdraw_info',
    path: '/withdraw/info/:id(\\d+)',
    props: true,
    component: pg_withdrawInfo,
    meta: {requiresAuth: true}
  },
  {name: 'username', path: '/user/name', component: pg_userName, meta: {requiresAuth: true}},
  {name: 'passwd', path: '/user/passwd', props: true, component: pg_userPasswd, meta: {requiresAuth: true}},
  {name: 'phone', path: '/user/phone', props: true, component: pg_userPhone, meta: {requiresAuth: true}},
  {name: 'login', path: '/login', component: pg_login},
  {name: 'forgot', path: '/forgot', component: pg_forgot},
  {name: 'register', path: '/register', component: pg_register},
  {name: 'product', path: '/detail/:id(\\d+)', props: true, component: pg_product},
  {name: 'article', path: '/article/:id(\\d+)', props: true, component: pg_article},
  {name: 'order_detail', path: '/order_detail/:order_code(\\d+)', props: true, component: pg_product},
  {name: 'machine', path: '/machine', component: window.CURRENCY=='FIL'?pg_machine_FIL:pg_machine, meta: {requiresAuth: true}},
  {
    name: 'machine_type',
    path: '/machine/:type(hosting|paid)',
    component:  window.CURRENCY=='FIL'?pg_machine_FIL:pg_machine,
    props: true,
    meta: {requiresAuth: true}
  },
  {name: 'order', path: '/order/:order_code(\\d+)', props: true, component: pg_order, meta: {requiresAuth: true}},
  {name: 'redemption_order', path: '/redemption/:order_code(\\d+)', props: true, component: pg_redemption, meta: {requiresAuth: true}},
  {
    name: 'leaseService_order',
    path: '/lease/:order_code(\\d+)',
    props: true,
    component: pg_leaseService,
    meta: {requiresAuth: true}
  },
  {name: 'leaseService', path: '/lease', component: pg_leaseService},
  {name: 'income', path: '/income', props: true, component: pg_income, meta: {requiresAuth: true}},
  {name: 'income_percentage', path: '/income/percentage', props: true, component: pg_income_percentage, meta: {requiresAuth: true}},
  {
    name: 'income_datepicker',
    path: '/income/datepicker',
    props: true,
    component: pg_income_datePicker,
    meta: {requiresAuth: true}
  },
  {
    name: 'income_down',
    path: '/income/down/:income_type',
    props: true,
    component: pg_income_datePicker,
    meta: {requiresAuth: true}
  },
  {name: 'bankcard', path: '/user/bankcard', component: pg_bankcard_admin, props: true, meta: {requiresAuth: true}},
  {
    name: 'bankcard_edit',
    path: '/user/bankcard/edit/:id(\\d+)?',
    component: pg_new_bankcard,
    props: true,
    meta: {requiresAuth: true}
  }
  /*{name: 'projects', path: '/:gid(\\d+)/projects', component: pg_project_list},
   {name: 'project', path: '/:gid(\\d+)/project/:pid(\\d+)', component: pg_project},
   {name: 'project_edit', path: '/:gid(\\d+)/project/:pid(\\d+)/edit', component: pg_project_edit},
   {name: 'project_create', path: '/:gid(\\d+)/project/edit', component: pg_project_edit},*/
  /*{name: 'rent', path: '/rent', component: pg_rent},
   {name: 'rooms', path: '/rooms', component: pg_rooms},
   {name: 'chart', path: '/chart/:key(\\w+)', component: pg_chart},*/
];
