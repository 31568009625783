<template>
  <div>
    <v-common-top></v-common-top>

    <div class="limit-box">
      <div class="p-detail">
        <strong style="font-size: 18px;">{{'提取纪录'|langTranslation}}</strong>
      </div>

      <div class="user-data-list">
        <ul>
          <li v-for="it in list" @click="showInfo(it.id)" class="twoline">
            <div>
              <span style="width: 140px; flex: none;"><i :class="{'el-icon-error':it.status==4,'el-icon-success':it.status==3}" :style="{color:it.status==4?'red':'green'}"></i> {{it.create_time|dateFormat}}</span>
              <span style="text-align: center;">{{(it.type==1?'汇出':'回购款')|langTranslation}}</span>
              <span style="width: 102px;flex: none; text-align: right;" v-if="it.withdraw_type=='2'">{{it.amount|toFixed(8)}} BTC</span>
              <span style="width: 102px;flex: none; text-align: right;" v-if="it.withdraw_type=='3'">{{it.amount|toFixed(8)}} FIL</span>
              <span style="width: 102px;flex: none; text-align: right;" v-if="it.withdraw_type=='1'">
                <template v-if="it.status==3 || it.type==1">
                  {{it.amount|moneyToShow(2)}} 円
                </template>
                <template v-else>
                  {{'回购审批中'|langTranslation}}
                </template>
              </span>
            </div>
            <div>
                <span style="float: left;margin-left: 16px;">{{ it.approval_info }}</span>
              <span>{{it.status|withdrawStatusToTextForFront}}</span>
            </div>
          </li>
          <li class="loading" v-if="lastLoad">
            <span>{{'读取中'|langTranslation}}</span>
          </li>

          <!--  <li>
                <span>2018.1</span>
                <span>20セット</span>
                <span>0.1240292 BTC</span>
            </li>
            <li class="subtitle">
                <strong>2017</strong>
            </li>
            <li>
                <span>2017.12</span>
                <span>20セット</span>
                <span>0.1240292 BTC</span>
            </li>
            <li>
                <span>2017.11</span>
                <span>20セット</span>
                <span>0.1240292 BTC</span>
            </li>
            <li>
                <span>2018.12.12 23:32:30</span>
                <span class="red">-0.1240292 BTC</span>
            </li>

            <li>
                <span>2018.12.12 23:32:30</span>
                <span class="green">0.1240292 BTC</span>
            </li>
            <li>
                <span>2018.12.12 23:32:30</span>
                <span class="green">4,134,000円</span>
            </li>

            <li>
                <span>2018.12.12 23:32:30</span>
                <span class="red">4,134,000円</span>
            </li>-->
        </ul>
        <div v-if="!list.length && !lastLoad">
          <div class="withdraw-empty">
            <h3 style="font-size:20px;">{{'没有提现记录'|langTranslation}}</h3>
          </div>

        </div>

      </div>

    </div>

    <v-copyright></v-copyright>
  </div>

</template>

<script type="text/javascript">
  import Big from 'big.js' ;

  import vCommonTop from './block/common-top';
  import {getWithdrawList} from '../api';

  export default {
    data() {
      return {
        list:[],
        start:0,
        sum:0,
        lastLoad:null
      }
    },
    props: ['type'],
    components: {
      vCommonTop
    },
    computed: {

    },
    filters:{

    },
    watch: {},
    methods: {
      loadRecord(reset){
        if((this.lastLoad||this.listEnd) && !reset){
          return ;
        }
        let start = reset?0:this.start;
        let opt = {
          /*type:this.type,*/
          start:start,
          limit:40,
        };
        if(reset){
          this.listEnd = false;
          this.list = [];
        }
        this.lastLoad = {...opt,reset};
        getWithdrawList(opt).then(json=>{
          if(JSON.stringify(this.lastLoad) != JSON.stringify({...opt,reset})){
            //不是最后一次请求
            return false;
          }
          this.lastLoad = false;
          let list = json.data.length?json.data:[];

          if(reset){

            this.list=list;
          }else{
            this.list=this.list.concat(list);
          }

          if(!json.data.length){
            this.listEnd = true;
          }
          this.start = this.list.length;
        })
      },
      changeType(t){
        this.type = t;
        this.loadRecord(true);
      },
      scrollTable: function () {
        let visibleHeight = document.documentElement.clientHeight
        let scrollHeight = document.documentElement.scrollHeight
        let scrollTop = document.documentElement.scrollTop
        if (scrollHeight - (scrollTop + visibleHeight) <= 100) {
          this.loadRecord()
        }
      },
      showInfo(id){
        let to = '/withdraw/info/' + id;
        this.$router.push(to);

      }
    },
    beforeDestroy(){
      window.removeEventListener('scroll',this.scrollTable,true)
    },
    created() {
      if(!this.$store.getters.isLogin){
        return this.goLogin();
      }

      this.loadRecord();
      window.addEventListener('scroll', this.scrollTable, true)



    },
    template: '__template__'
  };
</script>
