<template>
    <div>
        <v-common-top></v-common-top>
        <div class="limit-box">

            <div class="user-nav-tab" v-if="isHosting">
                <a href="javascript:;" @click="changeCurrent(1)" :class="{current:current==1}">{{'出租中'|langTranslation}}</a>
                <a href="javascript:;" @click="changeCurrent(2)" :class="{current:current==2}">{{'已到期'|langTranslation}}</a>
                <a v-if="site_mark!='pro2'" href="javascript:;" @click="changeCurrent(3)" :class="{current:current==3}">{{'回购'|langTranslation}}</a>
            </div>

            <div class="user-nav-tab" v-if="!isHosting">
                <a href="javascript:;" @click="changeCurrent(4)" :class="{current:current==4}">{{'支付中'|langTranslation}}</a>
                <a href="javascript:;" @click="changeCurrent(5)" :class="{current:current==5}">{{'支付成功'|langTranslation}}</a>
                <a href="javascript:;" @click="changeCurrent(6)" :class="{current:current==6}">{{'发货'|langTranslation}}</a>
            </div>



            <div v-for="(i,ind) in viewList" class="machine-item pdc-box" :class="map[i].boxClass" :key="ind">
                <div v-if="list[i] && list[i].length">
                    <h3 v-if="map[i].title" class="h-title" @click="toggleList(i)">
                        <span>{{map[i].title}}</span>
                    </h3>
                    <div v-if="map[i].showList">
                        <div  class="box" v-for="item in list[i]"  @click="showDetail(item)" :key="item.id">
                            <img :src="item.pro_info.pro_pic" alt="">
                            <div class="info">
                                <h3 class="title">{{item.pro_name}}</h3>
                                <ul v-if="!isHosting">
                                    <li><span>{{'订单号'|langTranslation}}</span><span>{{item.order_code}}</span></li>
                                    <li><span>{{'购买数量'|langTranslation}}</span><span>{{item|allCalc}} {{'台'|langTranslation}}</span></li>
                                    <li><span>{{'购买单价'|langTranslation}}</span>
                                        <span v-if="!noBTC"><strong>{{item.pro_price|priceToBtc(item.status==4?item.order.btc_price:realTimeBTCPriceJP)}}</strong> BTC</span>
                                        <span v-else><strong>{{item.pro_price|moneyToShow}} 円</strong></span></li>
                                    <li><span>{{'订单时间'|langTranslation}}</span><span>{{item.create_time|dateFormat}}</span></li>
                                    <li v-if="item.status==0 && !noBTC" ><span style="color:red;">{{'价格更新时间'|langTranslation}}</span><span style="color:red;">{{leftTime}}</span></li>
                                    <li v-if="!isHosting && item.order.pay_way=='online_btc' && item.order.pay_type==2 && item.status==4" class="pay-status-bar"><div class="bar" style="color:#0371e2">
                                        <span :style="{width:item.confirmations/2*100 + '%'}"></span>
                                    </div><div class="text">{{(item.confirmations>=2?'支払い成功':'支払確認中')|langTranslation}}</div></li>
                                </ul>
                                <ul v-if="item.status==5||item.status==6" class="box-line">
                                    <li><span>{{'物流单号'|langTranslation}}</span></li>
                                    <li v-if="item.status==5"><span>{{'备货中'|langTranslation}}</span></li>
                                    <li v-if="item.status==6"><span>{{item.express_code}}</span></li>
                                </ul>
                                <ul v-if="!isHosting && item.order.pay_type!=3 && item.status!=-3" class="box-line">
                                    <li class="sum" v-if="noBTC">
                                        <span>{{'支付金额'|langTranslation}}</span>
                                        <span><strong>{{item.order.order_amount|moneyToShow}} 円</strong></span>
                                    </li>
                                    <li class="sum" v-if="!noBTC && (item.order.pay_type==1 || item.status==0)"><span>{{'支付金额'|langTranslation}}</span><span><strong>{{item.order.order_amount|priceToBtc(realTimeBTCPriceJP)}} BTC</strong></span></li>
                                    <li class="sum" v-if="item.order.pay_type==2"><span>{{'支付金额'|langTranslation}}</span><span><strong>{{item.order.pay_amount}} BTC</strong></span></li>
                                </ul>

                                <template v-if="isHosting">
                                    <template v-if="item.status==-1||item.status==-2">
                                        <ul>
                                            <li><span>{{'订单号'|langTranslation}}</span><span>{{item.order_code}}</span></li>
                                            <li><span>{{'出租数量'|langTranslation}}</span><span>{{item|allCalc}} {{'台'|langTranslation}}</span></li>
                                            <li><span>{{'购买金额'|langTranslation}}</span><span>{{item.order.order_amount|moneyToShow}} 円</span></li>
                                        </ul>
                                        <ul class="box-line">
                                            <li><span>{{'订单时间'|langTranslation}}</span><span>{{item.create_time|dateFormat}}</span></li>
                                            <li><span>{{'出租开始时间'|langTranslation}}</span><span>{{item.income_start_time|dateFormat}}</span></li>
                                            <li><span>{{'出租结束时间'|langTranslation}}</span><span>{{item.income_end_time|dateFormat}}</span></li>

                                            <li><span>{{'出租合计收益'|langTranslation}}</span><span>{{item.pro_expiration_time|execIncome(item,noBTC)}} 円</span></li>
                                        </ul>
                                        <ul class="box-line">
                                            <li class="sum">
                                                <span>{{'回购金额'|langTranslation}}</span>
                                                <span style="display: inline-block; vertical-align: middle; vertical-align: -webkit-baseline-middle;">
                                                    <strong v-if="item.redemption_money">{{item.redemption_money|moneyToShow}} 円</strong>
                                                    <strong v-else>{{'审核中'|langTranslation}}</strong>
                                                </span>
                                            </li>
                                            <li><span>{{'回购申请时间'|langTranslation}}</span><span>{{item.redemption_time|dateFormat}}</span></li>
                                            <li v-if="item.status==-2"><span>{{'回购完成时间'|langTranslation}}</span><span>{{item.redemptioned_time|dateFormat}}</span></li>
                                        </ul>
                                    </template>
                                    <template v-else>
                                        <ul>
                                            <li><span>{{'订单号'|langTranslation}}</span><span>{{item.order_code}}</span></li>
                                            <li><span>{{'出租数量'|langTranslation}}</span><span>{{item|allCalc}} {{'台'|langTranslation}}</span></li>
                                            <li v-if="!noBTC"><span>{{'单月出租预测收益'|langTranslation}}</span><span>{{30|execIncomeBTC(item)}} BTC</span></li>
                                            <li v-if="noBTC"><span>{{'单月出租预测收益'|langTranslation}}</span><span>{{30|execIncome(item,noBTC)}} 円</span></li>
                                        </ul>
                                        <ul class="box-line">
                                            <li><span>{{'订单时间'|langTranslation}}</span><span>{{item.create_time|dateFormat}}</span></li>
                                            <li><span>{{'出租开始时间'|langTranslation}}</span><span>{{item.income_start_time|dateFormat}}</span></li>
                                            <li><span>{{'出租结束时间'|langTranslation}}</span><span>{{item.income_end_time|dateFormat}}</span></li>
                                        </ul>
                                        <ul class="box-line">
                                            <li class="sum" v-if="item.status!=3">
                                                <span>{{'出租预测合计收益'|langTranslation}}</span>
                                                <span style="display: inline-block; vertical-align: middle; vertical-align: -webkit-baseline-middle;">
                                                    <strong>{{item.pro_expiration_time|execIncome(item,noBTC)}} 円</strong>
                                                    <span style="display: block" v-if="!noBTC">≒({{item.pro_expiration_time|execIncomeBTC(item)}} BTC)</span>
                                                </span>
                                            </li>
                                            <li class="sum" v-else>
                                                <span>{{'出租合集收益'|langTranslation}}</span>
                                                <span style="display: inline-block; vertical-align: middle; vertical-align: -webkit-baseline-middle;">
                                                    <strong v-if="item.no_btc">{{item.income_info|moneyToShow}} 円</strong>
                                                    <strong v-else>{{item.income_info}} BTC</strong>
                                                </span>
                                            </li>
                                        </ul>
                                    </template>


                                </template>



                                <div v-if="item.status==0" class="pay-bottom">
                                    <a href="javascript:;" @click.stop="goto('/order/'+item.order_code)" class="button01">{{'去支付'|langTranslation}}</a>
                                    <a href="javascript:;" @click.stop="cancelOrder(item.order_code)">{{'入取消'|langTranslation}}</a>
                                </div>

                                <div v-if="item.status==-3" class="pay-bottom">
                                    <router-link :to="'/detail/'+item.pro_id" class="button01">
                                        {{'重新购买'|langTranslation}}
                                    </router-link>
                                    <!--<a href="" @click="cancelOrder">キャンセルする</a>-->
                                </div>

                                <div v-if="item.status==4" class="pay-bottom">
                                    <a  v-if="item.order.pay_way!='online_btc' || (item.confirmations && item.confirmations>=2)" href="javascript:;" @click.stop="goto('/lease/'+item.order_code)" class="button01">{{'出租设备'|langTranslation}}</a>
                                    <a  v-if="item.order.pay_way=='online_btc' && (!item.confirmations || item.confirmations<2)" @click.stop href="javascript:;" style="background: #aaa;" class="button01">{{'出租设备'|langTranslation}}</a>

                                    <a v-if="item.order.pay_type!=3" href="javascript:;" @click.stop="shipOrder(item.order_code,item.order.pay_way!='online_btc' || (item.confirmations && item.confirmations>=2))">{{'通知发货'|langTranslation}}</a>
                                </div>

                                <div v-if="item.status==3&&site_mark!='pro2'" class="pay-bottom">
                                    <a href="javascript:;" v-if="item.ext_times<2 && !item.product.pro_do_php" @click.stop="goto('/lease/'+item.order_code)" class="button01" style="margin: 0">
                                        <span>{{'出租延期'|langTranslation}}</span>
                                    </a>
                                    <a href="javascript:;" @click.stop="redemption(item.order_code,item.product.pro_do_php)" class="button02">
                                        <span>{{'申请回购核算'|langTranslation}}</span><br/>
                                        <em>powered by </em><i class="icon-font-bhp-logo"></i>
                                    </a>

                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div v-loading="loading" v-if="loading && ind==0" style="height: calc(100vh / 2); "></div>
                <div  v-if="isEmpty && ind==0" >
                    <div :class="{'machine-empty':!isHosting,'host-empty':isHosting}">
                        <h3 style="font-size:20px;" v-if="!isHosting">{{'没有购买的机器'|langTranslation}}</h3>
                        <h3 style="font-size:20px;" v-else>{{(current==2?'没有到期的设备':'没有租赁机器')|langTranslation}}</h3>
                    </div>

                    <div class="user-btns">
                        <router-link :to="!isHosting?'/product':'/lease'" class="button01">
                            <i class="icon-font-add"></i>
                            <span>{{(!isHosting?'购买设备':'设备出租介绍')|langTranslation}}</span>
                        </router-link>
                    </div>

                </div>



            </div>
        </div>
        <v-copyright></v-copyright>
    </div>

</template>

<script type="text/javascript">
    import Big from 'big.js' ;
    import vCommonTop from './block/common-top';
    import Vuex from 'vuex';
    import {getMyMachine,shipOrder,redemption,cancelOrder} from '../api';
    import common from '@/common/common'
    let langTranslation = common.langTranslation;

    export default {
        props:['type'],
        data() {
            return {
                current:this.type=='paid'?5:this.type=='hosting'?1:4,// 1 进行中    2 已到期   3 已赎回
                list:{},
                loading:true,
                map:{
                    0:{ // 待支付
                        title:langTranslation('待支付'),
                        boxClass:'machine-expire',
                        showList:1
                    },
                    1:{
                        title:langTranslation('未开始'),
                        boxClass:'machine-expire',
                        showList:1
                    },
                    2:{
                        //title:'貸出中',
                        showList:1
                    },
                    3:{
                        //title:'期間終了',
                        showList:1
                    },
                    4:{ //已支付
                        //title:'支払完了',
                        showList:1
                    },
                    5:{ //待发货
                        title:langTranslation('发货中'),
                        showList:1
                    },
                    6:{ //已发货
                        title:langTranslation('发货完成'),
                        showList:1
                    },

                    '-1':{
                        title:langTranslation('申请中'),
                        showList:1
                    },
                    '-2':{
                        title:langTranslation('完成'),
                        showList:1
                    },
                    '-3':{
                        title:langTranslation('取消'),
                        showList:1
                    },
                },
                now:new Date(+new Date + (window.DIFF_TIME || 0))
            }
        },
        components:{
            vCommonTop
        },
        computed: {
            ...Vuex.mapState(['realTimeBTCPriceJP','realTimeBTCPriceJPUpdateTime','noBTC','site_mark']),
            isHosting(){
                return this.type=='hosting';
            },
            isEmpty(){
                if(this.loading) return false;
                var notEmpty = false;
                for(var i=0;i<this.viewList.length;i++){
                    let it = this.list[this.viewList[i]];
                    if(it && it.length ){
                        notEmpty = true;
                    }
                }
                return !notEmpty;
            },
            leftTime(){
                var now = this.now;

                var left = Math.floor(this.realTimeBTCPriceJPUpdateTime - (+now/1000) + 301);

                if(left<=0) return '00:00';

                var min = Math.floor(left/60),sec = left % 60;
                return (min>=10?min:'0'+min) + ':' + (sec>=10?sec:'0'+sec);
            },
            viewList(){
                if(this.current==1){
                    return [2]
                }
                if(this.current==2){
                    return [3]
                }
                if(this.current==3){
                    return [-1,-2]
                }
                if(this.current==4){
                    return [0,-3]
                }
                if(this.current==5){
                    return [4]
                }
                if(this.current==6){
                    return [5,6]
                }
                return []
            }
        },
        filters:{
            execIncome(day,item,noBTC){
                let exp;
                if(item.product && item.product.other){
                    exp = item.product.other;
                    if(typeof exp == 'string'){
                        exp = JSON.parse(exp);
                    }

                    exp = exp.expirations_income.find(it=>it.expiration==item.pro_expiration_time);
                }

                if(!exp || !exp.income) return '0';

                if(noBTC){
                    let str
                    if(exp.annualized2){
                        if(day==30){
                            str = Big(exp.annualized2).times(item.order.order_amount).div(1200).toFixed(3);
                        }else{
                            str = Big(exp.annualized2).times(item.order.order_amount).div(100).times(day).toFixed(3);
                        }

                    }else{
                        str = Big(exp.annualized).times(item.order.order_amount).div(365).times(day).div(100).toFixed(3);
                    }

                    return str.substr(0,str.length-1);
                }else{
                    return Big(exp.income).times(day).times(item.pro_base_calc).times(item.count).times(window.BTCPriceJP).toFixed(2);
                }
            },
            execIncomeBTC(day,item){
                let exp;
                if(item.product && item.product.other){
                    exp = item.product.other;
                    if(typeof exp == 'string'){
                        exp = JSON.parse(exp);
                    }

                    exp = exp.expirations_income.find(it=>it.expiration==item.pro_expiration_time);
                }

                if(!exp || !exp.income) return '0';

                return Big(exp.income).times(day).times(item.pro_base_calc).times(item.count).round(8,0).toFixed(8)
            },

            allCalc(v){
                return Big(v.pro_base_calc).times(v.count).toFixed();
            },
            calcCount(v){

                return Big(v.pro_base_calc).times(v.count).toFixed();
            },
        },
        methods:{
            toggleList(key){
                if(this.map[key]){
                    this.map[key].showList =  1 - this.map[key].showList;
                }
            },
            loadMyMachine(reload){
                if(!reload && this.list[this.viewList[0]]) return ;// 已加载

                this.loading = true;

                for( let i =0;i<this.viewList.length;i++){
                    let lit = this.viewList[i]
                    this.$set(this.list,lit,[]);
                }

                getMyMachine(this.viewList.join(','),window.PRO_TYPES?window.PRO_TYPES:'0').then(json=>{
                    let list = json.data;
                    let i,c


                    for(i=0,c=list.length;i<c;i++){
                        let it = list[i];
                        it.pro_info =JSON.parse(it.pro_info);
                        let lit = it.status;

                        this.list[lit].push(it);
                    }
                    this.loading = false;
                },json=>{
                    this.loading = false;
                    this.$message(json.message);
                })
            },
            changeCurrent(cur){
                this.current = cur;
                this.loadMyMachine();
            },
            shipOrder(order_code,tf){
                if(!tf) return ;
                this.$confirm(langTranslation('是否确认发货'), {
                    cancelButtonText:langTranslation('取消'),
                    confirmButtonText:langTranslation('确认发货'),
                    callback:(action)=>{
                        if(action=='confirm'){
                            shipOrder(order_code).then(()=>{
                                this.loadMyMachine(true);
                                this.$message({
                                    message: langTranslation('设置发货成功'),
                                    type: 'success'
                                });
                            });
                        }
                    }
                })
                //设置为发货
            },
            redemption(order_code,pro_do_php){
                if(pro_do_php){
                    this.$confirm(langTranslation('是否取消回购申请？'), {
                        cancelButtonText:langTranslation('取消'),
                        confirmButtonText:langTranslation('申请回购'),
                        callback:(action)=>{
                            if(action=='confirm'){
                                redemption(order_code).then(()=>{
                                    this.loadMyMachine(true);
                                    this.$message({
                                        message: langTranslation('赎回成功'),
                                        type: 'success'
                                    });
                                },json=>{
                                    this.$message(json.message);
                                });
                            }
                        }
                    })
                }else{
                    this.$router.push({
                        path: '/redemption/'+order_code
                    });
                }

            },
            cancelOrder(order_code){
                this.$confirm(langTranslation('是否确认取消订单'), {
                    cancelButtonText:langTranslation('取消'),
                    confirmButtonText:langTranslation('确认'),
                    callback:(action)=>{
                        if(action=='confirm'){
                            cancelOrder(order_code).then(()=>{
                                this.loadMyMachine(true);
                                this.$message({
                                    message: langTranslation('取消订单成功'),
                                    type: 'success'
                                });
                            });
                        }
                    }
                })
            },
            showDetail(item){
                if(item.status==0||item.status==4){
                    this.$router.push('/order_detail/'+item.order_code);
                    return ;
                }

                if(item.status==2||item.status==3){
                    this.$router.push('/lease/'+item.order_code);
                    return ;
                }

                if(item.status==-1||item.status==-2){
                    this.$router.push('/redemption/'+item.order_code);
                    return ;
                }


            },
            goto(url){
                this.$router.push(url);
            }
        },
        watch: {

        },
        created() {
            var self = this;
            this.loadMyMachine();
            function _(){
                setTimeout(() => {
                    self.now = new Date(+new Date + (window.DIFF_TIME || 0));
                    _();
                },1000)
            }
            _();

        },
        template: '__template__'
    };
</script>
