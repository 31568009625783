<template>
    <div v-if="!form.business">
        <el-form :model="form" :rules="rules" ref="addressForm"  label-width="0">
            <div class="user-edit-box">
                <div>
                    <label for="">{{'收货人'|langTranslation}}</label>
                    <el-form-item prop="name" :error="formError.name" ref="fi_name">
                        <el-input class="text" v-model="form.name" ref="mobile" :placeholder="'请输入收货人姓名'|langTranslation"></el-input>
                    </el-form-item>
                    <!--<input v-model="form.name" type="text" placeholder="荷受人の名前を入力してください">-->
                </div>
                <div>
                    <label for="">{{'电话号码'|langTranslation}}</label>
                    <el-form-item prop="mobile" :error="formError.mobile" ref="fi_mobile">
                        <el-input class="text" v-model="form.mobile" ref="mobile" :placeholder="'请输入电话号码'|langTranslation"></el-input>
                    </el-form-item>
                </div>
                <div>
                    <label for="">{{'收货地址'|langTranslation}}</label>
                    <el-form-item prop="address" :error="formError.address" ref="fi_address">
                        <el-input class="text" v-model="form.address" ref="mobile" :placeholder="'请输入收货地址'|langTranslation"></el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>


        <div class="user-btns">
            <a href="javascript:;" @click="save" class="button01">
                <span>{{'保存地址'|langTranslation}}</span>
            </a>
        </div>
    </div>
    <div v-else>
        <el-form :model="form" :rules="rules" ref="addressForm"  label-width="0">
            <div class="user-edit-box">
                <div>
                    <label for="">{{'企业名称'|langTranslation}}</label>
                    <el-form-item prop="name" :error="formError.name" ref="fi_name">
                        <el-input class="text" v-model="form.name" ref="mobile" :placeholder="'请输入企业名称'|langTranslation"></el-input>
                    </el-form-item>
                    <!--<input v-model="form.name" type="text" placeholder="荷受人の名前を入力してください">-->
                </div>
                <div>
                    <label for="">{{'负责人姓名'|langTranslation}}</label>
                    <el-form-item prop="charge" :error="formError.charge" ref="fi_charge">
                        <el-input class="text" v-model="form.charge" ref="charge" :placeholder="'请输入负责人姓名'|langTranslation"></el-input>
                    </el-form-item>
                </div>
                <div>
                    <label for="">{{'联系地址'|langTranslation}}</label>
                    <el-form-item prop="address" :error="formError.address" ref="fi_address">
                        <el-input class="text" v-model="form.address" ref="mobile" :placeholder="'请输入联系地址'|langTranslation"></el-input>
                    </el-form-item>
                </div>
                <div>
                    <label for="">{{'联系电话'|langTranslation}}</label>
                    <el-form-item prop="mobile" :error="formError.mobile" ref="fi_mobile">
                        <el-input class="text" v-model="form.mobile" ref="mobile" :placeholder="'请输入联系电话'|langTranslation"></el-input>
                    </el-form-item>
                </div>

                <div>
                    <label for="">{{'联系邮箱'|langTranslation}}</label>
                    <el-form-item prop="email" :error="formError.email" ref="fi_email">
                        <el-input class="text" v-model="form.email" ref="email" :placeholder="'请输入联系邮箱'|langTranslation"></el-input>
                    </el-form-item>
                </div>

            </div>
        </el-form>


        <div class="user-btns">
            <a href="javascript:;" @click="save" class="button01">
                <span>{{'确认'|langTranslation}}</span>
            </a>
        </div>
    </div>

</template>

<script type="text/javascript">

    import {getAddress,saveAddress} from '../../api';
    import common from '@/common/common'
    let langTranslation = common.langTranslation;
    export default {
        props: ['address_id','business'],
        data() {
            var business = this.business;
            return {
                saving:false,
                form:{
                    id:'',
                    address:'',
                    name:'',
                    charge:'',
                    email:'',
                    mobile:'',
                    business:this.business
                },
                formError :{
                    name:'',
                    charge:'',
                    mobile:'',
                    email:'',
                    address:''
                },

                rules: {
                    name:[
                        {
                            validator:(rule, value, callback) =>{
                                if (!value) {
                                    return callback(new Error(langTranslation(business?'请输入企业名称':'请输入收货人的名字')));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    charge:[
                        {
                            validator:(rule, value, callback) =>{
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入负责人姓名')));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    address:[
                        {
                            validator:(rule, value, callback) =>{
                                if (!value) {
                                    return callback(new Error(langTranslation(business?'请输入联系地址':'请输入收货人的地址')));
                                }

                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    mobile: [
                        {
                            validator:(rule, value, callback) =>{
                                if (!value) {
                                    return callback(new Error(langTranslation(business?'请输入联系电话':'请输入手机号码')));
                                }
                                if (!/^\d{5,15}$/.test(value)) {
                                    return callback(new Error(langTranslation(business?'请输正确的联系电话':'请输正确的手机号码')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                    email: [
                        {
                            validator:(rule, value, callback) =>{
                                if (!value) {
                                    return callback(new Error(langTranslation('请输入联系邮箱')));
                                }
                                if (!/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(value)) {
                                    return callback(new Error(langTranslation('请输正确的联系邮箱')));
                                }
                                callback();
                            },
                            trigger: 'blur'
                        },
                    ],
                }
            }
        },

        components: {

        },
        computed: {

        },
        filters:{

        },
        watch: {},
        methods: {
            load(){
                if(this.address_id){
                    getAddress(this.address_id).then(json=>{
                        this.form.id = json.data.id;
                        this.form.name = json.data.name;
                        this.form.address = json.data.address;
                        this.form.mobile = json.data.mobile;
                        this.form.charge = json.data.charge;
                        this.form.email = json.data.email;
                        this.form.business = json.data.business;
                    })
                }

            },
            save(){
                if(this.saving) return ;

                this.$refs.addressForm.validate(rs=>{
                    if (rs) {
                        let form = {
                            ...this.form,
                            business:this.business||this.form.business?1:0
                        };

                        this.saving = true;
                        saveAddress(form).then(json=>{
                            this.$message({
                                message: langTranslation('保存成功'),
                                type: 'success'
                            });
                            json.data.business = form.business;
                            this.$emit('callback',json.data)
                        },json=>{
                            this.saving = false;
                            this.$message(json.message);
                        })
                    }
                })


            }
        },

        created() {
            this.load();


        },
        template: '__template__'
    };
</script>