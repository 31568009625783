<template>
    <div>
        <v-header @showMenu="showMenu" @closeMenu="closeMenu" :hasMenu="defArticle" :isMenuShow="menu"></v-header>

        <div class="limit-box" style="height: calc(100vh - 88px); overflow: auto;" ref="article">

            <div class="article-box" v-if="article" >
                <h2 v-if="article.cate_id==26">{{article.art_title}}</h2>
                <div class="ab-detail" v-html="article.art_detail" ref="detail">

                </div>

            </div>

        </div>
        <v-copyright></v-copyright>
        <div class="article-menu" v-if="menu">
            <el-form>
                <el-input
                        prefix-icon="el-icon-search"
                        v-model="keyword"
                ></el-input>
            </el-form>

            <dl>
                <dt>{{article.art_title}}</dt>
                <template v-for="item in menuList">
                    <dd  v-if="!keyword || item.text.indexOf(keyword)>=0" :key="item.key">
                        <a :href="'#'+item.key" @click="closeMenu">{{item.text}}</a>
                    </dd>
                </template>

            </dl>
        </div>
    </div>

</template>

<script type="text/javascript">

    import vHeader from './block/header';
    import Vuex from 'vuex';
    import {loadArticle} from '../api';
    import Viewer from 'viewerjs';

    export default {
        props: ['id'],
        data() {
            return {
                article:null,
                menu:false,
                menuList:null,
                keyword:''
            }
        },
        components: {
            vHeader
        },
        computed: {

            ...Vuex.mapGetters(['isLogin']),

            defArticle(){
                return !!(this.article && [23,24,25].indexOf(this.article.cate_id)>=0);
            }

        },
        watch:{
            menu(){
                if(!this.menuList){
                    let hlist = this.$refs.detail.querySelectorAll('h1');
                    let list = [],ts = +new Date;
                    hlist.forEach(it=>{
                        let key = 'hid_'+ts+'_'+list.length
                        it.id = key;
                        list.push({
                            key:key,
                            text:it.innerText
                        })
                    });
                    this.menuList = list;
                }
            }
        },
        methods: {
            loadArticle() {
                if(this.id){
                    loadArticle(this.id).then(json => {
                        this.article = json.data;
                        this.$nextTick(() => {
                            let viewer = new Viewer(this.$refs.article, {
                                inline: false,
                                toolbar: false,
                                title:false,
                                viewed() {
                                    viewer.zoomTo(1);
                                },
                            });
                        })
                    }, json => {
                        this.$message(json.message);
                    })
                }

            },
            showMenu(){
                this.menu = true;
            },
            closeMenu(){
                this.menu = false;
                this.keyword = '';
            }

        },
        created() {
            this.loadArticle();

        },
        template: '__template__'
    };
</script>