<template>
  <div class="header">
    <template v-if="isMenuShow">
      <div class="header-content">
        <div class="left" v-if="!isShowMenuBtn">
          <router-link to="/">
            <i class="icon-home"></i>
          </router-link>
        </div>
        <!--<div class="side">-->
          <!--<div v-if="!isRegisterPage" class="login-btn regist-btn" @click="$router.push('/register')">注册</div>-->
        <!--</div>-->
        <div class="side">
        <router-link to="/user" v-if="!hasMenu">
        <i class="icon-user"></i>
        </router-link>

        <a herf="javascript:;" @click.stop="closeMenu" v-if="hasMenu && isMenuShow">
        <i class="icon-font-del"></i>
        </a>
        </div>
      </div>
    </template>
    <template v-else>
     <div class="header-content" :class="{'shadow': isShowMenu}">
       <div class="left">
         <div class="user-info" v-if="isLogin">
           <router-link to="/user/edit" class="ut-info">
             <img src="/assets/tmp_img/user_head.jpg?_vh=2d3704c" alt="">
             <span>{{userInfo.user_name}}</span>
           </router-link>
         </div>
         <template v-else>
           <div  class="login-btn" @click="$router.push('/login')">ログイン</div>
         </template>
         <div class="logo" v-if="!noLogo">
           <router-link to="/">
             <i class="bitmining"></i>
             <!--<i class="bhp"></i>-->
           </router-link>

         </div>
       </div>


       <div class="side">
            <span href="javascript:;"
                  style="padding-left: 20px;"
                  @click.stop="showMenu(true)"
                  v-show="!isShowMenu">
                <van-icon name="wap-nav"/>
            </span>
         <span href="javascript:;"
               style="padding-left: 20px;"
               @click.stop="showMenu(false)"
               v-show="isShowMenu">
                <van-icon name="cross"/>
            </span>
       </div>
       <div class="side" v-if="false">
         <router-link to="/">
           <i class="icon-home"></i>
         </router-link>
       </div>
     </div>
     <my-menu
      @switching="changeSwitch"
      @openmenu="$emit('openmenu')"
      @shutmenu="$emit('shutmenu')"
      @change-path="changePath"
      :isShow="isShowMenu"
      @close="isShowMenu=false"
     ></my-menu>
   </template>
  </div>
</template>
<script>
  import {mapState, mapGetters} from 'vuex';
  import MyMenu from '../new/menu.vue';
  export default {
    components: {
      MyMenu
    },
    props: ['hasMenu', 'isMenuShow','noLogo'],
    computed: {
      ...mapState(['currentRoute', 'userInfo']),
      ...mapGetters(['isLogin']),
      isShowMenuBtn(){
        return this.currentRoute === 'index'
      },
      isLoginPage(){
        return this.currentRoute === 'login'
      },
      isRegisterPage(){
        return this.currentRoute == 'register';
      },
      isForgotPage(){
        return this.currentRoute == 'forgot';
      }
    },
    data() {
      return {
        switching: false,
        isShowMenu: false
      }
    },

    methods: {
      changeSwitch(val){
        this.switching = val;
      },
      changePath(path){
        //遮罩层动画结束时判断是否跳转
        if(path == this.currentRoute)return ;
        this.$router.push({path});
      },
      showMenu(val){
        //如果popup动画没有结束，不进行显示/隐藏
        if(this.switching)return ;
        this.isShowMenu  = val;
      },
        closeMenu(){
          this.$emit('closeMenu')
        }
    },
    template: '__template__'
  }
</script>
