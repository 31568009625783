import Big from 'big.js' ;
import {sha1} from "@/common/sha1";
import date from "@/common/date";
import axios from 'axios';
import Vue from 'vue';

/**
 * 多语言翻译
 * @param val 原文
 * @param map 参数
 * @returns {*}
 */
function langTranslation(val,map){
    let rs = val;
    if(window.SITE_LANG && window.SITE_LANG[val]){
        rs = window.SITE_LANG[val];
    }

    if(map){
        for(let key in map){
            if(map.hasOwnProperty(key)){
                rs = rs.replace('{'+key+'}',map[key])
            }

        }
    }
    return rs
}


let common = {
    /**
     * 加密密码
     * @param pwd 密码原文
     * @param time 时间戳
     * @param loginKey 密钥
     * @returns {string}
     */
    encodePassword(pwd, time, loginKey) {
        let ecpwd = sha1(loginKey + sha1(pwd).toLowerCase()).toLowerCase();
        if (!time) {
            return ecpwd;
        }
        return sha1(ecpwd + time.toString()).toLowerCase()
    },
    /**
     * 登录
     * @param account
     * @param pwd
     * @param api
     * @returns {*|void}
     */
    login(account, pwd, api) {
        let time = +new Date;
        return axios.post(api || '/api/1.0/web/1.0/login', {
            time: time,
            account: account,
            password: common.encodePassword(pwd, time, window.LOGINKEY)
        })
    },
    /**
     * 商品价格转BTC(加了产品风险系数的)
     * @param val
     * @param btc_price 1个
     * @returns {string|*|string}
     */
    priceToBtc(val,btc_price){
        if(!val) return '0';
        return Big(val).div(btc_price||window.BTCPriceJP).round(8, 0).times(Big(window.RiskFactor).add(1)).round(8, 0).toFixed(8);
    },
    /**
     * 金额转正带逗号分隔的格式
     * @param val
     * @param fix
     * @returns {string}
     */
    moneyToShow(val,fix) {

        let str = Big(val||0).toFixed(fix||0);
        let intSum = str;
        let dot = '';
        let index = str.indexOf('.')
        if(index>=0){
            intSum= str.substring(0, index);//取整数
            dot = str.substring(str.length,index)//取到小数部分搜索
        }
        intSum = intSum.replace(/\B(?=(?:\d{3})+$)/g, ',');
        let ret = intSum + dot;
        return ret;
    },
    /**
     * 多语言翻译
     * @param val 原文
     * @param map 参数
     * @returns {*}
     */
    langTranslation,
    /**
     * 代理级别转名称
     * @param val
     * @returns {*}
     */
    levelToText(val){
        let map = {
            1:langTranslation('国家代理'),
            2:langTranslation('总代理'),
            3:langTranslation('特别代理'),
            4:langTranslation('代理商'),
            5:langTranslation('销售商'),
            6:langTranslation('法人アカウント'),
            99:langTranslation('用户'),
            100:langTranslation('超管'),
            101:langTranslation('普通管理')
        };

        return map[val]||langTranslation('未知')
    },
}



axios.interceptors.response.use(function (e) {
    var t = e.data || {
        state: !1,
        message: langTranslation("网络异常，请稍后再试")
    };
    if ("string" == typeof t)
        try {
            t = JSON.parse(t)
        } catch (r) {
            t = {
                state: !1,
                message: langTranslation("网络异常，请稍后再试")
            }
        }
    return t
}, function () {
    return Promise.resolve({
        state: !1,
        message: langTranslation("网络异常，请稍后再试")
    })
});


Vue.mixin({
    filters: {
        /**
         * 金额转正带逗号分隔的格式
         * @param val
         * @param fix
         * @returns {string}
         */
        moneyToShow:common.moneyToShow,
        /**
         * php时间戳按指定格式时间输出
         * @param val
         * @param format
         * @returns {*}
         */
        dateFormat(val, format) {
            val = val * 1000;
            return date.format(new Date(val), format || 'yyyy.MM.dd HH:mm:ss')
        },
        /**
         * 商品价格转BTC(加了产品风险系数的)
         * @param val
         * @param btc_price 1个
         * @returns {string|*|string}
         */
        priceToBtc:common.priceToBtc,
        /**
         * BTC转货币价格
         * @param val BTC数量
         * @param btcPrice 1个BTC的货币价格
         * @returns {string|*|string}
         */
        btcToMoney(val,btcPrice){
            if(!val) return '0';
            return Big(val).times(btcPrice||window.BTCPriceJP).toFixed(2);
        },
        /**
         * 保留小数位
         * @param val 数字
         * @param fd 保留小数位的数量
         * @param round 是否四舍五入
         * @returns {*|string}
         */
        toFixed(val,fd,round) {
            if (!fd && fd !== 0) {
                fd = 0;
            }
            
            if (fd == -1) {
                return val * 1
            }
            
            if(round){
                return Big(val||0).round(fd,0).toFixed(fd);
            }else{
                return Big(val||0).toFixed(fd);
            }

        },
        /**
         * 订单状态文字转换
         * @param val
         * @returns {*}
         */
        orderStatusToText(val){
            let map = {
                1:langTranslation('待支付'),
                2:langTranslation('已付款'),
                3:langTranslation('已发货'),
                7:langTranslation('交易成功'),
                0:langTranslation('交易关闭'),
                '-1':langTranslation('交易超时关闭'),
                '-2':langTranslation('付款异常')
            }
            return map[val]||langTranslation('未知')
        },
        /**
         * 提现订单状态文字转换
         * @param val
         * @returns {*}
         */
        withdrawStatusToText(val){
            let map = {
                1:langTranslation('待审批'),
                2:langTranslation('已审批'),
                3:langTranslation('已付款'),
                4:langTranslation('已拒绝'),
                0:langTranslation('已取消'),

            }
            return map[val]||langTranslation('未知')
        },
        /**
         * 提现订单状态文字转换(前台)
         * @param val
         * @returns {*}
         */
        withdrawStatusToTextForFront(val){
            let map = {
                1:langTranslation('确认中'),
                2:langTranslation('已审批'),
                3:langTranslation('发送成功'),
                4:langTranslation('发送失败'),
                0:langTranslation('已取消'),

            }
            return map[val]||langTranslation('未知')
        },
        /**
         * 代理级别转名称
         * @param val
         * @returns {*}
         */
        levelToText:common.levelToText,
        /**
         * 支付渠道文字转换
         * @param val
         * @returns {*|string}
         */
        payWayToText(val){
            let map = {
                'balance':langTranslation('余额支付'),
                'offline':langTranslation('线下支付'),
                'offline_btc':langTranslation('BTC线下支付'),
                'balance_btc':langTranslation('BTC余额支付'),
                'online_btc':langTranslation('BTC线下支付'),
                'admin':langTranslation('线下支付'),
                'sys':langTranslation('系统赠送'),
            };

            return  val && (map[val]||langTranslation('未知')) || '-';
        },

        /**
         * 多语言翻译
         * @param val 原文
         * @param map 参数
         * @returns {*}
         */
        langTranslation
    },
    methods: {
        toFixed(row, column, cellValue, index) {

            return Big(cellValue||0).toFixed(0)
        },
        goLogin(){
            this.$router.push({
                path: '/login',
                query: { redirect: this.$route.path}
            })

        },
        /**
         * 代理级别转名称
         * @returns {*}
         */
        levelToText(row, column, cellValue, index){
            if('level' in row){
                return common.levelToText(row.level)
            }else{
                return common.levelToText(row)
            }
        },
        /**
         * 设置表格高度()
         * @param key 字段
         * @param dev 补差值
         * @returns {*}
         */
        setTableHeight(key,dev){
            common.setTableHeight(this,key,dev)
        }
    }
});

/**
 * 动态加载组件
 * @param component
 * @param opt
 * @param root
 * @returns {*}
 */
common.createVueComponent=function(component,opt,root){

    component = Vue.extend(component);

    let $vc = new component({
        el:document.createElement('div'),
        ...opt
    });
    root.$el.appendChild($vc.$el);
    $vc.$root = root;

    return $vc;
}


;(function(){

    /**
     * Callback index.
     */

    var count = 0;

    /**
     * Noop function.
     */

    function noop(){}

    /**
     * JSONP handler
     *
     * Options:
     *  - param {String} qs parameter (`callback`)
     *  - prefix {String} qs parameter (`__jp`)
     *  - name {String} qs parameter (`prefix` + incr)
     *  - timeout {Number} how long after a timeout error is emitted (`60000`)
     *
     * @param {String} url
     * @param {Object|Function} optional options / callback
     * @param {Function} optional callback
     */

    function jsonp(url, opts, fn){
        if ('function' == typeof opts) {
            fn = opts;
            opts = {};
        }
        if (!opts) opts = {};

        var prefix = opts.prefix || '__jp';

        // use the callback name that was passed if one was provided.
        // otherwise generate a unique name by incrementing our counter.
        var id = opts.name || (prefix + (count++));

        var param = opts.param || 'callback';
        var timeout = null != opts.timeout ? opts.timeout : 60000;
        var enc = encodeURIComponent;
        var target = document.getElementsByTagName('script')[0] || document.head;
        var script;
        var timer;


        if (timeout) {
            timer = setTimeout(function(){
                cleanup();
                if (fn) fn(new Error('Timeout'));
            }, timeout);
        }

        function cleanup(){
            if (script.parentNode) script.parentNode.removeChild(script);
            window[id] = noop;
            if (timer) clearTimeout(timer);
        }

        function cancel(){
            if (window[id]) {
                cleanup();
            }
        }

        window[id] = function(data){

            cleanup();
            if (fn) fn(null, data);
        };

        // add qs component
        url += (~url.indexOf('?') ? '&' : '?') + param + '=' + enc(id);
        url = url.replace('?&', '?');



        // create script
        script = document.createElement('script');
        script.src = url;
        target.parentNode.insertBefore(script, target);

        return cancel;
    }

    /**
     * 获取jsonp数据
     * @type {function(String, *=, *=): cancel}
     */
    common.jsonp = jsonp;
}());

/**
 * 复制文本
 * @param text
 */
common.copy = function (text) {
    var cipt = document.createElement('textarea');
    cipt.style.position = 'absolute';
    cipt.style.left = '-999px';
    cipt.style.top = '-999px';
    document.body.appendChild(cipt);
    cipt.value = text;
    cipt.contentEditable = true;
    cipt.focus();
    try{
        if( document.createRange && window.getSelection){
            var range = document.createRange();
            range.selectNodeContents(cipt);
            var s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);
            cipt.setSelectionRange(0, 999999);
        }else{
            cipt.select();
        }

    }catch(e){
        cipt.select();
    }

    document.execCommand('copy',false,null);
    cipt.remove();
}

//common.getQueryParams
//common.getQueryParamByKey
;(function(){
    var _cache = {
        queryValues:{}
    };
    /**
     * 将地址栏查询参数转为json对象
     * @param url
     * @return {Object}
     * @memberOf oofUtil
     */
    common.getQueryParams=function(url,decode){
        if(!url) return {};
        url=url.split("#")[0];
        var us=url.split("?"),obj={},u;
        if(us.length>1){
            us=us[1].split("&");
            for(var i= 0,c=us.length;i<c;i++){
                u=us[i].split("=");
                obj[u[0]]=decode? decodeURIComponent(u[1]) : u[1];
            }
        }
        return obj;
    }

    /**
     * 将地址栏指定key的查询参数
     * @param key
     * @param url
     * @return {String}
     * @memberOf oofUtil
     */
    common.getQueryParamByKey=function(key,url){
        url=url||location.href;
        var obj=_cache.queryValues[url]||(_cache.queryValues[url]=common.getQueryParams(url));
        return obj[key]||"";
    }
}());

/**
 * 设置表格高度
 * @param vue
 * @param key
 * @param dev
 */
common.setTableHeight = function (vue,key,dev) {
    if(!common.setTableHeight.init){
        common.setTableHeight.init = true;
        common.setTableHeight.list = [];
        window.onresize = function(){
            let ls = common.setTableHeight.list;
            let h = document.documentElement.clientHeight;
            ls.forEach(it=>{
                try{
                    it(h);
                }catch(e){}
            });
        }
    }

    common.setTableHeight.list.push(function(h){
        vue[key] = h - dev;
    });

    vue[key] = document.documentElement.clientHeight - dev;
}


/**
 * 计算收益比例
 * @param income_btc
 * @param pro_price
 * @param pro_org_price
 * @param BTCPriceJP
 * @returns {*|string|number}
 */
common.execAnnualized = function(income_btc,pro_price,pro_org_price,BTCPriceJP){
    // income_btc * BTCPriceJP * 360 / pro_price

    pro_price = Big(pro_price||0);
    if(pro_price.toFixed()==0){
        pro_price = Big(pro_org_price||0)
    }
    income_btc = Big(income_btc||0)
    if(income_btc.toFixed()==0 || pro_price.toFixed()==0) return 0;

    return Big(income_btc).times(BTCPriceJP).times(365).div(pro_price).times(100).round(2,0).toFixed(2)
}

/**
 * 计算收益额
 * @param annualized
 * @param pro_price
 * @param pro_org_price
 * @param BTCPriceJP
 * @returns {*|string|number}
 */
common.execIncome = function(annualized, pro_price, pro_org_price, BTCPriceJP) {
    pro_price = Big(pro_price||0);
    if(pro_price.toFixed()==0){
        pro_price = Big(pro_org_price||0)
    }

    annualized = Big(annualized||0)
    if(annualized.toFixed()==0 || pro_price.toFixed()==0) return 0;




    return Big(annualized).times(pro_price).div(100).div(365).div(BTCPriceJP).round(8,0).toFixed(8);



};



export default common;
