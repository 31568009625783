<template>
  <div class="com-top" :class="{'user-top':showUserInfo,'ct-shadow':hasShadow}">

    <div class="com-content"
         :class="{
          'ct-white': isMy,
          'shadow': isShowMenu,
           'is-withdraw':isWithdraw,
           'is-my-show': isMyShow,
           'is-my': isMy}">
      <div class="left" style="margin: 15px;" >
        <template v-if="isLogin">
          <a v-if="!showUserInfo" href="javascript:;" @click="back">
            <i class="icon-back"></i>
          </a>
          <router-link to="/user/edit" v-if="showUserInfo" class="ut-info">
            <img src="/assets/tmp_img/user_head.jpg?_vh=2d3704c" alt="">
            <span>
                {{userInfo.user_name}}<br/>
                <span class="ut-btn">{{userInfo.level | levelToText}}</span>
            </span>

          </router-link>

        </template>
        <span class="login-btn" @click="$router.push('/login')" v-else>ログイン</span>
          <div class="logo">
              <router-link to="/">
                  <i class="bitmining"></i>
                  <!--<i class="bhp"></i>-->
              </router-link>

          </div>
      </div>
      <template>
        <router-link :to="{name: 'income_datepicker', params: {income_type}}" v-if="currentRoute == 'income' && currency!='FIL'">
          <div class="percolator"  style="margin-right: 15px;">
            範囲
            <span class="iconpercolator iconfont percolator-icon"></span>
          </div>
        </router-link>

        <div class="side" v-if="!/income/.test(currentRoute)">
          <template v-if="!rightBtn">
        <span href="javascript:;"
              @click.stop="showMenu(true)"
              v-show="!isShowMenu">
                <van-icon name="wap-nav"/>
            </span>
            <span href="javascript:;"
                  @click.stop="showMenu(false)"
                  v-show="isShowMenu">
                <van-icon name="cross"/>
            </span>
          </template>
          <router-link v-if="rightBtn" :to="rightBtn.to">
            <span class="iconclock iconfont" style="font-size: 24px;font-weight: 600"></span>
          </router-link>


        </div>
      </template>
    </div>
    <my-menu
      @switching="changeSwitch"
      @openmenu=""
      @shutmenu=""
      @change-path="changePath"
      :isShow="isShowMenu"
      @close="isShowMenu=false"
    ></my-menu>
  </div>

</template>
<script>
  import {mapGetters} from 'vuex';
  import Vuex from 'vuex';
  export default {
    data() {
      return {
        isShowMenu: false,
        switching: false
      }
    },
    props: {
      showUserInfo: Boolean,
      backEvent: Boolean,
      hasShadow: Boolean,
      rightBtn: Object,
      income_type: Number
    },
    components: {},
    computed: {
      ...Vuex.mapState(['userInfo', 'currentRoute','currency']),
      ...mapGetters(['isLogin']),
      isWithdraw(){
        return /withdraw_btc/ig.test(this.currentRoute);
      },
      isMy(){
        return /my/ig.test(this.currentRoute) && !this.isShowMenu
      },
      isMyShow(){
        return this.isMy && this.isShowMenu
      }
    },
    methods: {
      back(){
        if (this.backEvent) {
          this.$emit('back');
        } else {
          this.$router.go(-1);
        }

      },
      changeSwitch(val){
        this.switching = val;
      },
      changePath(path){
        //遮罩层动画结束时判断是否跳转
        this.$router.push({path});
      },
      showMenu(val){
        //如果popup动画没有结束，不进行显示/隐藏
        if (this.switching)return;
        this.isShowMenu = val;
      }
    },
    watch: {},
    created() {

    },
    template: '__template__'
  }
</script>
