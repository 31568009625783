<template>
  <div>

    <el-form :model="form" :rules="rules" ref="bankCardForm"  label-width="0">
      <div class="user-edit-box">
        <div>
          <label>{{'银行名'|langTranslation}}</label>
          <el-form-item prop="name" :error="formError.bank_name" ref="fi_name">
            <el-input class="text" v-model="form.bank_name" ref="mobile" :placeholder="'请输入银行名称'|langTranslation"></el-input>
          </el-form-item>
          <!--<input v-model="form.name" type="text" placeholder="荷受人の名前を入力してください">-->
        </div>
        <div>
          <label>{{'分支行名'|langTranslation}}</label>
          <el-form-item prop="mobile" :error="formError.sub_name" ref="fi_mobile">
            <el-input class="text" v-model="form.sub_name" ref="mobile" :placeholder="'请输入分行名称'|langTranslation"></el-input>
          </el-form-item>
        </div>
        <div>
          <label>{{'账户类别'|langTranslation}}</label>
          <el-form-item prop="address" :error="formError.bank_type" ref="fi_address">
            <el-input class="text" v-model="form.bank_type" ref="mobile" :placeholder="'请输入口座種别'|langTranslation"></el-input>
          </el-form-item>
        </div>
        <div>
          <label>{{'银行账号'|langTranslation}}</label>
          <el-form-item prop="address" :error="formError.card_no" ref="fi_address">
            <el-input class="text" v-model="form.card_no" ref="mobile" :placeholder="'请输入银行账号'|langTranslation"></el-input>
          </el-form-item>
        </div>
        <div>
          <label>{{'账号姓名'|langTranslation}}</label>
          <el-form-item prop="address" :error="formError.name" ref="fi_address">
            <el-input class="text" v-model="form.name" ref="mobile" :placeholder="'请输入口座名義'|langTranslation"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <ul class="content">
      <li class="btn-wrap">
        <template v-if="!id || !showDel">
          <van-button
            type="primary"
            size="large"
            @click="saveInfo"
            class=""
            round>
            {{'确认'|langTranslation}}
          </van-button>
        </template>
        <template v-if="id">
          <van-button
            type="primary"
            size="large"
            class="save-btn"
            @click="saveInfo"
            round>
            {{'保存'|langTranslation}}
          </van-button>
          <van-button
            round
            size="large"
            @click="delCard"
            class="plain-btn">
            {{'删除'|langTranslation}}
          </van-button>
        </template>
      </li>
    </ul>
    <van-dialog
      v-model="isShow"
      width="280"
      confirm-button-color="#363737"
      show-cancel-button
      @confirm="del"
      :confirmButtonText="'是'|langTranslation"
      :cancelButtonText="'取消'|langTranslation"
    >

      <div class="dialog-content">
        <div class="top">
          <span class="close" @click.stop="isShow = false">
            <van-icon name="cross"/>
          </span>
        </div>
        <van-icon name="warning" class="icon"/>
        <div class="message">{{'确定删除银行账户'|langTranslation}}?</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import vCommonTop from '../block/common-top';
  import {delBankCard, getBankCard, saveBankCard} from '../../api';
  import common from '@/common/common'
  let langTranslation = common.langTranslation;
  export default {
    components: {

    },
    computed: {

      toast(){
        return this.$notice.toast
      },
      dialog(){
        return this.$notice.dialog
      }
    },
    props:['id','showDel'],
    data () {
      return {
        saving:false,
        form: {
          bank_name: '',
          sub_name: '',
          bank_type: '',
          card_no: '',
          name: ''
        },
        formError:{
          bank_name: '',
          sub_name: '',
          bank_type: '',
          card_no: '',
          name: ''
        },
        rules:{
          bank_name: [
            {
              validator:(rule, value, callback) =>{
                if (!value) {
                  return callback(new Error(langTranslation(this.business?'请输入企业名称':'请输入收货人的名字')));
                }

                callback();
              },
              trigger: 'blur'
            },
          ],
          sub_name: '',
          bank_type: '',
          card_no: '',
          name: ''
        },
        isShow: false
      }
    },
    created() {
      this.load()
    },
    methods: {
      load(){
        if(this.id){
          getBankCard(this.id).then(json=>{
            this.form.id = json.data.id;
            this.form.bank_name = json.data.bank_name;
            this.form.sub_name = json.data.sub_name;
            this.form.bank_type = json.data.bank_type;
            this.form.card_no = json.data.card_no;
            this.form.name = json.data.name;
          })
        }

      },
      bindCard(){
        this.toast.success({
          message: '添加成功',
          icon: 'smile-o'
        })
      },
      saveInfo(){

        if(this.saving) return ;

        this.$refs.bankCardForm.validate(rs=>{
          if (rs) {
            let form = {
              ...this.form,
            };

            this.saving = true;
            saveBankCard(form).then(json=>{

              this.toast.success({
                message: langTranslation(!this.form.id?'添加成功':'保存成功'),
                icon: 'smile-o'
              })
              form.id = json.data.id;

              this.$emit('saved',form)
              //this.$router.go(-1);
            },json=>{
              this.saving = false;
              this.$message(json.message);
            })
          }
        })
      },
      delCard(){
        this.isShow = true;
      },
      del(){
        delBankCard(this.id).then(json=>{
          this.toast.success({
            message: langTranslation('删除成功'),
            icon: 'smile-o'
          })
          this.$router.go(-1);
        },json=>{
          this.$message(json.message);
        })
      }
    }
  }

</script>


