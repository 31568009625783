<template>
  <div>
    <v-common-top></v-common-top>
    <div class="limit-box">
      <div v-if="order && !order.pay_way">
        <div class="pay-select">
          <h2>{{'选择支付方式' | langTranslation}}</h2>
          <div class="pay-list">
            <ul>
              <!--<li @click="changePayWay('stripe')">
              <span class="pl-title">在线支付</span>
              <span class="pl-info"></span>
              <label class="bm-checkbox"><input v-model="pay_way" value="stripe"
              type="radio"/><i></i></label>
              </li>-->

              <li @click="changePayWay('offline_btc')" v-if="!noBTC">
                <span class="pl-title">{{'BTC支付' | langTranslation}}</span>
                <span class="pl-info"></span>
                <label class="bm-checkbox"><input v-model="pay_way" value="offline_btc"
                                                  type="radio"/><i></i></label>
              </li>
              <li @click="changePayWay('offline')">
                <span class="pl-title">{{(order.product.pro_type==0?'线下汇款':'銀行振込') | langTranslation}}</span>
                <span class="pl-info"></span>
                <label class="bm-checkbox"><input v-model="pay_way" value="offline"
                                                  type="radio"/><i></i></label>
              </li>
              <!--<li v-if="user && user.btc>0" @click="changePayWay('btc_balance')"
              :class="{disabled:!canUseBtcBalance}">
              <span class="pl-title">残高から支払い</span>
              <span class="pl-info">({{user.btc}} BTC)</span>
              <label class="bm-checkbox"><input :disabled="!canUseBtcBalance" v-model="pay_way"
              value="btc_balance" type="radio"/><i></i></label>
              </li>-->

            </ul>
          </div>
        </div>

        <div v-if="this.pay_way=='offline'" class="p-detail">
          <p>{{'选择支付方式后进入下一步。' | langTranslation}}</p>
          <!-- <p><strong>-->
          <!--{{user.wallet}}-->
          <!--ii34i4Ynfn449Nink)lzinddsagIn-->
          <!--</strong></p>-->
          <!--<p>-->
            <!--お客様の振込先（出金先）指定口座へのお振込みは、出金をご-->
            <!--依頼された金額から振込手数料を差し引いた金額を受付日の-->
            <!--翌々営業日に行います。-->
          <!--</p>-->
          <!--<p>-->
            <!--お振込みのご連絡はいたしませんので、お客様ご自身で残高の-->
            <!--ご確認をお願いいたします。-->
          <!--</p>-->
        </div>

        <el-form :model="form" :rules="rules" ref="mainForm" label-width="0">

          <div class="pay-bottom">
            <el-form-item v-if="this.pay_way=='offline'" prop="pay_key" ref="fi_pay_way">
              <el-input class="text" v-model="form.pay_key" ref="mobile"
                        :placeholder="'汇款人请用罗马字输入'|langTranslation"></el-input>
            </el-form-item>

            <input type="password" v-if="this.pay_way=='btc_balance'" class="text"
                   :placeholder="'登录密码'|langTranslation">

            <!--<van-cell-group :border="false" class="input-wrap" v-if="pay_way == 'offline'">
              <van-field v-model="inputvalue" placeholder="振り込み名義を入力"/>
            </van-cell-group>-->
            <a href="javascript:;" @click="pay" class="button01">{{'次のステップ' | langTranslation}}</a>
          </div>
        </el-form>


        <!--<div class="p-detail">
        <p>ヒント：1.コインハンドリング手数料はマイナー手数料で、ビ
        ットミンティングは手数料を請求しません。2.コインの住所が
        正確であることを確認してください。そうでなければ、資産は
        回収されません。ヒント：1.コインハンドリング手数料はマイ
        ナー手数料で、ビットミンティングは手数料を請求しません。
        2.コインの住所が正確であることを確認してください。そうで
        なければ、資産は回収されません。</p>
        </div>-->

      </div>

      <div
        v-if="order && (order.pay_way=='stripe' || order.pay_way=='admin' || order.pay_way=='sys' || order.pay_way=='online_btc')"
        class="pay-status">
        <div class="ps-info">
          <i class="icon-suc"></i>
          <p>{{'已支付' | langTranslation}}</p>
        </div>
        <div class="ps-info ps-detail">
          <p><span>{{'支付金额' | langTranslation}}</span></p>
          <p v-if="order.pay_type==1"><strong>{{order.pay_amount | moneyToShow}} 円</strong></p>
          <p v-if="order.pay_type==2"><strong>{{order.pay_amount}} BTC</strong></p>
        </div>
        <div class="ps-bottom">
          <p>{{'订单号' | langTranslation}}：{{order.order_code}}
            <br>
            {{'订单时间' | langTranslation}}：{{order.pay_time | dateFormat}}
          </p>
          <div>
            <router-link to="/machine/paid" class="button01">{{(order.product.pro_type==0?'已购买的设备':'購入履歴') | langTranslation}}</router-link>
          </div>
        </div>
      </div>

      <div v-if="order && order.pay_way=='offline'" class="pay-status">

        <div class="ps-info">

          <i class="icon-font-Transfer icon-circle"></i>

          <p>{{(order.product.pro_type==0?'线下汇款':'銀行振込') | langTranslation}}</p>
        </div>
        <div class="ps-info ps-detail">
          <p><span>{{order.order_title}}</span></p>
          <p><strong>{{byTaxAmount | moneyToShow}} 円</strong></p>
          <p>
            <em>{{'订单号' | langTranslation}}：{{order.order_code}}</em>
          </p>
          <p>
            <em>{{'付款人名义' | langTranslation}}：{{order.pay_key}}</em>
          </p>
        </div>
        <div class="ps-bottom" style="border-bottom: 1px solid #d7d7d7;">
          <p><strong>{{'銀行振込口座情報' | langTranslation}}</strong></p>

            <template v-if="order.product.pro_type==0">
                <p><em>{{'银行名' | langTranslation}}：住信SBIネット銀行</em></p>
                <p><em>{{'本·支店名' | langTranslation}}：法人第一支店</em></p>
                <p><em>{{'口座種別' | langTranslation}}：普通</em></p>
                <p><em>{{'口座番号' | langTranslation}}：1345949</em></p>
                <p><em>{{'口座名義' | langTranslation}}：ビットマイニング(株</em></p>


            </template>
            <template v-else>
                <p><em>{{'银行名' | langTranslation}}：GMOあおぞらネット銀行</em></p>
                <p><em>{{'支店名' | langTranslation}}：法人第二営業部</em></p>
                <p><em>{{'口座種別' | langTranslation}}：普通</em></p>
                <p><em>{{'口座番号' | langTranslation}}：1007373</em></p>
                <p><em>{{'口座名義' | langTranslation}}：ビットマイニング株式会社</em></p>


            </template>
        </div>
        <div class="ps-bottom">
          <p v-if="order.product.pro_type==0" style="padding: 0 30px;">
            {{'銀行窓口やインターネットバンキングでお客様名義の口座から下記の口座へお振込ください。また、注文番号を備考欄にご記入ください。着金から約一週間後にマイニング開始。' | langTranslation}}
          </p>
          <p v-else style="padding: 0 30px;">
            ご購入より<em style="color: #FDD205">24時間以内</em>に、銀行窓口やインターネットバンキングでお客様名義の口座から上記の口座へお振込ください。24時間を超えた場合は、注文をキャンセルさせて頂く場合がございます。その際は恐れ入りますが、再度ご購入の手順よりお進みください。また、<em style="color: #FDD205">注文番号</em>を備考欄にご記入ください。着金から採掘開始する時間は、商品詳細よりご確認ください。
          </p>
          <div>
            <router-link to="/machine" class="button01">{{(order.product.pro_type==0?'已购买的设备':'購入履歴') | langTranslation}}</router-link>
          </div>
        </div>
      </div>

      <div v-if="order && order.pay_way=='offline_btc'" class="pay-status">

        <div class="ps-info">

          <i class="icon-font-Transfer icon-circle"></i>

          <p>{{'BTC支付' | langTranslation}}</p>
          <p><span>{{order.order_title}}</span></p>
          <p>
            <em>{{'订单号' | langTranslation}}：{{order.order_code}}</em>
          </p>
          <p>
            <em>{{'购买时间' | langTranslation}}：{{order.create_time | dateFormat}}</em>
          </p>
          <p v-if="order.pay_btc_record">
            <em>{{'商品总金额' | langTranslation}}：{{byTaxAmount | priceToBtc(realTimeBTCPriceJP)}} BTC</em>
          </p>
          <p v-if="order.pay_btc_record">
            <em>{{'已付金额' | langTranslation}}：{{order.pay_btc_record}} BTC</em>
          </p>
          <p v-if="order.pay_btc_record && waitPayBtc>0">
            <em>{{'请支付剩余尾款' | langTranslation}}：</em>
          </p>
          <p v-if="waitPayBtc>0"><strong>{{waitPayBtc}} BTC</strong></p>
          <p v-if="waitPayBtc<=0"><strong>{{'处理中...' | langTranslation}}</strong></p>
          <p>
            <img :src="qrcode" alt="" width="160">
          </p>
          <p class="clipboard-copy" :data-clipboard-text="user.wallet" style="text-align: center">
            {{'BTC专用地址' | langTranslation}}<br>
            {{user.wallet}} <i class="icon-font-copy"></i>
          </p>
          <p><span style="color:red;">{{'价格更新时间' | langTranslation}}</span> <span style="color:red;">{{leftTime}}</span>
          </p>
            <template v-if="order.product.pro_type==0">
                <p style="text-align: left;margin: 5px 15px"><em>
                    {{'上記BTC専用アドレスへBTCを送付してください。送付手数料はお客様ご負担となります。送付が確認できるまでに時間がかかる場合があります。' | langTranslation}}
                </em></p>
                <p style="text-align: left;margin: 5px 15px"><em>
                    {{'如果将BTC误转到上述以外的地址 ，用户自己承担损失。' | langTranslation}}
                </em></p>
                <p style="text-align: left;margin: 5px 15px"><em>
                    {{'我们不向您再次发送购买成功的确认，请通过「已购买的设备」中确认支付状态。' | langTranslation}}
                </em></p>
            </template>
            <template v-else>
                <p style="text-align: left;margin: 5px 15px"><em>
                    {{'上記BTC専用アドレスへBTCを送付してください。送付手数料はお客様ご負担となります。送付が確認できるまでに時間がかかる場合があります。' | langTranslation}}
                </em></p>
                <p style="text-align: left;margin: 5px 15px"><em>
                    {{'如果将BTC误转到上述以外的地址 ，用户自己承担损失。' | langTranslation}}
                </em></p>
                <p style="text-align: left;margin: 5px 15px"><em>
                    {{'購入成功のご連絡はいたしませんので、お客様ご自身でマイページの「購入履歴」のメニューよりご確認をお願いいたします。' | langTranslation}}
                </em></p>
            </template>

          <div class="ps-bottom">
            <div>
              <a class="button01" @click="showWaitPay">{{(order.product.pro_type==0?'已付款':'支払い完了') | langTranslation}}</a>
            </div>
            <div style="margin-top: 10px">
              <router-link to="/machine" class="button02">{{(order.product.pro_type==0?'已购买的设备':'購入履歴') | langTranslation}}</router-link>
            </div>
          </div>
        </div>


      </div>

      <!--<div v-if="order && order.pay_way=='offline_money'" class="pay-money">
        <ul>
          <li style="height: 140px;">
            <div class="avatar"></div>
            <div class="name">线下汇款</div>
          </li>
          <li style="height: 210px;" class="balance">
            <div class="title">BMマイニングマシン15A ( 365日 )</div>
            <div class="money">750,000 円</div>
            <ul>
              <li>注文番号：3389295</li>
              <li>振込人名義：Nagaoka Ryo</li>
            </ul>
          </li>
          <li style="height: 215px;" class="bank-info">
            <dl>
              <dt>銀行振込口座情報</dt>
              <dd>銀行名：住信SBIネット銀行</dd>
              <dd>本·支店名：法人第一支店</dd>
              <dd>口座種別：普通</dd>
              <dd>口座番号：1345949</dd>
              <dd>口座名義： ビットマイニング(株</dd>
            </dl>

          </li>
          <li style="height: 212px;" class="bottom">
            <div class="desc">
              銀行振込の際、必ず注文番号を備考欄に 御記入ください。また、振込人名義は必ず上記名義 をご記入ください。
            </div>
            <router-link to="/machine" class="button02">{{'已购买的设备' | langTranslation}}</router-link>
            &lt;!&ndash;<div class="btn plain-btn">
              購入済みマシン
            </div>&ndash;&gt;
          </li>
        </ul>
      </div>-->
    </div>
    <v-copyright></v-copyright>

    <div v-if="waiting" class="pay-waiting" @click="waiting=0">
      <!--<i class="icon-font-loading"></i>-->
      <div>
        {{waitPayBtc > 0 ? '检查中...' : '处理中...' | langTranslation}} <br/>
        <em>{{'已付金额' | langTranslation}}：{{order.pay_btc_record}} BTC</em><br/>
        <strong v-if="waitPayBtc>0">{{'待付金额' | langTranslation}}：{{waitPayBtc}} BTC</strong>
      </div>

    </div>
  </div>

</template>

<script type="text/javascript">
  import Big from 'big.js' ;
  import vCommonTop from './block/common-top';
  import {loadOrder, loadUserInfo, payment, setPayWay, checkPayment} from '../api';
  import Vuex from 'vuex';
  import common from '@/common/common'
  let langTranslation = common.langTranslation;
  import ClipboardJS from 'clipboard';
  import qrcode from '@/libs/qrcode/qrcode.js'

  export default {
    props: ['order_code'],
    data() {
      return {
        inputvalue: '',
        waittimer: 0,
        waiting: 0,
        order: null,
        user: {},
        pay_way: window.NO_BTC?'offline':'offline_btc',
        now: new Date(+new Date + (window.DIFF_TIME || 0)),
        rules: {
          pay_key: [
            {
              validator: (rule, value, callback) => {
                if (this.pay_way == 'offline') {
                  if (!value.trim() || !/^[ア-ンｧ-ﾝ㈠-㈮\(\)（）\{\}\[\]\s]+$/i.test(value)) {
                    return callback(new Error(langTranslation('汇款人请用罗马字输入')));
                  }
                }
                callback();
              },
              trigger: 'blur'
            },
          ],
        },
        pay_email: '',
        form: {
          pay_key: '',
        }
      }
    },
    components: {
      vCommonTop
    },
    computed: {
      ...Vuex.mapState(['realTimeBTCPriceJP', 'realTimeBTCPriceJPUpdateTime','noBTC']),
      qrcode(){
        if (this.user && this.user.wallet) {
          var qr = qrcode(4, 'Q');
          qr.addData(this.user.wallet);
          qr.make();
          return qr.createDataURL(8, 1);
        } else {
          return 'about:blank';
        }

      },
      canUseBtcBalance(){
        return Big(this.user.btc) >= common.priceToBtc(this.byTaxAmount, this.realTimeBTCPriceJP)
      },
      canUseBalance() {
        return this.user.money * 1 >= this.byTaxAmount * 1;
      },
      leftTime(){
        var now = this.now;

        var left = Math.floor(this.realTimeBTCPriceJPUpdateTime - (+now / 1000) + 301);

        if (left <= 0) return '00:00';

        var min = Math.floor(left / 60), sec = left % 60;
        return (min >= 10 ? min : '0' + min) + ':' + (sec >= 10 ? sec : '0' + sec);
      },
        byTaxAmount(){
            return Big(this.order.order_amount).add(this.order.sale_tax).toFixed(2);
        },
      waitPayBtc(){

        return Big(common.priceToBtc(this.byTaxAmount, this.realTimeBTCPriceJP)).sub(this.order.pay_btc_record || 0).round(8, 0).toFixed(8);
      }


    },
    watch: {
      'form.pay_key':function(n){

        let fn = n.replace(/[^ア-ンｧ-ﾝ㈠-㈮\(\)（）\{\}\[\]\s]+/g,'');

        if(fn!=n){
          this.form.pay_key = fn;
        }
      }
    },
    methods: {
      waitPayOrder(){
          this.waittimer = setTimeout(()=>{
              this.waittimer && clearTimeout(this.waittimer);
              loadOrder(this.order_code,0,1,1).then(json => {
                  this.order = json.data;

                  this.$nextTick(()=>{
                      if(this.waitPayBtc<=0){
                          checkPayment(this.order_code);
                      }
                  });

                  if(this.order.status==1){
                      this.waitPayOrder();
                  }

                  if(this.order.status>1){
                      this.waiting=0;
                      this.$message({
                          message: langTranslation('支付成功'),
                          type: 'success'
                      });
                  }
              })

          },5000)

      },

      showWaitPay(){
        this.waiting = 1
      },
      loadOrder() {
        loadOrder(this.order_code, 0, 1, 1).then(json => {
          this.order = json.data;
          if (this.order.status == 1) {
            this.waitPayOrder();
          }
        }, json => {
          if (json.code == 99) {
            return this.goLogin();
          }
          this.$message(json.message);
        })
      },
      loadUserInfo() {
        loadUserInfo().then(json => {
          this.user = json.data;
        }, json => {
          this.$message(json.message);
        })
      },
      changePayWay(py) {
        if (py == 'balance') {
          if (this.canUseBalance) {
            this.pay_way = py;
          }

        } else if (py == 'btc_balance') {
          if (this.canUseBtcBalance) {
            this.pay_way = py;
          }
        } else {
          this.pay_way = py;
        }
      },
      pay() {
        if (this.pay_way == 'stripe') {
          window.StripeCheckout.configure({
            key: window.STRIPE_KEY,
            name: '标题_' + this.order.order_code,
            email: this.pay_email,
            currency: window.STRIPE_CURRENCY,
            amount: Number(this.byTaxAmount),
            allowRememberMe: false,
            image: '//' + location.host + '/assets/images/logo_big.svg',
            locale: 'auto',
            token: (token) => {
              this.pay_email = token.email;
              payment(this.order.order_code, token).then(json => {
                this.order = {
                  ...this.order,
                  ...json.data
                };
              }, json => {
                this.$message(json.message);
              });
            }
          }).open();
        } else if (this.pay_way == 'offline') {
          this.$refs.mainForm.validate(rs => {


            if (rs) {
              setPayWay(this.order.order_code, this.pay_way, this.form.pay_key).then(() => {
                this.order.pay_way = this.pay_way;
                this.order.pay_key = this.pay_key;
              }, json => {
                this.$message(json.message);
              })
            }
          });

        } else if (this.pay_way == 'offline_btc') {
          setPayWay(this.order.order_code, this.pay_way).then(() => {
            this.order.pay_way = this.pay_way;
          }, json => {
            this.$message(json.message);
          })
        }

      }
    },
    created() {
      var self = this;
      if (!this.$store.getters.isLogin) {
        return this.goLogin();
      }
      this.loadOrder();
      this.loadUserInfo();


      function _() {
//        setTimeout(() => {
//          self.now = new Date(+new Date + (window.DIFF_TIME || 0));
//          _();
//        }, 1000)
      }

      _();

    },
    updated(){
//            if(this.pay_way=='offline_btc'){
//                if(this.clipboard){
//                    this.clipboard.destroy();
//                }
//
//                let it = document.querySelector('.clipboard-copy');
//
//                this.clipboard = new ClipboardJS(it);
//
//                this.clipboard.on('success', ()=> {
//                    this.$message({
//                        message: langTranslation('复制成功'),
//                        type: 'success'
//                    });
//                });
//
//                this.clipboard.on('error', ()=> {
//                    this.$message(langTranslation('复制失败'))
//                });
//
//            }
    },
    beforeDestroy(){
      this.waittimer && clearTimeout(this.waittimer);
    },
    template: '__template__'
  };
</script>
